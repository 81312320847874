import { sendRequest } from "../../helper";

interface validateOtpProps {
  // otp: string;
  link: string;
  platform: string;
  influencerId: string;
}

export const validateOtp = ({
  // otp,
  link,
  platform,
  influencerId,
}: validateOtpProps) => {
  return sendRequest("post", `/influencer/social-media-kit/add`, false, {
    // otp,
    link,
    platform,
    influencerId,
  }).then((res) => res.data);
};
