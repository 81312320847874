import { CampaignPayment } from "../../typings";
import { sendRequest } from "../helper";

export type CampaignPaymentResult = {
  status: "SUCCESS";
  message: "Fetching All Campaign Payments";
  data: CampaignPayment;
};

type getCampaignAllPaymentsProps = {
  page?: number;
  campaignId?: string;
};

export const getCampaignAllPayments = ({
  page,
  campaignId,
}: getCampaignAllPaymentsProps) => {
  const queryKey = ["campaigns", "payment", campaignId, page];

  const queryFunction = () =>
    sendRequest<CampaignPaymentResult>(
      "get",
      `/brand/offer/payment?campaignId=${campaignId}&page=${page}&limit=4`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
