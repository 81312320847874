import { sendRequest } from "../helper";

export type UserResult = any;

type getAllUsersProps = {
  page?: number;
  limit?: number;
};

export const getAllUsers = ({ page = 1, limit = 10 }: getAllUsersProps) => {
  const queryKey = ["users", page, limit];

  const queryFunction = () =>
    sendRequest<UserResult>(
      "get",
      `/admin/users?limit=${limit}&page=${page}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
