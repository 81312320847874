import React from "react";
import { PageHeader } from "antd";

const Navbar: React.FC = () => {
  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="CloutFlow"
        avatar={{ src: "/assets/img/cloutflow.png" }}
      />
    </div>
  );
};

export default Navbar;
