import { sendRequest } from "../helper";

interface updateBrandProps {
  brandId: string;
  businessGst?: string;
  accountType: string;
}

export const updateBrand = ({
  brandId,
  businessGst,
  accountType,
}: updateBrandProps) => {
  return sendRequest("put", `/brand/me?brandId=${brandId}`, false, {
    businessGst,
    accountType,
  }).then((res) => res.data);
};
