import { Col, Drawer, Modal, Row, Select, Skeleton, Space, Tag } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getSocialMediaConnectErrorLogById } from "../../../handler/Logs/getSocialMediaLogsById";
import { Divider, Radio, Typography, Button } from "antd";
import ReactJson from "react-json-view";
import { Input } from "antd";
import { updateSocialMediaConnectLogsById } from "../../../handler/Logs/updateSocialMediaLogsById";
import { AxiosError } from "axios";
import { useEffect, useReducer, useRef, useState } from "react";
import { EditOutlined, CheckOutlined, PlusOutlined } from "@ant-design/icons";
import InfluencerKycDrawer from "../InfluencerKycDrawer";
import SocialMediaTokenIgDebugModal from "./Social Media Debug Modal/Instagram";
import SocialMediaTokenYtDebugModal from "./Social Media Debug Modal/Youtube";

const { TextArea } = Input;

const { Title } = Typography;

const SocialMediaConnectErrorLogsDrawer = ({
	drawerParams,
	setDrawerParams,
}: {
	drawerParams: any;
	setDrawerParams: React.Dispatch<React.SetStateAction<any>>;
}) => {
	const [openDebugModal, setOpenDebugModal] = useState(false);
	const [openInfluencerDrawer, setOpenInfluencerDrawer] = useState(false);
	const queryClient = useQueryClient();

	const [getLogByIdKey, getLogByIdFn] = getSocialMediaConnectErrorLogById({
		id: drawerParams._id,
	});

	const [isEdit, setIsEdit] = useState({
		note: false,
	});

	const [note, setNote] = useState<string>("");
	const { data, isLoading } = useQuery(getLogByIdKey, getLogByIdFn, {
		enabled: drawerParams?.isOpen,
		onSuccess: (args) => {
			setNote(args?.data?.note);
		},
	});

	const updateSocialMediaConnectLogs = useMutation(
		updateSocialMediaConnectLogsById,
		{
			onSuccess: () => {
				queryClient.invalidateQueries(getLogByIdKey);

				queryClient.invalidateQueries(
					drawerParams.getSocialMediaConnectErrorCountKey,
				);
				queryClient.invalidateQueries(
					drawerParams.getSocialMediaConnectErrorKey,
				);
			},
			onError: (err: AxiosError) => {
				console.log(err);
			},
		},
	);

	return (
		<>
			<Drawer
				open={drawerParams?.isOpen}
				onClose={() => {
					setDrawerParams((prev: any) => {
						return { ...prev, isOpen: false, _id: "" };
					});
				}}
				width={openInfluencerDrawer ? "65vw" : "50vw"}
			>
				{isLoading ? (
					<Skeleton paragraph={{ rows: 10 }} />
				) : (
					<>
						<Row justify={"space-between"} align={"middle"}>
							<Space size={100}>
								<Col>
									<Title level={5}>{"Influencer Id"}</Title>
								</Col>
								<Col>
									<Tag
										onClick={() => {
											setOpenInfluencerDrawer(true);
										}}
										color="magenta"
									>
										{data?.data?.influencerId || "None"}{" "}
									</Tag>
								</Col>
							</Space>
						</Row>
						<Row justify={"space-between"} align={"middle"}>
							<Space size={100}>
								<Col>
									<Title level={5}>{"Phone Number"}</Title>
								</Col>
								<Col>
									<Tag color="magenta">{data?.data?.phoneNumber || "None"}</Tag>
								</Col>
							</Space>
						</Row>
						<Row justify={"space-between"}>
							<Space size={100}>
								<Col>
									<Title level={5}>{"Social media platform"}</Title>
								</Col>
								<Col>
									<Tag color="magenta">
										{data?.data?.logInfo?.socialMediaPlatformType || "None"}
									</Tag>
								</Col>
							</Space>
						</Row>
						<Row justify={"space-between"} align={"middle"}>
							<Space size={100}>
								<Col>
									<Title level={5}>{"Call"}</Title>
								</Col>
								<Col>
									<Tag color="magenta">
										{data?.data?.logInfo?.call || "None"}
									</Tag>
								</Col>
							</Space>
						</Row>
						<Row justify={"space-between"}>
							<Space size={100}>
								<Col>
									<Title level={5}>{"Status"}</Title>
								</Col>
								<Col>
									<Select
										style={{ width: 120 }}
										onChange={(args) => {
											updateSocialMediaConnectLogs.mutate({
												id: drawerParams._id,
												status: args,
											});
										}}
										options={[
											{
												label: "Open",
												value: "Open",
											},
											{
												label: "Pending",
												value: "Pending",
											},
											{
												label: "Resolved",
												value: "Resolved",
											},
											{
												label: "Critical",
												value: "Critical",
											},
										]}
										defaultValue={data?.data?.status}
									/>
								</Col>
							</Space>
						</Row>
						<Row justify={"space-between"} align={"middle"}>
							<Space size={100}>
								<Col>
									<Title level={5}>{"Error"}</Title>
								</Col>
								<Col>
									<ReactJson
										src={data?.data?.logInfo?.error}
										collapsed={false}
									></ReactJson>
								</Col>
							</Space>
						</Row>
						<Row justify={"space-between"} align={"middle"}>
							<Space size={100}>
								<Col>
									<Title level={5}>{"Notes"}</Title>
								</Col>
								<Col>
									{!isEdit.note ? (
										<>
											<Input
												placeholder="maxLength is 6"
												value={note}
												disabled={true}
											/>
											<EditOutlined
												onClick={() => setIsEdit({ ...isEdit, note: true })}
												style={{ cursor: "pointer" }}
											/>
										</>
									) : (
										<>
											<Input
												placeholder="maxLength is 6"
												value={note}
												onChange={(args) => {
													setNote(args.target.value);
												}}
											/>

											<Button
												onClick={() => {
													updateSocialMediaConnectLogs.mutate({
														id: drawerParams._id,
														note,
													});
													setIsEdit({ note: false });
												}}
											>
												<CheckOutlined
													style={{
														cursor: "pointer",
														color: "#00B578",
													}}
												/>
											</Button>
										</>
									)}
								</Col>
							</Space>
						</Row>
						<Row justify={"space-between"} align={"middle"}>
							<Space size={100}>
								<Col>
									<Title level={5}>{"Debug"}</Title>
								</Col>
								<Col>
									{" "}
									<Button
										onClick={() => {
											setOpenDebugModal(true);
										}}
									>
										Debug
									</Button>
								</Col>
							</Space>
						</Row>

						<InfluencerKycDrawer
							openDrawer={openInfluencerDrawer}
							id={data?.data?.influencerId}
							setOpenDrawer={setOpenInfluencerDrawer}
						></InfluencerKycDrawer>
					</>
				)}
			</Drawer>
			{openDebugModal ? (
				data?.data?.logInfo?.socialMediaPlatformType === "instagram" ? (
					<SocialMediaTokenIgDebugModal
						isModalOpen={openDebugModal}
						setIsModalOpen={setOpenDebugModal}
						logInfo={data?.data?.logInfo}
					></SocialMediaTokenIgDebugModal>
				) : data?.data?.logInfo?.socialMediaPlatformType === "youtube" ? (
					<SocialMediaTokenYtDebugModal
						isModalOpen={openDebugModal}
						setIsModalOpen={setOpenDebugModal}
						logInfo={data?.data?.logInfo}
					></SocialMediaTokenYtDebugModal>
				) : (
					<></>
				)
			) : (
				<></>
			)}
		</>
	);
};

export default SocialMediaConnectErrorLogsDrawer;
