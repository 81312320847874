import { ReportCampaignInfluencer, ReportCampaign } from "../../typings";
import { Campaign } from "../../typings";
import { sendRequest } from "../helper";

type getCampaignResult = {
  status: string;
  message: string;
  data: {
    influencers: ReportCampaignInfluencer[];
    campaign: ReportCampaign;
    aggregates: {
      influencer: number;
      report: number;
    };
  };
};

export const getCampaignInfluencersById = (id: string) => {
  const queryKey = ["report-only", "campaign", id];
  const queryFunction = () =>
    sendRequest<getCampaignResult>(
      "get",
      `/report-only/campaign/?campaignId=${id}`,
      false
    ).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
