import {
  DashOutlined,
  CloseOutlined,
  ClockCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { KycConst } from "../const/kycStatus.const";

export const getStatus = ({ status }: { status?: string }) => {
  if (status === KycConst.status.NOT_INITIATED) {
    return {
      status: "Incomplete",
      color: "#999999",
      icon: <DashOutlined style={{ color: "#999999" }} />,
    };
  }
  if (status === KycConst.status.NEEDS_ATTENTION) {
    return {
      status: "Not Verified",
      color: "#FF3141",
      icon: <CloseOutlined style={{ color: "#FF3141" }} />,
    };
  }
  if (status === KycConst.status.UNDER_REVIEW) {
    return {
      status: "Pending",
      color: "#FF8F1F",
      icon: <ClockCircleOutlined style={{ color: "#FF8F1F" }} />,
    };
  }
  if (status === KycConst.status.NON_VERIFIED) {
    return {
      status: "Not Verified",
      color: "#FF3141",
      icon: <CloseOutlined style={{ color: "#FF3141" }} />,
    };
  }

  return {
    status: "KYC Verified",
    color: "#00B578",
    icon: <CheckOutlined style={{ color: "#00B578" }} />,
  };
};
