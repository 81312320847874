import React, { useState } from "react";
import styles from "./BrandTile.module.css";
import { Brand } from "../../../../typings";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { getStatus } from "../../../../Utils/getStatus";
import { KycConst } from "../../../../const/kycStatus.const";
import { format } from "date-fns";
import BrandKycDrawer from "../../../Drawers/BrandKycDrawer";

interface BrandTileProps {
  tileData: Brand;
  getAllBrandsKey: (
    | string
    | number
    | {
        text: string;
        received: string;
        kycStatus: string;
      }
    | undefined
  )[];
}

const BrandTile: React.FC<BrandTileProps> = ({ tileData, getAllBrandsKey }) => {
  //Use States
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  return (
    <>
      <div onClick={() => setOpenDrawer(true)} className={styles.tile}>
        {tileData.lastUpdatedDateTime ? (
          <p>
            {format(
              new Date(tileData?.lastUpdatedDateTime),
              "hh:mm a, do LLL yy"
            )}
          </p>
        ) : (
          <p>-</p>
        )}
        <p>
          {tileData.businessDetails.name === ""
            ? "-"
            : tileData.businessDetails.name}
        </p>
        <p>
          {tileData.name === "" ? "-" : tileData.name + " " + tileData.lastname}
        </p>
        <p>{tileData.emailId}</p>
        <p>
          {tileData?.kyc.status === KycConst.status.NOT_INITIATED ? (
            "-"
          ) : (
            <CheckOutlined style={{ color: "#00B578" }} />
          )}
        </p>
        <p>
          <span className="">
            {getStatus({ status: tileData.kyc.status }).icon}
          </span>{" "}
          <span
            style={{ color: getStatus({ status: tileData.kyc.status }).color }}>
            {getStatus({ status: tileData.kyc.status }).status}
          </span>
        </p>
      </div>
      <BrandKycDrawer
        id={tileData._id}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        status={tileData.kyc.status}
        getAllBrandsKey={getAllBrandsKey}
      />
    </>
  );
};

export default BrandTile;
