const AccessLevels = [
  "User",
  "Brand",
  "Creator",
  "Campaign",
  "Logs",
  "Connect-Request",
  "Community",
  "Restaurant",
  "Payouts",
  "Extention",
];
export { AccessLevels };
