import { sendRequest } from "../helper";

type getTotalCampaignPaymentCountResult = {
  status: string;
  message: string;
  data: { count: number };
};

export const getTotalCampaignPaymentCount = (campaignId?: string) => {
  const queryKey = ["campaigns", "payment", "count", campaignId];
  const queryFunction = () =>
    sendRequest<getTotalCampaignPaymentCountResult>(
      "get",
      `/brand/offer/payment/count?campaignId=${campaignId}`,
      false
    ).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
