import React, { useState, useEffect } from "react";
import { Modal, Button, Input, InputNumber, message } from "antd";
import { useMutation } from "react-query";
import {
  sendOtp,
  validateOtp,
} from "../../../../handler/Influencer/connectPlatform";

interface ModalProps {
  visible: boolean;
  handleClose: () => void;
  initialPlatform: "instagram" | "youtube";
  influencerId: string;
  username?: string;
}
const AddPlatformModal: React.FC<ModalProps> = ({
  visible,
  handleClose,
  influencerId,
  initialPlatform,
  username = "",
}) => {
  const [platformValue, setPlatformValue] = useState<{
    instagram: string;
    youtube: string;
    // otp: string;
  }>({
    instagram: "",
    youtube: "",
    // otp: "",
  });

  // const sendOtpMutation = useMutation(sendOtp, {
  //   onSuccess: () => {
  //     message.success("OTP sent successfully");
  //   },
  //   onError: () => {
  //     message.error("Error in sending OTP");
  //   },
  // });
  const validateOTPMutation = useMutation(validateOtp, {
    onSuccess: () => {
      message.success("Creator  Platform Added Successfully");
      handleClose();
    },
    onError: () => {
      message.error("Error in sending OTP");
    },
  });

  useEffect(() => {
    if (visible) {
      setPlatformValue({
        instagram: "",
        youtube: "",
        // otp: "",
      });
    }
  }, [visible]);

  const wrapInsta = () => {
    if (
      initialPlatform === "instagram" &&
      platformValue.instagram.trim().at(-1) !== "/"
    )
      return platformValue.instagram.trim() + "/";

    return platformValue[initialPlatform].trim();
  };

  return (
    <Modal
      open={visible}
      onCancel={() => handleClose()}
      title={`Connect ${initialPlatform} Profile`}
      footer={
        <>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button
            type="primary"
            disabled={
              initialPlatform === "instagram"
                ? platformValue.instagram.trim() === ""
                : platformValue.youtube.trim() === ""
            }
            onClick={() => {
              validateOTPMutation.mutate({
                link: wrapInsta(),
                influencerId,
                platform: initialPlatform,
              });
            }}
            loading={validateOTPMutation.isLoading}>
            Connect Platform
          </Button>
        </>
      }>
      <>
        {username && (
          <>
            <p>Selected Username</p>
            <Input value={username} disabled />
          </>
        )}

        <h3>
          {initialPlatform === "youtube"
            ? "Enter Any User video Link"
            : "Enter User Profile Link"}
        </h3>

        <Input
          value={
            initialPlatform === "instagram"
              ? platformValue.instagram
              : platformValue.youtube
          }
          onChange={(event) =>
            setPlatformValue((platformValue) => ({
              ...platformValue,
              [initialPlatform]: event.target.value,
            }))
          }
          style={{
            width: "100%",
            border: "1px solid #d9d9d9",
            borderRadius: "2px",
            padding: "4px",
          }}
        />
      </>
    </Modal>
  );
};

export default AddPlatformModal;
