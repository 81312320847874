import { sendRequest } from "../helper";

type getTotalCountResult = {
  status: string;
  message: string;
  data: { count: number };
};

export const getTotalCampaignCount = (searchQuery: {
  text: string;
  brandId: string;
  status: string;
  type?: "report-only" | "execution";
}) => {
  const queryKey = ["campaigns", "count", searchQuery];
  const queryFunction = () =>
    sendRequest<getTotalCountResult>(
      "get",
      `/brand/campaign/count?&status=${searchQuery.status}&brandId=${searchQuery.brandId}&text=${searchQuery.text}&type=${searchQuery?.type}`,
      false
    ).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
