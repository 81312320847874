import { sendRequest } from "../helper";

interface createZohoProps {
  brandId: string;
}

export const createBrandZoho = ({ brandId }: createZohoProps) => {
  return sendRequest(
    "post",
    `/brand/zoho/create-contact?brandId=${brandId}`,
    false
  ).then((res) => res.data);
};
