import {
  Button,
  Drawer,
  Empty,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Skeleton,
  Space,
} from "antd";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import {
  cancelDeal,
  getInfluencerAllPayments,
  getTotalInfluencerPaymentCount,
} from "../../../handler/Influencer";
import {
  getCampaignAllDeals,
  getCampaignAllPayments,
  getTotalCampaignDealCount,
  getTotalCampaignPaymentCount,
} from "../../../handler/Campaign";
import { getCampaignPaymentStatus } from "../../../Utils/getCampaignPaymentStatus";

import CampaignPaymentTile from "./CampaignPaymentTile/CampaignPaymentTile";
import CampaignDealTile from "./CampaignDealTile";
import InviteInfluencerDrawer from "../InviteInfluencerDrawer";

import styles from "./CampaignPaymentDrawer.module.css";

const { TextArea } = Input;

interface CampaignPaymentDrawerProps {
  openPaymentDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenPaymentDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  campaignId?: string;
  openFromInfluencerDrawer: boolean;
  dealId: string;
  brandId: string;
}

const CampaignPaymentDrawer: React.FC<CampaignPaymentDrawerProps> = ({
  openPaymentDrawer,
  setOpenDrawer,
  setOpenPaymentDrawer,
  campaignId,
  openFromInfluencerDrawer,
  dealId,
  brandId,
}) => {
  const queryClient = useQueryClient();
  // Use States
  const [paymentPage, setPaymentPage] = useState<number>(1);
  const [dealPage, setDealPage] = useState<number>(1);
  const [option, setOption] = useState<"payments" | "deals">("payments");
  const [openCancelDealModal, setOpenCancelDealModal] =
    useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>();
  const [finalizedOfferId, setFinalizedOfferId] = useState<string>();
  const [viewRemarks, setViewRemarks] = useState<boolean>(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  // Get all Campaign Payments and count
  const [getCampaignAllPaymentsKey, getCampaignAllPaymentsFn] =
    getCampaignAllPayments({ campaignId, page: paymentPage });
  const { data: campaignPayments, isLoading: campaignPaymentsIsLoading } =
    useQuery(getCampaignAllPaymentsKey, getCampaignAllPaymentsFn, {
      enabled: openPaymentDrawer,
    });
  const [getTotalCampaignPaymentCountKey, getTotalCampaignPaymentCountFn] =
    getTotalCampaignPaymentCount(campaignId);
  const { data: campaignPaymentsTotalCount } = useQuery(
    getTotalCampaignPaymentCountKey,
    getTotalCampaignPaymentCountFn,
    { enabled: openPaymentDrawer }
  );
  // Get all Campaign Deals and count
  const [getCampaignAllDealsKey, getCampaignAllDealsFn] = getCampaignAllDeals({
    campaignId,
    page: dealPage,
  });
  const { data: campaignDeals, isLoading: campaignDealsIsLoading } = useQuery(
    getCampaignAllDealsKey,
    getCampaignAllDealsFn,
    { enabled: openPaymentDrawer }
  );
  const [getTotalCampaignDealCountKey, getTotalCampaignDealCountFn] =
    getTotalCampaignDealCount(campaignId);
  const { data: campaignDealsTotalCount } = useQuery(
    getTotalCampaignDealCountKey,
    getTotalCampaignDealCountFn,
    { enabled: openPaymentDrawer }
  );

  // Cancel Deal Mutatation
  const cancelDealMutation = useMutation(cancelDeal, {
    onSuccess: () => {
      message.success("Deal Cancelled");
      setOpenCancelDealModal(false);
      setFinalizedOfferId("");
      queryClient.invalidateQueries(getCampaignAllPaymentsKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <>
      <Drawer
        width={"75vw"}
        open={openPaymentDrawer}
        title="Campaign Details"
        onClose={() => setOpenPaymentDrawer(false)}>
        {openPaymentDrawer && (
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                <p className={styles.campaignName}>
                  <span>{campaignPayments?.data?.campaign?.campaignName}</span>
                  <img
                    className={styles.platformImg}
                    src={`/assets/img/${campaignPayments?.data.campaign.platform}.png`}
                    alt=""
                  />
                </p>
                <p className={styles.brandName}>
                  {campaignPayments?.data.brand.businessDetails.name}
                </p>
              </div>

              <p
                onClick={() => setOpenDrawer(true)}
                className={styles.headerRight}>
                Campaign Preview
              </p>
            </div>
            <div className={styles.options}>
              <p
                onClick={() => setOption("payments")}
                className={
                  option === "payments" ? styles.active : styles.option
                }>
                Payments (
                {campaignPaymentsTotalCount
                  ? campaignPaymentsTotalCount.data.count
                  : 0}
                )
              </p>
              <p
                onClick={() => setOption("deals")}
                className={option === "deals" ? styles.active : styles.option}>
                Deals (
                {campaignDealsTotalCount ? campaignDealsTotalCount.data : 0})
              </p>
              <Button type="primary" onClick={() => setInviteModalOpen(true)}>
                {" "}
                Invite Creator
              </Button>
            </div>
            {option === "payments" ? (
              <>
                <div className={styles.table}>
                  <div className={styles.paymentTableHeader}>
                    <p>Timestamp</p>
                    <p>Amount</p>
                    <p>Status</p>
                    <p>Payment Status</p>
                    <p>Pathway</p>
                    <p>Ref Id</p>
                    <p>Action</p>
                  </div>
                  <div className={styles.tableBody}>
                    {campaignPaymentsIsLoading && (
                      <Skeleton paragraph={{ rows: 4 }} />
                    )}
                    {campaignPaymentsTotalCount?.data.count !== 0 ? (
                      <>
                        {campaignPayments?.data.offerPayments?.map(
                          (campaignPayment, idx) => (
                            <CampaignPaymentTile
                              key={idx}
                              campaignPayment={campaignPayment}
                              getCampaignAllPaymentsKey={
                                getCampaignAllPaymentsKey
                              }
                            />
                          )
                        )}
                      </>
                    ) : (
                      <Row
                        style={{ height: "200px" }}
                        align="middle"
                        justify="center">
                        <Space direction="vertical" align="center">
                          <Empty />
                        </Space>
                      </Row>
                    )}
                  </div>
                </div>
                {campaignPaymentsTotalCount?.data.count !== 0 && (
                  <Row justify="center">
                    <Pagination
                      total={campaignPaymentsTotalCount?.data.count}
                      style={{ margin: "10px auto" }}
                      onChange={(page, _) => setPaymentPage(page)}
                      pageSize={4}
                      showSizeChanger={false}
                      current={paymentPage}
                    />
                  </Row>
                )}
              </>
            ) : (
              <>
                <div className={styles.table}>
                  <div className={styles.dealTableHeader}>
                    <p>Timestamp</p>
                    <p>
                      <p>Creator Name</p> <p>username</p>
                    </p>

                    <p>Influencer Price</p>
                    <p>Brand Price</p>
                    <p>Payment Status</p>
                    <p>Action</p>
                  </div>
                  <div className={styles.tableBody}>
                    {campaignDealsIsLoading && (
                      <Skeleton paragraph={{ rows: 4 }} />
                    )}
                    {campaignDealsTotalCount?.data !== 0 ? (
                      <>
                        {campaignDeals?.data.offers.map((campaignDeal, idx) => (
                          <CampaignDealTile
                            key={idx}
                            campaignDeal={campaignDeal}
                            getCampaignAllDealsKey={getCampaignAllDealsKey}
                            openFromInfluencerDrawer={openFromInfluencerDrawer}
                          />
                        ))}
                      </>
                    ) : (
                      <Row
                        style={{ height: "200px" }}
                        align="middle"
                        justify="center">
                        <Space direction="vertical" align="center">
                          <Empty />
                        </Space>
                      </Row>
                    )}
                  </div>
                </div>
                {campaignDealsTotalCount?.data !== 0 && (
                  <Row justify="center">
                    <Pagination
                      total={campaignDealsTotalCount?.data}
                      style={{ margin: "10px auto" }}
                      onChange={(page, _) => setDealPage(page)}
                      pageSize={4}
                      showSizeChanger={false}
                      current={dealPage}
                    />
                  </Row>
                )}
              </>
            )}
          </div>
        )}
      </Drawer>
      {openPaymentDrawer && (
        <Modal
          onOk={() => cancelDealMutation.mutate({ finalizedOfferId, remarks })}
          visible={openCancelDealModal}
          title="Cancel Deal"
          okButtonProps={{
            disabled: remarks === "",
            loading: cancelDealMutation.isLoading,
          }}
          onCancel={() => setOpenCancelDealModal(false)}>
          <p>Cancellation reason</p>
          <TextArea
            rows={5}
            style={{ marginTop: "10px" }}
            placeholder="Write remakrs"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Modal>
      )}
      {openPaymentDrawer && (
        <Modal
          visible={viewRemarks}
          title="Remarks"
          cancelButtonProps={{ hidden: true }}
          onOk={() => {
            setViewRemarks(false);
            setRemarks("");
          }}
          onCancel={() => {
            setViewRemarks(false);
            setRemarks("");
          }}>
          <p>{remarks}</p>
        </Modal>
      )}
      {openPaymentDrawer && (
        <InviteInfluencerDrawer
          isOpen={inviteModalOpen}
          setOpenDrawer={setInviteModalOpen}
          brandId={brandId}
          dealId={dealId}
        />
      )}
    </>
  );
};

export default CampaignPaymentDrawer;
