import { sendRequest } from "../helper";

interface Props {
  start: string;
  brandId: string;
  tierPlanId: string;
}

export const createCustomSubscription = ({
  subscription,
}: {
  subscription: Props;
}) => {
  return sendRequest(
    "put",
    `/brand/saas-subscription/custom-subcription`,
    false,
    { ...subscription }
  ).then((res) => res.data);
};
