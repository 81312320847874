import { Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import styles from "./pushNotificationInfluencerModal.module.css";

interface PushNotificationInfluencerModalProps {
	handleOk: () => any;
	handleCancel: () => any;
	state: {
		visible: boolean;
		confirmLoading: boolean;
		props: {
			title: string;
			body: string;
		};
	};
	setState: React.Dispatch<
		React.SetStateAction<{
			visible: boolean;
			confirmLoading: boolean;
			props: {
				title: string;
				body: string;
			};
		}>
	>;
}

const PushNotificationInfluencerModal: React.FC<
	PushNotificationInfluencerModalProps
> = ({ handleCancel, handleOk, state, setState }) => {
	return (
		<>
			<Modal
				title="Push Notification "
				open={state.visible}
				onOk={handleOk}
				onCancel={handleCancel}
				confirmLoading={state.confirmLoading}
			>
				<Input
					placeholder="Title"
					value={state.props.title}
					onChange={(event) => {
						setState((prev) => ({
							...prev,
							props: {
								...prev.props,
								title: event.target.value,
							},
						}));
					}}
				/>
				<br />

				<br />
				<TextArea
					showCount
					maxLength={100}
					style={{ height: 120, resize: "none" }}
					onChange={(event) => {
						setState((prev) => ({
							...prev,
							props: {
								...prev.props,
								body: event.target.value,
							},
						}));
					}}
					placeholder="Body"
					value={state.props.body}
				/>
			</Modal>
		</>
	);
};

export default PushNotificationInfluencerModal;
