import { sendRequest } from "../helper";

interface updateSocialMediaConnectLogsByIdProps {
	id: string;
	note?: string;
	status?: string;
}

export const updateSocialMediaConnectLogsById = ({
	id,
	note,
	status,
}: updateSocialMediaConnectLogsByIdProps) => {
	return sendRequest(
		"put",
		`/log/by-id?logsType=Social-Media-Connect-Error&id=${id}`,
		false,
		{
			note,
			status,
		},
	).then((res) => res.data);
};
