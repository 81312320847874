import {
  Button,
  Drawer,
  Image,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Space,
  Radio,
} from "antd";
import React, { useState } from "react";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import AddPlanModal from "../../AdminDashboard/Brand/AddPlanModal";
import ChangeSubscriptionModal from "../../AdminDashboard/Brand/ChangeSubScriptionModal";

import { KycConst } from "../../../const/kycStatus.const";

import styles from "./BrandKycDrawer.module.css";

import {
  approveBrandKyc,
  createBrandWallet,
  createBrandZoho,
  getBrand,
  sendBrandKycRemarks,
  updateBrand,
  changeKycStatus,
} from "../../../handler/Brand";
import { getSubstate, cancelSubscription } from "../../../handler/plan";

import { getStatus } from "../../../Utils/getStatus";
import { useAuth } from "../../../context/AuthContext";

const { TextArea } = Input;
interface BrandKycDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  status: string;
  id: string;
  getAllBrandsKey: (
    | string
    | number
    | {
        text: string;
        received: string;
        kycStatus: string;
      }
    | undefined
  )[];
}

const BrandKycDrawer: React.FC<BrandKycDrawerProps> = ({
  openDrawer,
  setOpenDrawer,
  status,
  id,
  getAllBrandsKey,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  // Use States
  const [isSendRemarksVisible, setIsSendRemarksVisible] =
    useState<boolean>(false);

  const [remarks, setRemarks] = useState<string>("");
  const [editGST, setEditGST] = useState<boolean>(false);
  const [viewModalVisible, setViewModalVisible] = useState<boolean>(false);
  const [changeSub, setChangeSub] = useState<boolean>(false);
  const [viewRemarks, setViewRemarks] = useState<boolean>(false);
  const { currentUser } = useAuth();

  const cancelSubscriptionMutation = useMutation(cancelSubscription, {
    onSuccess: () => {
      message.success("Subscription Cancelled");
      queryClient.invalidateQueries(getSubKey);
    },
    onError: (err) => {
      console.log(err);
      message.error("Something went wrong");
    },
  });

  // Get Brand data by id
  const [getBrandKey, getBrandFn] = getBrand(id);
  const { data: brandData, isLoading } = useQuery(getBrandKey, getBrandFn, {
    enabled: openDrawer,
  });

  const [getSubKey, getSubFn] = getSubstate({ brandId: id });

  const { data: subData, isLoading: isLoadingSubData } = useQuery(
    getSubKey,
    getSubFn,
    {
      enabled: openDrawer,
    }
  );

  // Send Remakrs
  // const sendRemarksMutation = useMutation(sendBrandKycRemarks, {
  //   onSuccess: () => {
  //     message.success("Remarks Sent");
  //     setIsSendRemarksVisible(false);
  //     queryClient.invalidateQueries(getBrandKey);
  //     queryClient.invalidateQueries(getAllBrandsKey);
  //   },
  //   onError: (err: AxiosError) => {
  //     console.log(err.code, err.message, "error-message", err);
  //     message.error("Something went wrong");
  //   },
  // });

  // Update GST and
  const updateBrandMutation = useMutation(updateBrand, {
    onSuccess: () => {
      message.success("Brand Details Updated");
      setEditGST(false);
      queryClient.invalidateQueries(getBrandKey);
      queryClient.invalidateQueries(getAllBrandsKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  // Create Zoho Customer
  const createZohoMutation = useMutation(createBrandZoho, {
    onSuccess: () => {
      message.success("Zoho Customer Created");
      queryClient.invalidateQueries(getBrandKey);
      queryClient.invalidateQueries(getAllBrandsKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });
  // Create Wallet
  const createWalletMutation = useMutation(createBrandWallet, {
    onSuccess: () => {
      message.success("Wallet Created");
      // queryClient.invalidateQueries(getBrandKey);
      // queryClient.invalidateQueries(getAllBrandsKey);
      changeKycStatusMutation.mutate({
        remarks: "",
        brandId: brandData?.data?._id || "",
        status: KycConst?.status?.VERIFIED,
      });
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error((err?.response?.data as any)?.message);
    },
  });
  // Approve Kyc
  const approveKycMutation = useMutation(approveBrandKyc, {
    onSuccess: () => {
      message.success("KYC Approved");
      queryClient.invalidateQueries(getBrandKey);
      queryClient.invalidateQueries(getAllBrandsKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  const changeKycStatusMutation = useMutation(changeKycStatus, {
    onSuccess: () => {
      message.success("KYC Status Changed");
      queryClient.invalidateQueries(getBrandKey);
      queryClient.invalidateQueries(getAllBrandsKey);
      setRemarks("");
      setIsSendRemarksVisible(false);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error((err?.response?.data as any)?.message);
    },
  });

  // Use Effects

  // Util Functions

  const getButtonText = () => {
    if (brandData?.data.isWalletCreated === false) return "Create Wallet";
    return "Approve";
  };
  const getButtonClickFn = () => {
    if (brandData) {
      if (brandData?.data.isWalletCreated === false)
        return createWalletMutation.mutate({ brandId: brandData?.data._id });

      return approveKycMutation.mutate({
        brandId: brandData?.data._id,
      });
    }
  };

  return (
    <>
      <Drawer
        width={"30vw"}
        title="KYC Details"
        extra={
          <div className={styles.extra}>
            <p className={styles.status}>
              <span className="">{getStatus({ status }).icon}</span>{" "}
              <span style={{ color: getStatus({ status }).color }}>
                {getStatus({ status }).status}
              </span>
            </p>
            {status === KycConst.status.VERIFIED && (
              <p
                onClick={() => {
                  if (brandData) {
                    navigate(`/campaign?brandId=${brandData?.data._id}`);
                  }
                }}
                className={styles.viewCampaign}
              >
                View all campaigns
              </p>
            )}
          </div>
        }
        footer={
          <Row justify="end">
            {status !== KycConst.status.VERIFIED ? (
              <Space>
                <Button
                  loading={
                    createWalletMutation.isLoading ||
                    changeKycStatusMutation?.isLoading
                  }
                  onClick={() => {
                    if (brandData?.data?.isWalletCreated) {
                      return changeKycStatusMutation?.mutate({
                        brandId: brandData?.data?._id || "",
                        remarks: "",
                        status: KycConst?.status?.VERIFIED,
                      });
                    }
                    return createWalletMutation?.mutate({
                      brandId: brandData?.data?._id || "",
                    });
                  }}
                >
                  {brandData?.data?.isWalletCreated
                    ? "Verify"
                    : "Create Wallet And Verify"}
                </Button>

                <Button
                  loading={changeKycStatusMutation?.isLoading}
                  onClick={() => {
                    setIsSendRemarksVisible(true);
                    // return changeKycStatusMutation?.mutate({
                    //   brandId: brandData?.data?._id || "",
                    //   remarks: "",
                    //   status: KycConst?.status?.NON_VERIFIED,
                    // });
                  }}
                >
                  Make it Non Verified
                </Button>
              </Space>
            ) : (
              <Button
                loading={changeKycStatusMutation?.isLoading}
                onClick={() => {
                  setIsSendRemarksVisible(true);
                  // return changeKycStatusMutation?.mutate({
                  //   brandId: brandData?.data?._id || "",
                  //   remarks: "",
                  //   status: KycConst?.status?.NON_VERIFIED,
                  // });
                }}
              >
                Make it Non Verified
              </Button>
            )}

            {/* {status===KycConst?.status?.NON_VERIFIED} */}
          </Row>
        }
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {openDrawer && (
          <>
            {isLoading || isLoadingSubData ? (
              <Skeleton avatar paragraph={{ rows: 16 }} />
            ) : (
              <div className={styles.container}>
                <Image
                  width={106}
                  height={106}
                  className={styles.logo}
                  src={brandData?.data.businessDetails.logoUrl}
                  alt="Brand Logo"
                />
                <Button
                  size="middle"
                  type="primary"
                  style={{ marginTop: "20px" }}
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_BRAND_SIDE_URL}/public/custom/${brandData?.data?.emailId}`,
                      "_blank"
                    )
                  }
                >
                  {" "}
                  Login as Brand
                </Button>
                <div className={styles.personalDetails}>
                  <div className={styles.info}>
                    <p className={styles.label}>Brand Name</p>
                    <p className={styles.value}>
                      {brandData?.data.businessDetails.name}
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>Account Type</p>
                    <p className={styles.value}>
                      <Radio.Group
                        onChange={(e) =>
                          updateBrandMutation.mutate({
                            brandId: brandData?.data._id || "",
                            accountType: e.target.value,
                          })
                        }
                        value={brandData?.data?.accountType}
                      >
                        <Radio.Button value="Simple">Simple</Radio.Button>
                        <Radio.Button value="Managed">Managed</Radio.Button>
                        <Radio.Button value="Report">Report</Radio.Button>
                        <Radio.Button value="Agency">Agency</Radio.Button>
                        {currentUser?.email == "shivam@cloutflow.com" && (
                          <Radio.Button value="Agency">Admin</Radio.Button>
                        )}
                      </Radio.Group>
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>Website</p>
                    <p className={styles.value}>
                      {brandData?.data.businessDetails.websiteUrl}
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>POC Name</p>
                    <p className={styles.value}>
                      {brandData?.data.name} {brandData?.data.lastname}
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>POC Email</p>
                    <p className={styles.value}>{brandData?.data.emailId}</p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>POC Designation</p>
                    <p className={styles.value}>
                      {brandData?.data.designation}
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>POC Mobile</p>
                    <p className={styles.value}>
                      {brandData?.data.phoneNumber}
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>Upgrade Sub</p>
                    <p className={styles.value}>
                      <Button
                        onClick={() => {
                          if (!subData?.data?.subscriptionRequest?.canRequest) {
                            return Modal.info({
                              title: "Reason",
                              content:
                                subData?.data?.subscriptionRequest?.reason,
                            });
                          }

                          return setChangeSub(true);
                          // show Upgrade Modal
                        }}
                      >
                        {subData?.data?.subscriptionRequest?.canRequest
                          ? "Can"
                          : "Can't"}{" "}
                        Upgrade
                      </Button>
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}> Current Subscription</p>
                    <p className={styles.value}>
                      <Space>
                        <Button
                          type="primary"
                          onClick={() => setViewModalVisible(true)}
                        >
                          View{" "}
                        </Button>
                        <Button
                          onClick={() => {
                            if (
                              !subData?.data?.cancelRequest
                                ?.canCancelSubscription
                            ) {
                              return Modal.info({
                                title: "Reason",
                                content: subData?.data?.cancelRequest?.reason,
                              });
                            }

                            return cancelSubscriptionMutation.mutate({
                              brandId: id,
                              subscriptionId:
                                subData?.data?.subscriptionPlan?._id || "",
                            });
                            // show Upgrade Modal
                          }}
                          loading={cancelSubscriptionMutation.isLoading}
                        >
                          Cancel Subscription
                        </Button>
                      </Space>
                    </p>
                  </div>
                </div>
                <div className={styles.businessDetails}>
                  <div className={styles.communities}>
                    <div className={styles.label}>Communities</div>
                    <div className={styles.community}>
                      {brandData?.data.businessDetails.niche.map(
                        (community, idx) => (
                          <span key={idx}>{community}</span>
                        )
                      )}
                    </div>
                  </div>
                  <div className={styles.secondaryInfo}>
                    <p className={styles.secondaryLabel}>Type</p>
                    <p className={styles.secondaryValue}>
                      {brandData?.data.businessDetails.businessType}
                    </p>
                  </div>
                  <div className={styles.secondaryInfo}>
                    <p className={styles.secondaryLabel}>Inc. Cert.</p>

                    <p className={styles.docUrl}>
                      <a
                        href={
                          brandData?.data.businessDetails.verificationDocRef.find(
                            (doc) => doc.label === "inc-cert"
                          )?.url
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        View doc
                      </a>
                    </p>
                  </div>
                  <div className={styles.secondaryInfo}>
                    <p className={styles.secondaryLabel}>GST</p>
                    <div className={styles.gst}>
                      <p className={styles.docUrl}>
                        <a
                          href={
                            brandData?.data.businessDetails.verificationDocRef.find(
                              (doc) => doc.label === "gst-cert"
                            )?.url
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Certificate
                        </a>
                      </p>
                      {/* {brandData?.data.isZohoCustomerCreated === false ? (
                        <>
                          <div className={styles.gstValue}>
                            <p>Enter GST no.</p>
                            <div className={styles.gstInput}>
                              <Input
                                value={gstNo}
                                onChange={(e) => setGstNo(e.target.value)}
                                disabled={!editGST}
                                style={{ width: "80%" }}
                                placeholder="Enter GST no."
                              />
                              {!editGST ? (
                                <EditOutlined
                                  onClick={() => setEditGST(true)}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <Button
                                  onClick={() =>
                                    updateBrandMutation.mutate({
                                      businessGst: gstNo,
                                      brandId: brandData.data._id,
                                      accountType: isAccountManaged
                                        ? "Managed"
                                        : "Simple",
                                    })
                                  }
                                  disabled={gstNo === ""}
                                  loading={updateBrandMutation.isLoading}>
                                  <CheckOutlined
                                    style={{
                                      cursor: "pointer",
                                      color: "#00B578",
                                    }}
                                  />
                                </Button>
                              )}
                            </div>
                          </div>
                        </>
                      ) : ( */}
                      <p className={styles.secondaryValue}>
                        {brandData?.data.businessDetails.gst}
                      </p>
                      {/* )} */}
                    </div>
                  </div>
                  <div className={styles.secondaryInfo}>
                    <p className={styles.secondaryLabel}>Pan</p>

                    <p className={styles.docUrl}>
                      <a
                        href={
                          brandData?.data.businessDetails.verificationDocRef.find(
                            (doc) => doc.label === "pan"
                          )?.url
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        View doc
                      </a>
                    </p>
                  </div>
                  {brandData?.data.businessDetails.verificationDocRef.findIndex(
                    (doc) => doc.label === "sup-doc"
                  ) !== -1 && (
                    <div className={styles.secondaryInfo}>
                      <p className={styles.secondaryLabel}>Other Document</p>

                      <p className={styles.docUrl}>
                        <a
                          href={
                            brandData?.data.businessDetails.verificationDocRef.find(
                              (doc) => doc.label === "sup-doc"
                            )?.url
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          View doc
                        </a>
                      </p>
                    </div>
                  )}
                  <div className={styles.secondaryInfo}>
                    <p className={styles.secondaryLabel}>Address</p>
                    <p className={styles.secondaryValue}>
                      {brandData?.data.businessDetails.address.street}
                      {", "}
                      {brandData?.data.businessDetails.address.city}
                      {", "}
                      {brandData?.data.businessDetails.address.state}
                      {", "}
                      {brandData?.data.businessDetails.address.country}
                      {", Pincode-"}
                      {brandData?.data.businessDetails.address.pincode}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Drawer>
      <Modal
        open={isSendRemarksVisible}
        okText="Block Account"
        bodyStyle={{ maxHeight: "40vh" }}
        onOk={() =>
          changeKycStatusMutation.mutate({
            brandId: brandData?.data?._id || "",
            remarks,
            status: KycConst?.status?.NON_VERIFIED,
          })
        }
        okButtonProps={{ loading: changeKycStatusMutation.isLoading }}
        onCancel={() => setIsSendRemarksVisible(false)}
        title="Why is it not verified"
      >
        <p className={styles.subText}>
          Explain the problem. the brand can see it
        </p>
        <TextArea
          style={{ marginTop: "10px" }}
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          placeholder="Write any message"
          rows={8}
        />
      </Modal>
      <Modal
        visible={viewRemarks}
        okText="OK"
        bodyStyle={{ maxHeight: "40vh" }}
        onOk={() => setViewRemarks(false)}
        onCancel={() => setViewRemarks(false)}
        title="Previous Remarks"
      >
        <p>{brandData?.data.kyc.remarks}</p>
      </Modal>

      {subData?.data?.tier && (
        <AddPlanModal
          isView
          handleClose={() => setViewModalVisible(false)}
          selectedPlan={subData?.data?.tier}
          visible={viewModalVisible}
        />
      )}
      <ChangeSubscriptionModal
        brandId={id}
        initialSelectedPlanId={subData?.data?.tier?._id || ""}
        visible={changeSub}
        handleClose={() => setChangeSub(false)}
      />
    </>
  );
};

export default BrandKycDrawer;
