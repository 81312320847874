import { sendRequest } from "../helper";

export const cancelSubscription = ({
  brandId,
  subscriptionId,
}: {
  brandId: string;
  subscriptionId: string;
}) => {
  return sendRequest(
    "put",
    `/brand/saas-subscription/cancel-custom-subscription?brandId=${brandId}&subscriptionId=${subscriptionId}`,
    false
  ).then((res) => res.data);
};
