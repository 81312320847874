import { useState } from "react";
import {
  Pagination,
  Row,
  Space,
  Empty,
  Input,
  Select,
  Skeleton,
  Button,
} from "antd";
import { useQuery } from "react-query";
import styles from "./Brand.module.css";
import { getAllBrands, getTotalBrandCount } from "../../../handler/Brand";
import BrandTile from "./BrandTile/BrandTile";
import AddPlanModal from "./AddPlanModal";

const { Search } = Input;
const { Option } = Select;

const Brand = () => {
  //Use States
  const [page, setPage] = useState(1);
  const [planModalVisible, setPlanModalVisible] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<{
    text: string;
    received: string;
    kycStatus: string;
  }>({
    text: "",
    received: "",
    kycStatus: "",
  });
  //Get All brands and count
  const [getTotalCountKey, getTotalCountFn] = getTotalBrandCount(searchQuery);
  const { data: totalCount } = useQuery(getTotalCountKey, getTotalCountFn);
  const [getAllBrandsKey, getAllBrandsFn] = getAllBrands({
    page,
    searchQuery: searchQuery,
  });
  const { data: brands, isLoading } = useQuery(getAllBrandsKey, getAllBrandsFn);

  return (
    <>
      <div className={styles.countAndSearch}>
        <div className={styles.count}>
          <div>
            <p>Showing {totalCount?.data.count || 0} results</p>
            <p>{`${(page - 1) * 7 + 1}-${Math.min(
              (page - 1) * 7 + 8,
              totalCount?.data.count || 0
            )} of ${totalCount?.data.count}`}</p>
          </div>
        </div>
        <div className="">
          <Search
            onSearch={(val) => setSearchQuery({ ...searchQuery, text: val })}
            placeholder="Search Brand by name or email"
            enterButton="Search"
            size="large"
          />
        </div>
      </div>

      <div>
        <div className={styles.table}>
          <div className={styles.filters}>
            <Button onClick={() => setPlanModalVisible(true)} type="primary">
              Add Plan
            </Button>
            <p></p>
            <p></p>
            <p></p>
            <div>
              <Select
                onChange={(val) =>
                  setSearchQuery({ ...searchQuery, received: val })
                }
                style={{ width: "120px" }}
                placeholder="Filter by received">
                <Option value="">All</Option>
                <Option value="yes">Received</Option>
                <Option value="no">Not Received</Option>
              </Select>
            </div>
            {searchQuery.received !== "no" && (
              <div>
                <Select
                  onChange={(val) =>
                    setSearchQuery({ ...searchQuery, kycStatus: val })
                  }
                  style={{ width: "120px" }}
                  placeholder="Filter by status">
                  <Option value="">All</Option>
                  {searchQuery.received !== "yes" && (
                    <Option value="Not-Initiated">Incomplete</Option>
                  )}
                  <Option value="Under-Review">Pending</Option>
                  <Option value="Needs-Attention">Not Verified</Option>
                  <Option value="Verified">KYC Verifed</Option>
                </Select>
              </div>
            )}
          </div>
          <div className={styles.tableHeader}>
            <p>Time</p>
            <p>Brand name</p>
            <p>POC name</p>
            <p>POC email</p>
            <p>Kyc Received or not</p>
            <p>Status</p>
          </div>
          {isLoading ? (
            <Skeleton paragraph={{ rows: 10 }} />
          ) : (
            <>
              {totalCount?.data.count !== 0 ? (
                <div className={styles.tableBody}>
                  {brands?.data.map((item, idx) => {
                    return (
                      <BrandTile
                        key={idx}
                        tileData={item}
                        getAllBrandsKey={getAllBrandsKey}
                      />
                    );
                  })}
                </div>
              ) : (
                <Row
                  style={{ height: "200px" }}
                  align="middle"
                  justify="center">
                  <Space direction="vertical" align="center">
                    <Empty />
                  </Space>
                </Row>
              )}
            </>
          )}
        </div>
        {totalCount?.data.count !== 0 && (
          <Row justify="center">
            <Pagination
              total={totalCount?.data.count}
              style={{ margin: "46px auto" }}
              onChange={(page, _) => setPage(page)}
              pageSize={7}
              showSizeChanger={false}
              current={page}
            />
          </Row>
        )}
      </div>
      <AddPlanModal
        handleClose={() => setPlanModalVisible(false)}
        visible={planModalVisible}
      />
    </>
  );
};

export default Brand;
