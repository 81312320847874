import { Brand } from "../../typings";
import { sendRequest } from "../helper";

export type brandResult = {
  status: "SUCCESS";
  message: "Fetching All Brands";
  data: Brand[];
};

type getAllBrandsProps = {
  page?: number;
  searchQuery: { text: string; received: string; kycStatus: string };
};

export const getAllBrands = ({ page, searchQuery }: getAllBrandsProps) => {
  const queryKey = ["brands", page, searchQuery];

  const queryFunction = () =>
    sendRequest<brandResult>(
      "get",
      `/brand/?limit=7&pageNo=${page}&received=${searchQuery.received}&kycStatus=${searchQuery.kycStatus}&text=${searchQuery.text}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
