import { sendRequest } from "../../helper";

interface addToOrganizationProps {
  userId: string;
  orgId: string;
}

export const addToOrganization = (props: addToOrganizationProps) => {
  return sendRequest("post", `/brand/organization/user/add`, false, {
    ...props,
  }).then((res) => res.data);
};
