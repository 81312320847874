import { sendRequest } from "../helper";

interface getInfluencersCountResponse {
  status: string;
  message: string;
  data: {
    count: number;
  };
}

export const getInfluencersCount = ({ brandId = "" }: { brandId?: string }) => {
  const queryKey = ["saas-influencers", "lists", "count", brandId];

  const queryFunction = () =>
    sendRequest<getInfluencersCountResponse>(
      "get",
      `/brand/connect-request/count?brandId=${brandId}&status=&platform=`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
