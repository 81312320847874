import { sendRequest } from "../helper";

interface approveKycProps {
  brandId: string;
}

export const approveBrandKyc = ({ brandId }: approveKycProps) => {
  return sendRequest(
    "put",
    `/brand/kyc/approve?brandId=${brandId}`,
    false
  ).then((res) => res.data);
};
