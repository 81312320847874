import { sendRequest } from "../helper";

export type ListInfluencer = {
  _id: string;
  brandId: string;
  socialMediaId: string;
  platform: string;
  socialMediaName: string;
  isRemoved: boolean;
  removedBy: string;
  lastUpdateDateTime: string;
  createdDateTime: string;
  searchAbleParams: string;
  connectRequestId: string;
  __v: number;
  isProfileLockedForBrand: boolean;
  brand: {
    _id: string;
    businessDetails: {
      name: string;
    };
  };
  socialMediaKit: {
    _id: string;
    influencerId: string;
    socialMediaPlatform: string;
    influencerName: string;
  };
  followers: number;
  profilePicUrl: string;
  name: string;
  influencerId: string;
};

interface getInfluencersResponse {
  status: string;
  message: string;
  data: {
    community: ListInfluencer[];
    pageNo: number;
  };
}

export const getInfluencersCommunity = ({
  pageNumber,
  brandId = "",
  platform,
  accountStatus,
}: {
  pageNumber: number;
  brandId?: string;
  platform?: ("instagram" | "youtube")[];
  accountStatus?: string[];
}) => {
  const queryKey = [
    "community-influencer",
    pageNumber,
    brandId,
    platform,
    accountStatus,
  ];

  // const queryFunction = () =>
  //   sendRequest<getInfluencersResponse>("put", `/brand/community`, false, {
  //     pageNo: pageNumber,
  //     brandId,
  //     accountStatus,
  //     platform,
  //     limit: 10,
  //   }).then((res) => res.data);

  const queryFunction = ({ pageParam }: { pageParam?: number }) =>
    sendRequest<getInfluencersResponse>("put", `/brand/community`, false, {
      brandId,
      accountStatus,
      platform,
      limit: 10,
      pageNo: pageParam || 1,
    }).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
