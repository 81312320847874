import { Brand } from "../../../typings";
import { sendRequest } from "../../helper";

export interface CUserType {
  _id: string;
  organizations: {
    _id: string;
    emailId: string;
    ownerDetails: {
      _id: string;
      name: string;
      businessDetails: {
        name: string;
        logoUrl: string;
      };
    };
  }[];
  cloutflowUser: Brand;
}

type brandResult = {
  status: string;
  message: string;
  data: {
    cloutflowUsers: CUserType[];
  };
};

interface Props {
  limit: number;
  pageNo: number;
}

export const getCloutflowUsers = ({ limit, pageNo }: Props) => {
  const queryKey = ["cloutflow-users", limit, pageNo];

  const queryFunction = () =>
    sendRequest<brandResult>(
      "get",
      `/brand/organization/users?limit=${limit}&pageNo=${pageNo}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
