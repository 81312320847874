import React, { useState, useEffect, useRef } from "react";
import { CiLink } from "react-icons/ci";
import moment from "moment";
import {
  Modal,
  Space,
  Input,
  Col,
  Button,
  Row,
  Upload,
  Radio,
  message,
  DatePicker,
  DatePickerProps,
  Switch,
  Select,
} from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import { PlusOutlined } from "@ant-design/icons";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import cuid from "cuid";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Influencer } from "../../../typings";

import { updateInfluencerV2 } from "../../../handler/Influencer";
import { getInfluencerPlaces } from "../../../handler/Onboard";

import AddPlatformModal from "../InfluencerKycDrawer/AddPlatformMdoal";

const { TextArea } = Input;

interface AddModalProps {
  visible: boolean;
  handleSubmit: () => void;
  handleClose: () => void;
  initialData: Influencer | null;
  getInfluencerKey: (string | undefined)[];
}

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

const EditUserDetailsModal: React.FC<AddModalProps> = ({
  visible,
  handleSubmit,
  handleClose,
  initialData,
  getInfluencerKey,
}) => {
  const [inputData, setInputData] = useState<Partial<Influencer> | null>(null);

  const [placesSearch, setPlacesSearch] = useState<string>("");

  const [placesKey, placesFn] = getInfluencerPlaces({ query: placesSearch });

  const { data: placesData } = useQuery(placesKey, placesFn, {
    enabled: visible,
  });

  const [addPlatformModalProps, setAddPlatformModalProps] = useState<{
    visible: boolean;
    username: string;
    platform: string;
  }>({
    visible: false,
    username: "",
    platform: "",
  });

  const queryClient = useQueryClient();

  const firstRender = useRef(true);

  const resetInputData = () => {
    setInputData(null);
  };

  const updateInfluencerMutation = useMutation(updateInfluencerV2, {
    onSuccess: () => {
      message.success("Influencer Updated");
      handleSubmit();
      firstRender.current = true;
    },
  });

  const validateInput = () => {
    if ((inputData?.name || "")?.trim().length <= 0) {
      return message.error("Please Enter Name");
    }
    if ((inputData?.geoLocationName || "")?.trim().length <= 0) {
      return message.error("Please Enter Location");
    }
    if ((inputData?.geoLocationGooglePlaceId || "")?.trim().length <= 0) {
      return message.error("Please Enter GeoLocation Place ID");
    }
    if ((inputData?.lastname || "")?.trim().length <= 0) {
      return message.error("Please Enter Last Name");
    }

    if (!inputData?.gender) {
      return message.error("Please Select gender");
    }
    if ((inputData?.kyc?.kycDetails?.pan || "")?.trim().length <= 0) {
      return message.error("Please Enter PAN");
    }

    if (
      inputData?.kyc?.kycDetails?.isGstRegistered &&
      (inputData?.kyc?.kycDetails?.gst || "")?.trim().length <= 0
    ) {
      return message.error("Please Enter GST");
    }
    if (
      (inputData?.kyc?.kycDetails?.gst || "")?.trim().length > 0 &&
      (inputData?.kyc?.kycDetails?.companyName || "")?.trim().length <= 0
    ) {
      return message.error("Please Enter Company name");
    }

    if (
      !(inputData?.kyc?.kycDetails?.address?.city || "").trim() ||
      !(inputData?.kyc?.kycDetails?.address?.line || "").trim() ||
      !(inputData?.kyc?.kycDetails?.address?.country || "").trim() ||
      !(inputData?.kyc?.kycDetails?.address?.pincode || "").trim() ||
      !(inputData?.kyc?.kycDetails?.address?.state || "").trim()
    ) {
      return message.error("Please FIll All fields of Street Address");
    }
    if ((inputData?.kyc?.kycDetails?.gst || "")?.trim().length > 0) {
      if ((inputData?.kyc?.kycDetails?.companyName || "")?.trim().length > 0) {
        return updateInfluencerMutation.mutate({
          address: inputData?.kyc?.kycDetails?.address,
          companyName: inputData?.kyc?.kycDetails?.companyName,
          influencerId: inputData?._id || "",
          dob: inputData?.dateOfBirth,
          firstname: inputData?.name,
          lastname: inputData?.lastname,
          gender: inputData?.gender,
          gst: inputData?.kyc?.kycDetails?.gst || "",
          pan: inputData?.kyc?.kycDetails?.pan || "",
          isGstRegistered: inputData?.kyc?.kycDetails?.isGstRegistered,
          niche: inputData?.niche || [],
          profilePicUrl: inputData?.profilePicUrl,
          tags: inputData?.tags,
          geoLocationGooglePlaceId: inputData?.geoLocationGooglePlaceId,
          geoLocationName: inputData?.geoLocationName,
          userPlatforms: inputData?.userPlatforms?.map((item) => ({
            username: item.username,
            platform: item.platform,
          })),
        });
      }
      return;
    }
    return updateInfluencerMutation.mutate({
      address: inputData?.kyc?.kycDetails?.address,
      companyName: "",
      influencerId: inputData?._id || "",
      dob: inputData?.dateOfBirth,
      firstname: inputData?.name,
      lastname: inputData?.lastname,
      gender: inputData?.gender,
      gst: "",
      pan: inputData?.kyc?.kycDetails?.pan || "",
      isGstRegistered: inputData?.kyc?.kycDetails?.isGstRegistered,
      niche: inputData?.niche || [],
      profilePicUrl: inputData?.profilePicUrl,
      tags: inputData?.tags,
      geoLocationGooglePlaceId: inputData?.geoLocationGooglePlaceId,
      geoLocationName: inputData?.geoLocationName,
      userPlatforms: inputData?.userPlatforms?.map((item) => ({
        username: item.username,
        platform: item.platform,
      })),
    });
  };

  const UploadButtonProps: UploadProps = {
    name: "file",
    multiple: false,
    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;

      const storageRef = ref(
        storage,
        `/influencer/${inputData?._id}/${fileName.slice(0, 10)} + ${cuid()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      // setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);
          setInputData((inputData) => {
            return {
              ...inputData,
              profilePicUrl: downloadURL,
            };
          });
        }
      );
    },
    accept: "image/*",
    onRemove(file) {
      setInputData((inputData) => {
        return {
          ...inputData,
          profilePicUrl: "",
        };
      });
    },
  };

  useEffect(() => {
    if (firstRender?.current && initialData?._id && visible) {
      setInputData(initialData);

      firstRender.current = false;
    }
  }, [firstRender.current, initialData, visible]);

  useEffect(() => {
    if (!visible) {
      resetInputData();
    }
  }, [visible]);

  const onDateChange: DatePickerProps["onChange"] = (
    date: moment.Moment | null,
    _: string
  ) => {
    setInputData((inputData) => ({
      ...inputData,
      dateOfBirth: date?.toDate().toISOString() || new Date().toISOString(),
    }));
  };

  const openPlatformLink = (
    username: string,
    platform: "instagram" | "youtube"
  ) => {
    if (platform === "instagram")
      return window.open(
        `https://www.instagram.com/${
          username?.startsWith("@") ? username.slice(1) : username
        }`,
        "_blank"
      );

    return window.open(
      `https://www.youtube.com/${
        username?.startsWith("@") ? username : "@" + username
      }`,
      "_blank"
    );
  };

  return (
    <Modal
      open={visible}
      //   okButtonProps={{ disabled: !validateInput() }}
      width={700}
      onOk={async () => {
        validateInput();
      }}
      onCancel={() => {
        firstRender.current = true;
        handleClose();
      }}
      title="Edit User Details">
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Space>
            Name
            <Input
              value={inputData?.name}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  name: e.target.value.trim() || "",
                });
              }}
            />
          </Space>
        </Col>

        <Col span={24}>
          <Space>
            Last Name
            <Input
              value={inputData?.lastname}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  lastname: e.target.value.trim() || "",
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Profile Picture
            <Upload
              listType="picture-card"
              fileList={
                inputData?.profilePicUrl
                  ? [
                      {
                        uid: inputData?.profilePicUrl,
                        name: inputData?.profilePicUrl,
                        status: "done",
                        url: inputData?.profilePicUrl,
                      },
                    ]
                  : []
              }
              {...UploadButtonProps}>
              {inputData?.profilePicUrl ? null : uploadButton}
            </Upload>
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Dob
            <DatePicker
              onChange={onDateChange}
              value={
                inputData?.dateOfBirth
                  ? moment(inputData?.dateOfBirth)
                  : undefined
              }
            />
          </Space>
        </Col>

        <Col span={24}>
          <Space>
            Gender
            <Radio.Group
              onChange={(e) =>
                setInputData((inputData) => ({
                  ...inputData,
                  gender: e.target.value,
                }))
              }
              value={inputData?.gender}>
              <Radio value={"Male"}>Male</Radio>
              <Radio value={"Female"}>Female</Radio>
              <Radio value={"Other"}>Other</Radio>
            </Radio.Group>
          </Space>
        </Col>

        <Col span={24}>
          <Space>
            Tags:{" "}
            <Button
              onClick={() =>
                setInputData((planDetail) => ({
                  ...planDetail,
                  tags: [...(planDetail?.tags || []), ""],
                }))
              }>
              Add item
            </Button>
          </Space>
          <ol style={{ display: "grid", gap: "4px", margin: "5px 0 5px 50px" }}>
            {inputData?.tags?.map((item, idx) => (
              <li>
                <Col>
                  <Space>
                    <Input
                      value={item}
                      onChange={(e) => {
                        setInputData((planDetail) => ({
                          ...planDetail,
                          tags: [
                            ...(planDetail?.tags || [])?.map(
                              (descItem, descIdx) => {
                                if (descIdx !== idx) return descItem;
                                return e.target.value;
                              }
                            ),
                          ],
                        }));
                      }}
                    />
                    <Button
                      onClick={() =>
                        setInputData((planDetail) => ({
                          ...planDetail,
                          tags: planDetail?.tags?.filter(
                            (_, descIdx) => descIdx !== idx
                          ),
                        }))
                      }
                      danger>
                      Remove
                    </Button>
                  </Space>
                </Col>
              </li>
            ))}
          </ol>
        </Col>
        <Col span={24}>
          <Space>
            Niche:{" "}
            <Button
              onClick={() =>
                setInputData((planDetail) => ({
                  ...planDetail,
                  niche: [...(planDetail?.niche || []), ""],
                }))
              }>
              Add item
            </Button>
          </Space>
          <ol style={{ display: "grid", gap: "4px", margin: "5px 0 5px 50px" }}>
            {inputData?.niche?.map((item, idx) => (
              <li>
                <Col>
                  <Space>
                    <Input
                      value={item}
                      onChange={(e) => {
                        setInputData((planDetail) => ({
                          ...planDetail,
                          niche: [
                            ...(planDetail?.niche || [])?.map(
                              (descItem, descIdx) => {
                                if (descIdx !== idx) return descItem;
                                return e.target.value;
                              }
                            ),
                          ],
                        }));
                      }}
                    />
                    <Button
                      onClick={() =>
                        setInputData((planDetail) => ({
                          ...planDetail,
                          niche: planDetail?.niche?.filter(
                            (_, descIdx) => descIdx !== idx
                          ),
                        }))
                      }
                      danger>
                      Remove
                    </Button>
                  </Space>
                </Col>
              </li>
            ))}
          </ol>
        </Col>

        <Col span={24}>
          <Space>
            Pan
            <Input
              value={inputData?.kyc?.kycDetails?.pan}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  kyc: {
                    ...inputData?.kyc,
                    kycDetails: {
                      ...inputData?.kyc?.kycDetails,
                      pan: e.target.value.trim() || "",
                    },
                  },
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Has gst
            <Switch
              checked={inputData?.kyc?.kycDetails?.isGstRegistered}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  kyc: {
                    ...inputData?.kyc,
                    kycDetails: {
                      ...inputData?.kyc?.kycDetails,
                      isGstRegistered: e,
                    },
                  },
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Gst
            <Input
              value={inputData?.kyc?.kycDetails?.gst}
              disabled={!inputData?.kyc?.kycDetails?.isGstRegistered}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  kyc: {
                    ...inputData?.kyc,
                    kycDetails: {
                      ...inputData?.kyc?.kycDetails,
                      gst: e.target.value.trim() || "",
                    },
                  },
                });
              }}
            />
          </Space>
          {inputData?.kyc?.kycDetails?.gst && (
            <Space>
              {" "}
              Company Name
              <Input
                value={inputData?.kyc?.kycDetails?.companyName}
                disabled={!inputData?.kyc?.kycDetails?.isGstRegistered}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    kyc: {
                      ...inputData?.kyc,
                      kycDetails: {
                        ...inputData?.kyc?.kycDetails,
                        companyName: e.target.value.trim() || "",
                      },
                    },
                  });
                }}
              />
            </Space>
          )}
        </Col>

        <Col span={24}>
          <Space>
            Location
            <Select
              showSearch
              placeholder={`Search  Location`}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              labelInValue={true}
              value={{
                value: inputData?.geoLocationGooglePlaceId || "",
                label: inputData?.geoLocationName || "",
              }}
              onChange={(val) => {
                setInputData((inputData) => ({
                  ...inputData,
                  geoLocationGooglePlaceId: val.value,
                  geoLocationName: val.label,
                }));
              }}
              searchValue={placesSearch}
              onSearch={(value) => setPlacesSearch(value || "")}
              // notFoundContent={null}
              style={{ width: "200px" }}
              options={(placesData?.data || []).map((d) => ({
                value: d.placeId,
                label: d.name,
              }))}
            />
          </Space>
        </Col>

        <Col span={24}>
          <p>Address</p>
          <div style={{ padding: "10px" }}>
            <Space>
              Street Address
              <Input
                value={inputData?.kyc?.kycDetails?.address?.line}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    kyc: {
                      ...inputData?.kyc,
                      kycDetails: {
                        ...inputData?.kyc?.kycDetails,
                        address: {
                          ...inputData?.kyc?.kycDetails?.address,
                          line: e.target.value || "",
                        },
                      },
                    },
                  });
                }}
              />
            </Space>
            <Space>
              {" "}
              Pin
              <Input
                value={inputData?.kyc?.kycDetails?.address?.pincode}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    kyc: {
                      ...inputData?.kyc,
                      kycDetails: {
                        ...inputData?.kyc?.kycDetails,
                        address: {
                          ...inputData?.kyc?.kycDetails?.address,
                          pincode: e.target.value || "",
                        },
                      },
                    },
                  });
                }}
              />
            </Space>
            <Space>
              {" "}
              Country
              <Input
                value={inputData?.kyc?.kycDetails?.address?.country}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    kyc: {
                      ...inputData?.kyc,
                      kycDetails: {
                        ...inputData?.kyc?.kycDetails,
                        address: {
                          ...inputData?.kyc?.kycDetails?.address,
                          country: e.target.value || "",
                        },
                      },
                    },
                  });
                }}
              />
            </Space>
            <Space>
              {" "}
              State
              <Input
                value={inputData?.kyc?.kycDetails?.address?.state}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    kyc: {
                      ...inputData?.kyc,
                      kycDetails: {
                        ...inputData?.kyc?.kycDetails,
                        address: {
                          ...inputData?.kyc?.kycDetails?.address,
                          state: e.target.value || "",
                        },
                      },
                    },
                  });
                }}
              />
            </Space>
            <Space>
              {" "}
              City
              <Input
                value={inputData?.kyc?.kycDetails?.address?.city}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    kyc: {
                      ...inputData?.kyc,
                      kycDetails: {
                        ...inputData?.kyc?.kycDetails,
                        address: {
                          ...inputData?.kyc?.kycDetails?.address,
                          city: e.target.value || "",
                        },
                      },
                    },
                  });
                }}
              />
            </Space>
          </div>
        </Col>

        <Col span={24}>
          <Space>
            Platforms:{" "}
            <Button
              onClick={() =>
                setInputData((inputData) => ({
                  ...inputData,
                  userPlatforms: [
                    ...(inputData?.userPlatforms || []),
                    { platform: "instagram", username: "" },
                  ],
                }))
              }>
              Add Platform
            </Button>
          </Space>
          <ol style={{ display: "grid", gap: "4px", margin: "5px 0 5px 50px" }}>
            {inputData?.userPlatforms?.map((item, idx) => (
              <li>
                <Col>
                  <Space>
                    Plat. Name
                    <Select
                      style={{ width: 120 }}
                      value={item?.platform}
                      size="small"
                      onChange={(value) => {
                        setInputData((inputData) => ({
                          ...inputData,
                          userPlatforms: [
                            ...(inputData?.userPlatforms || [])?.map(
                              (descItem, descIdx) => {
                                if (descIdx !== idx) return descItem;
                                return {
                                  ...descItem,
                                  platform: value,
                                };
                              }
                            ),
                          ],
                        }));
                      }}
                      options={[
                        {
                          value: "instagram",
                          label: "Instagram",
                        },
                        {
                          value: "youtube",
                          label: "Youtube",
                        },
                        {
                          value: "tiktok",
                          label: "Tiktok",
                          disabled: true,
                        },
                        {
                          value: "snapchat",
                          label: "Snapchat",
                          disabled: true,
                        },
                        {
                          value: "linkedin",
                          label: "Linkedin",
                          disabled: true,
                        },
                      ]}
                    />
                    username
                    <Input
                      value={item?.username}
                      disabled={Boolean(item?.connectedThrough)}
                      onChange={(e) => {
                        setInputData((inputData) => ({
                          ...inputData,
                          userPlatforms: [
                            ...(inputData?.userPlatforms || [])?.map(
                              (descItem, descIdx) => {
                                if (descIdx !== idx) return descItem;
                                return {
                                  ...descItem,
                                  username: e.target.value || "",
                                };
                              }
                            ),
                          ],
                        }));
                      }}
                    />
                    {["instagram", "youtube"].includes(item.platform) && (
                      <CiLink
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          openPlatformLink(
                            item.username,
                            item.platform as "instagram" | "youtube"
                          )
                        }
                      />
                    )}
                    {["instagram", "youtube"].includes(item.platform) && (
                      <Button
                        disabled={
                          Boolean(item?.connectedThrough) ||
                          !["instagram", "youtube"].includes(item.platform)
                        }
                        onClick={
                          () => {
                            setAddPlatformModalProps({
                              platform: item.platform,
                              username: item.username,
                              visible: true,
                            });
                          }
                          // setInputData((inputData) => ({
                          //   ...inputData,
                          //   userPlatforms: inputData?.userPlatforms?.filter(
                          //     (_, descIdx) => descIdx !== idx
                          //   ),
                          // }))
                        }
                        type="default">
                        {Boolean(item?.connectedThrough)
                          ? "Connected"
                          : "Verify"}
                      </Button>
                    )}
                    <Button
                      disabled={Boolean(item?.connectedThrough)}
                      onClick={() =>
                        setInputData((inputData) => ({
                          ...inputData,
                          userPlatforms: inputData?.userPlatforms?.filter(
                            (_, descIdx) => descIdx !== idx
                          ),
                        }))
                      }
                      danger>
                      Remove
                    </Button>
                  </Space>
                </Col>
              </li>
            ))}
          </ol>
        </Col>
      </Row>

      <AddPlatformModal
        influencerId={initialData?._id || ""}
        handleClose={async () => {
          await queryClient.refetchQueries(getInfluencerKey);
          const queryData = queryClient.getQueriesData(getInfluencerKey);

          setInputData((inputData) => ({
            ...inputData,
            userPlatforms:
              (queryData?.at(-1)?.at(-1) as any)?.data?.userPlatforms || [],
          }));
          setAddPlatformModalProps({
            platform: "instagram",
            username: "",
            visible: false,
          });
        }}
        initialPlatform={
          addPlatformModalProps.platform as "instagram" | "youtube"
        }
        visible={addPlatformModalProps.visible}
        username={addPlatformModalProps.username}
      />
    </Modal>
  );
};

export default EditUserDetailsModal;
