import { sendRequest } from "../helper";

type getYtTokenDebugResponse = {
	status: string;
	message: string;
	data: any;
};

export const getYtTokenDebug = ({ token }: { token: string }) => {
	const queryKey = ["youtube", token];
	const queryFunction = () =>
		sendRequest<getYtTokenDebugResponse>(
			"get",
			`/log/debug-social-media-token?platform=youtube&token=${token}`,
			false,
		).then((res) => {
			return res.data;
		});
	return [queryKey, queryFunction] as const;
};
