import React from "react";
import { Modal, Form, Input, Button, Select } from "antd";
import { AccessLevels } from "../../../const/AccessLevel";

interface EditUserModalProps {
  visible: boolean;
  user: any; // Replace with specific user type if available
  handleClose: () => void;
  handleSubmit: (values: any) => void; // Adjust the type of `values` if necessary
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  visible,
  user,
  handleClose,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const accessLevels = AccessLevels;
  return (
    <Modal
      visible={visible}
      title="Edit User"
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                handleSubmit(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" initialValues={user}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="emailId"
          label="Email"
          rules={[{ required: true, message: "Please input the email!" }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="accessLevel"
          label="Access Level"
          rules={[
            {
              required: true,
              message: "Please select at least one access level!",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Please select access levels"
          >
            {accessLevels.map((level) => (
              <Select.Option key={level} value={level}>
                {level}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditUserModal;
