import React, { useState, useEffect } from "react";
import {
  Modal,
  Space,
  Input,
  InputNumber,
  Col,
  Button,
  Row,
  Switch,
  Upload,
} from "antd";
import cuid from "cuid";

import { collection, addDoc, updateDoc, doc } from "firebase/firestore";

import { CodeInfo } from "..";

import { firebaseDb } from "../../../../firebase/firebase";

interface AddModalProps {
  visible: boolean;
  handleSubmit: () => void;
  handleClose: () => void;
  type: "Add" | "Edit";
  initialData: CodeInfo | null;
}

const AddModal: React.FC<AddModalProps> = ({
  visible,
  handleSubmit,
  handleClose,
  type,
  initialData,
}) => {
  const [inputData, setInputData] = useState<CodeInfo>({
    name: "",
    code: "",
    id: "",
    isActive: false,
  });

  const resetInputData = () => {
    setInputData({
      name: "",
      code: "",
      id: "",
      isActive: false,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (initialData) {
      setInputData(initialData);
    }
  }, [initialData]);

  const [loading, setLoading] = useState(false);

  const validateInput = () => {
    if ((inputData?.name || "").trim() && (inputData?.code || "").trim())
      return true;
    return false;
  };

  return (
    <Modal
      open={visible}
      okButtonProps={{ loading, disabled: !validateInput() }}
      width={700}
      onOk={async () => {
        setLoading(true);
        const docRef =
          type === "Add"
            ? await addDoc(collection(firebaseDb, "ExtensionCodes"), {
                ...inputData,
                createdDateTime: new Date(),
                id: cuid(),
              })
            : await updateDoc(
                doc(firebaseDb, "ExtensionCodes", inputData.id),
                inputData
              );
        console.log("Document written with ID: ", docRef);
        resetInputData();
        handleSubmit();
      }}
      onCancel={() => {
        resetInputData();
        handleClose();
      }}
      title="Add ExtensionCode Details">
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Space>
            Account Holder Name
            <Input
              value={inputData.name}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  name: e.target.value.trim() || "",
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Code
            <Input
              value={inputData.code}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  code: e.target.value.trim() || "",
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Is Code Active
            <Switch
              checked={inputData.isActive}
              onChange={(checked) =>
                setInputData({ ...inputData, isActive: checked })
              }
            />
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddModal;
