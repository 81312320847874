import { InfluencerPayments } from "../../typings";
import { sendRequest } from "../helper";

export type influencerPaymentResult = {
  status: "SUCCESS";
  message: "Fetching All Influencer Payments";
  data: InfluencerPayments;
};

type getInfluencerAllPaymentsProps = {
  page?: number;
  influencerId?: string;
};

export const getInfluencerAllPayments = ({
  page,
  influencerId,
}: getInfluencerAllPaymentsProps) => {
  const queryKey = ["influencers", "payment", influencerId, page];

  const queryFunction = () =>
    sendRequest<influencerPaymentResult>(
      "get",
      `/influencer/offer/in-payment?influencerId=${influencerId}&limit=4&pageNo=${page}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
