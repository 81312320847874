import { sendRequest } from "../helper";

interface createInfluencerRazorpayProps {
  influencerId: string;
}

export const createInfluencerRazorpay = ({
  influencerId,
}: createInfluencerRazorpayProps) => {
  return sendRequest(
    "post",
    `/influencer/razorpay/create-contact?influencerId=${influencerId}`,
    false
  ).then((res) => res.data);
};
