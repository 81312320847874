import {createContext, useContext, useState} from 'react'

interface Props {
  children: React.ReactNode;
}

interface ContextInterFace {
    editType: string | undefined;
    editEmail: string | undefined;
    editInApp: string | undefined;
    editPush: string | undefined;
    editSms: string | undefined;
    editWhatsApp: string | undefined;
    setEditType: (c: string | undefined) => void;
    setEditEmail: (c: string | undefined) => void;
    setEditInApp: (c: string | undefined) => void;
    setEditPush: (c: string | undefined) => void;
    setEditSms: (c: string | undefined) => void;
    setEditWhatsApp: (c: string | undefined) => void;
    };

export const NotificationTemplateContext = createContext<ContextInterFace | undefined>(undefined)

export const useNotificationTemplateContext = ()=> {
  const context = useContext(NotificationTemplateContext)
  if (!context) {
    throw new Error("useNotificationContext must be used in <NotificationTemplateProvider />");
  }

  return context;
}

export const NotificationTemplateProvider:React.FC<Props> = (props:any) => {

    const [editType, setEditType] = useState<string | undefined>("")
    const [editEmail, setEditEmail] = useState<string | undefined>("")
    const [editInApp, setEditInApp] = useState<string | undefined>("")
    const [editPush, setEditPush] = useState<string | undefined>("")
    const [editSms, setEditSms] = useState<string | undefined>("")
    const [editWhatsApp, setEditWhatsApp] = useState<string | undefined>("")

    const value = {
                      editType, setEditType,
                      editEmail, setEditEmail,
                      editInApp, setEditInApp,
                      editPush, setEditPush,
                      editSms, setEditSms,
                      editWhatsApp, setEditWhatsApp
                  }

  return (
    <NotificationTemplateContext.Provider value={value}>
        {props.children}
    </NotificationTemplateContext.Provider>
  ) 
}

