import { useState, useEffect } from "react";
import {
  Pagination,
  Row,
  Space,
  Empty,
  Input,
  Select,
  Skeleton,
  Switch,
} from "antd";

import { useQuery } from "react-query";
import styles from "./Campaign.module.css";
import CampaignTile from "./CampaignTile/CampaignTile";
import {
  getAllCampaigns,
  getTotalCampaignCount,
} from "../../../handler/Campaign";
import { useSearchParams } from "react-router-dom";
import { getAllBrands } from "../../../handler/Brand";

const { Search } = Input;
const { Option } = Select;

const Campaign = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const brandId = searchParams.get("brandId");

  //Use States
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<{
    text: string;
    brandId: string;
    status: string;
    type: "report-only" | "execution";
  }>({
    text: "",
    brandId: "",
    status: "",
    type: "execution",
  });
  const [brandSearchQuery, setBrandSearchQuery] = useState<{
    text: string;
    received: string;
    kycStatus: string;
  }>({
    text: "",
    received: "",
    kycStatus: "Verified",
  });
  const [brandName, setBrandName] = useState<string>("");

  // Get All campaigns and count
  const [getTotalCountKey, getTotalCountFn] =
    getTotalCampaignCount(searchQuery);
  const { data: totalCount } = useQuery(getTotalCountKey, getTotalCountFn);
  const [getAllCampaignsKey, getAllCampaignsFn] = getAllCampaigns({
    page,
    searchQuery: searchQuery,
  });
  const { data: campaigns, isLoading } = useQuery(
    getAllCampaignsKey,
    getAllCampaignsFn
  );

  // Get Brands for filter by brand

  const [getAllBrandsKey, getAllBrandsFn] = getAllBrands({
    page,
    searchQuery: brandSearchQuery,
  });
  const { data: brands } = useQuery(getAllBrandsKey, getAllBrandsFn);

  // Use Effects
  useEffect(() => {
    if (brandId) {
      setSearchQuery({ ...searchQuery, brandId });
    }
  }, [brandId]);

  useEffect(() => {
    if (campaigns && brandId) {
      if (campaigns.data.length !== 0) {
        console.log(campaigns.data);

        setBrandName(campaigns.data[0].brand[0].businessDetails.name);
      }
    }
  }, [campaigns]);

  // useEffect(() => {
  //   if (brands) {
  //     if (brands.data.length !== 0)
  //       setSearchQuery({ ...searchQuery, brandId: brands.data[0]._id });
  //   }
  // }, [brands]);

  return (
    <>
      <div className={styles.countAndSearch}>
        <div className={styles.count}>
          <div>
            <p>Showing {totalCount?.data.count || 0} results</p>
            <p>{`${(page - 1) * 7 + 1}-${Math.min(
              (page - 1) * 7 + 8,
              totalCount?.data.count || 0
            )} of ${totalCount?.data.count}`}</p>
          </div>
        </div>
        <div className="">
          <Search
            onSearch={(val) => setSearchQuery({ ...searchQuery, text: val })}
            placeholder="Search Campaign by name"
            enterButton="Search"
            size="large"
          />
        </div>
      </div>

      <div>
        <div className={styles.table}>
          <div className={styles.filters}>
            <Space>
              <p>Campaign Type</p>
              <Switch
                checked={searchQuery.type === "report-only"}
                unCheckedChildren="Execution"
                checkedChildren="Report-Only"
                onChange={(checked) =>
                  setSearchQuery((searchQuery) => ({
                    ...searchQuery,
                    type: checked ? "report-only" : "execution",
                  }))
                }
              />
            </Space>

            <p>
              <Select
                showSearch
                value={brandName === "" ? null : brandName}
                style={{ width: 200 }}
                placeholder="Filter by brand name"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                allowClear
                onSearch={(val) => {
                  setBrandSearchQuery({ ...brandSearchQuery, text: val });
                }}
                onChange={(value) => {
                  if (!value) {
                    setSearchQuery({ ...searchQuery, brandId: "" });
                    return setBrandName("");
                  }
                  // if (brandId) setSearchParams({});
                  setSearchQuery({ ...searchQuery, brandId: value });
                  setBrandName(value);
                }}
                notFoundContent={<p>No result Found</p>}
                options={(brands?.data || []).map((brand) => ({
                  value: brand._id,
                  label: brand.businessDetails?.name,
                }))}
              />
              {/* <Search
                onChange={(e) => {
                  if (brandId) setSearchParams({});
                  setBrandName(e.target.value);
                }}
                value={brandName}
                onSearch={(val) => {
                  if (val !== "") {
                    setBrandSearchQuery({ ...brandSearchQuery, text: val });
                  } else setSearchQuery({ ...searchQuery, brandId: "" });
                }}
                placeholder="Filter by brand name"
                enterButton="Search"
                size="middle"
              /> */}
            </p>
            <p></p>

            <div>
              <Select
                onChange={(val) =>
                  setSearchQuery({ ...searchQuery, status: val })
                }
                style={{ width: "150px" }}
                placeholder="Filter by status">
                <Option value="">All</Option>
                <Option value="Under-Review">Pending</Option>
                <Option value="Active">Approved</Option>
                <Option value="Needs-Attention">Not Approved</Option>
              </Select>
            </div>
          </div>
          <div className={styles.tableHeader}>
            <p>Time</p>
            <p>Brand name</p>
            <p>Campaign Name</p>
            <p>Status</p>
          </div>
          {isLoading ? (
            <Skeleton paragraph={{ rows: 10 }} />
          ) : (
            <>
              {totalCount?.data.count !== 0 ? (
                <div className={styles.tableBody}>
                  {campaigns?.data.map((item, idx) => {
                    return (
                      <CampaignTile
                        type={searchQuery.type}
                        key={idx}
                        tileData={item}
                        getAllCampaignsKey={getAllCampaignsKey}
                      />
                    );
                  })}
                </div>
              ) : (
                <Row
                  style={{ height: "200px" }}
                  align="middle"
                  justify="center">
                  <Space direction="vertical" align="center">
                    <Empty />
                  </Space>
                </Row>
              )}
            </>
          )}
        </div>
        {totalCount?.data.count !== 0 && (
          <Row justify="center">
            <Pagination
              total={totalCount?.data.count}
              style={{ margin: "46px auto" }}
              onChange={(page, _) => setPage(page)}
              pageSize={7}
              showSizeChanger={false}
              current={page}
            />
          </Row>
        )}
      </div>
    </>
  );
};

export default Campaign;
