import { sendRequest } from "../helper";

interface createWalletProps {
  influencerId: string;
}

export const createInfluencerWallet = ({ influencerId }: createWalletProps) => {
  return sendRequest(
    "post",
    `/influencer/wallet?influencerId=${influencerId}`,
    false
  ).then((res) => res.data);
};
