import { sendRequest } from "../helper";

interface getInfluencerPlacesResponse {
  status: string;
  message: string;
  data: {
    placeId: string;
    name: string;
  }[];
}

export const getInfluencerPlaces = ({ query = "" }: { query: string }) => {
  const queryKey = ["influencer-places", query];

  const queryFunction = () =>
    sendRequest<getInfluencerPlacesResponse>(
      "get",
      `/influencer/me/autocomplete-places?searchQuery=${query || "india"}`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
