import React, { useState, useEffect } from "react";

import { CUserType } from "../../../handler/Brand/org";

import { useMutation } from "react-query";

import { removeFromOrganization } from "../../../handler/Brand/org";

import { Modal, Checkbox, message } from "antd";
import { AxiosError } from "axios";

interface Props {
  userId: string;
  visible: boolean;
  organizations: CUserType["organizations"];
  handleClose: () => void;
  handleSubmit: () => void;
}

const RemoveOrganizationModal: React.FC<Props> = ({
  userId,
  visible,
  handleClose,
  handleSubmit,
  organizations,
}) => {
  const [uInput, setUInput] = useState<string[]>([]);

  useEffect(() => {
    if (!visible) {
      setUInput([]);
    }
  }, [visible]);

  const removeOrgMutation = useMutation(removeFromOrganization, {
    onSuccess: () => {
      message.info("Removed user");
      handleSubmit();
    },
    onError: (err: AxiosError) => {
      console.log(err);
    },
  });

  return (
    <Modal
      open={visible}
      okText={`Remove `}
      onOk={() => {
        removeOrgMutation.mutate({ orgIds: uInput, userId });
      }}
      okButtonProps={{
        loading: removeOrgMutation.isLoading,
        disabled: !Boolean(uInput.length),
      }}
      onCancel={handleClose}
      title={"Remove User from Organizations"}>
      <h3>Select the Organizations you want to remove user from</h3>
      <Checkbox.Group
        style={{ width: "100%", display: "grid", gap: 8 }}
        value={uInput}
        onChange={(e) => setUInput(e.map((item) => item?.toString()))}>
        {organizations?.map((orgItem) => (
          <Checkbox value={orgItem?.ownerDetails?._id}>
            <div style={{ display: "grid" }}>
              <p>{orgItem?.ownerDetails?.businessDetails?.name}</p>
              <p>Owner:{orgItem?.ownerDetails?.name}</p>
            </div>
          </Checkbox>
        ))}
      </Checkbox.Group>
    </Modal>
  );
};

export default RemoveOrganizationModal;
