import { Campaigns } from "../../typings";
import { sendRequest } from "../helper";

export type campaignResult = {
  status: "SUCCESS";
  message: "Fetching All Campaigns";
  data: Campaigns[];
};

type getAllCampaignsProps = {
  page?: number;
  searchQuery: {
    text: string;
    brandId: string;
    status: string;
    type?: "report-only" | "execution";
  };
};

export const getAllCampaigns = ({
  page,
  searchQuery,
}: getAllCampaignsProps) => {
  const queryKey = ["campaigns", page, searchQuery];

  const queryFunction = () =>
    sendRequest<campaignResult>(
      "get",
      `/brand/campaign?limit=7&page=${page}&status=${searchQuery.status}&brandId=${searchQuery.brandId}&text=${searchQuery.text}&type=${searchQuery.type}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
