import React, { useState } from "react";
import styles from "./CampaignTile.module.css";
import { Campaigns } from "../../../../typings";
import { format } from "date-fns";
import {
  ClockCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import CampaignDrawer from "../../../Drawers/CampaignDrawer";
import CampaignPaymentDrawer from "../../../Drawers/CampaignPaymentDrawer";
import ReportCampaignDrawer from "../../../Drawers/ReportCampaignDrawer";

interface CampaignTileProps {
  tileData: Campaigns;
  type: "report-only" | "execution";
  getAllCampaignsKey: (
    | string
    | number
    | {
        text: string;
        status: string;
        brandId: string;
      }
    | undefined
  )[];
}
const CampaignTile: React.FC<CampaignTileProps> = ({
  tileData,
  getAllCampaignsKey,
  type,
}) => {
  //Use States
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openPaymentDrawer, setOpenPaymentDrawer] = useState<boolean>(false);

  const [campaignReportDrawerVisible, setCampaignReportDrawerVisible] =
    useState<boolean>(false);
  return (
    <>
      <div
        onClick={() => {
          if (type === "report-only") {
            return setCampaignReportDrawerVisible(true);
          }
          if (tileData.status === "Active") {
            setOpenPaymentDrawer(true);
          } else {
            setOpenDrawer(true);
          }
        }}
        className={styles.tile}>
        {tileData?.lastUpdatedDateTime ? (
          <p>
            {format(
              new Date(tileData?.lastUpdatedDateTime),
              "hh:mm a, do LLL yy"
            )}
          </p>
        ) : (
          <p>-</p>
        )}
        <p>
          {tileData?.brand.length === 0
            ? "-"
            : tileData?.brand[0].businessDetails.name}
        </p>
        <p>{tileData?.campaignName === "" ? "-" : tileData?.campaignName}</p>
        <p>
          {tileData?.status === "Under-Review" && (
            <>
              <span className="">
                <ClockCircleOutlined style={{ color: "#FF8F1F" }} />
              </span>{" "}
              <span style={{ color: "#FF8F1F" }}>Review Pending</span>
            </>
          )}
          {tileData?.status === "Active" && (
            <>
              <span>
                <CheckOutlined style={{ color: "#00B578" }} />
              </span>{" "}
              <span style={{ color: "#00B578" }}>Approved</span>
            </>
          )}
          {tileData?.status === "Needs-Attention" && (
            <>
              <span>
                <CloseOutlined style={{ color: "#FF3141" }} />
              </span>{" "}
              <span style={{ color: "#FF3141" }}>Not Approved</span>
            </>
          )}
        </p>
      </div>
      <CampaignDrawer
        id={tileData?._id}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        getAllCampaignsKey={getAllCampaignsKey}
      />
      <CampaignPaymentDrawer
        openPaymentDrawer={openPaymentDrawer}
        setOpenPaymentDrawer={setOpenPaymentDrawer}
        setOpenDrawer={setOpenDrawer}
        campaignId={tileData._id}
        openFromInfluencerDrawer={false}
        brandId={tileData?.brandId}
        dealId={tileData?.defaultDeal}
      />
      <ReportCampaignDrawer
        visible={campaignReportDrawerVisible}
        handleClose={() => setCampaignReportDrawerVisible(false)}
        campaignId={tileData?._id}
      />
    </>
  );
};

export default CampaignTile;
