import { sendRequest } from "../helper";
import { KycConst } from "../../const/kycStatus.const";

interface changeKycStatusProps {
  brandId: string;
  status: string;
  remarks: string;
}

export const changeKycStatus = (props: changeKycStatusProps) => {
  return sendRequest("put", `/brand/kyc/change`, false, { ...props }).then(
    (res) => res.data
  );
};
