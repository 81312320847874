import { sendRequest } from "../helper";

type getFrontEndLogsResponse = {
	status: string;
	message: string;
	data: any;
};

export const getFrontEndErrorLogs = ({
	page,
	limit,
}: {
	page: number;
	limit: number;
}) => {
	const queryKey = ["front-end-log", page];
	const queryFunction = () =>
		sendRequest<getFrontEndLogsResponse>(
			"get",
			`/log?logsType=Front-End-Error&page=${page}&limit=${limit}`,
			false,
		).then((res) => {
			return res.data;
		});
	return [queryKey, queryFunction] as const;
};
