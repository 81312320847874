import React, { useState, useEffect } from "react";
import { Modal, Input } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { updateInfluencer } from "../../../../handler/Influencer";

const { TextArea } = Input;

interface RejectInfluencerModalProps {
  visible: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  getAllInfluencersKey?: (
    | string
    | number
    | {
        text: string;
        received: string;
        kycStatus: string;
        isPriceRequested: boolean;
        minFollowers?: number | undefined;
        maxFollowers?: number | undefined;
      }
    | undefined
  )[];
  getAllInfluencersCountKey?: (
    | string
    | number
    | {
        text: string;
        received: string;
        kycStatus: string;
        isPriceRequested: boolean;
        minFollowers?: number | undefined;
        maxFollowers?: number | undefined;
      }
    | undefined
  )[];
  getInfluencerKey: (string | undefined)[];
  influencerId: string;
}
const RejectInfluencerModal: React.FC<RejectInfluencerModalProps> = ({
  visible,
  handleCancel,
  handleSubmit,
  getAllInfluencersCountKey,
  getAllInfluencersKey,
  getInfluencerKey,
  influencerId,
}) => {
  const [rejectReason, setRejectReason] = useState<string>("");
  const queryClient = useQueryClient();
  useEffect(() => {
    if (!visible) {
      setRejectReason("");
    }
  }, [visible]);

  const updateInfluencerMutation = useMutation(updateInfluencer, {
    onSuccess: () => {
      queryClient.invalidateQueries(getInfluencerKey);
      queryClient.invalidateQueries(getAllInfluencersKey);
      queryClient.invalidateQueries(getAllInfluencersCountKey);
      handleSubmit();
    },
  });

  return (
    <Modal
      open={visible}
      okButtonProps={{ disabled: rejectReason.trim().length === 0 }}
      onCancel={handleCancel}
      onOk={() =>
        updateInfluencerMutation.mutate({
          influencerId,
          adminStatus: "Archive",
          adminStatusReason: rejectReason,
        })
      }>
      <h2> Archive Reason</h2>
      <TextArea
        placeholder="Please Enter reason"
        value={rejectReason}
        onChange={(e) => setRejectReason(e.target.value)}
      />
    </Modal>
  );
};

export default RejectInfluencerModal;
