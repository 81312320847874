import { sendRequest } from "../helper";

interface AddUserProps {
  email: string;
  password: string;
  name: string;
  accessLevel: string[];
  notificationPreferences: {
    isSmsEnabled: boolean;
    isWhatsappEnabled: boolean;
    isEmailEnabled: boolean;
    isPushEnabled: boolean;
  };
}

export const addUser = (userData: AddUserProps) => {
  return sendRequest("post", `/admin/user`, false, userData).then(
    (res) => res.data
  );
};
