import React, { useEffect } from "react";
import type { MenuProps } from "antd";
import { Layout, Image, Button, Modal } from "antd";
import styles from "./mainLayout.module.css";
import { useAuth } from "../context/AuthContext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MainRoutes from "../routes";
import { getUserRole } from "../handler/User/getUserRole";
import { useQuery } from "react-query";

const { Sider } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useAuth();
  const { logout } = useAuth();
  const [queryKey, queryFunction] = getUserRole();
  const { data, isLoading, error } = useQuery(queryKey, queryFunction);

  const accessLevel = data?.admin.accessLevel;

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      alert("Failed to Logout!");
    }
  };

  if (isLoading) return <>...</>;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            height: "100vh",
            paddingBottom: "15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="">
            <div className={styles.logo}>
              <Image
                preview={false}
                width={35}
                src="/assets/img/cloutflow.png"
              />
            </div>
            <div className={styles.navOptions}>
              {accessLevel.includes("User") && (
                <NavLink
                  to={"/cloutflow-users"}
                  className={({ isActive }) =>
                    isActive ? styles.optionActive : styles.option
                  }
                >
                  <p>Cloutflow Users</p>
                </NavLink>
              )}
              {accessLevel.includes("User") && (
                <NavLink
                  to={"/admin"}
                  className={({ isActive }) =>
                    isActive ? styles.optionActive : styles.option
                  }
                >
                  <p>Admins</p>
                </NavLink>
              )}
              {accessLevel.includes("Brand") && (
                <NavLink
                  to={"/"}
                  className={({ isActive }) =>
                    isActive ? styles.optionActive : styles.option
                  }
                >
                  <p>Brand</p>
                </NavLink>
              )}
              {accessLevel.includes("Creator") && (
                <NavLink
                  to={"/creator"}
                  className={({ isActive }) =>
                    isActive ? styles.optionActive : styles.option
                  }
                >
                  <p>Creator</p>
                </NavLink>
              )}
              {accessLevel.includes("Campaign") && (
                <NavLink
                  to={"/campaign"}
                  className={({ isActive }) =>
                    isActive ? styles.optionActive : styles.option
                  }
                >
                  <p>Campaign</p>
                </NavLink>
              )}
              {accessLevel.includes("Logs") && (
                <NavLink
                  to={"/logs"}
                  className={({ isActive }) =>
                    isActive ? styles.optionActive : styles.option
                  }
                >
                  <p>Logs</p>
                </NavLink>
              )}
              {accessLevel.includes("Connect-Request") && (
                <NavLink
                  to={"/onboard"}
                  className={({ isActive }) =>
                    isActive ? styles.optionActive : styles.option
                  }
                >
                  <p>Connect Request</p>
                </NavLink>
              )}
              {accessLevel.includes("Community") && (
                <NavLink
                  to={"/community"}
                  className={({ isActive }) =>
                    isActive ? styles.optionActive : styles.option
                  }
                >
                  <p>Community</p>
                </NavLink>
              )}
              {accessLevel.includes("Restaurant") && (
                <NavLink
                  to={"/restaurants"}
                  className={({ isActive }) =>
                    isActive ? styles.optionActive : styles.option
                  }
                >
                  <p>Restaurants</p>
                </NavLink>
              )}
              {accessLevel.includes("Payouts") && (
                <NavLink
                  to={"/payouts"}
                  className={({ isActive }) =>
                    isActive ? styles.optionActive : styles.option
                  }
                >
                  <p>Payouts</p>
                </NavLink>
              )}
              {accessLevel.includes("Extention") && (
                <NavLink
                  to={"/extensionCodes"}
                  className={({ isActive }) =>
                    isActive ? styles.optionActive : styles.option
                  }
                >
                  <p>Extension Codes</p>
                </NavLink>
              )}
            </div>{" "}
          </div>
          <p onClick={handleLogout} className={styles.signout}>
            {"SignOut " + currentUser?.email}
          </p>
        </div>
      </Sider>

      <Layout className={styles.layout}>
        <div className={styles.header}>
          <p>
            {location.pathname === "/"
              ? "Brand"
              : location.pathname === "/creator"
              ? "Creator"
              : location.pathname === "/logs"
              ? "Logs"
              : location.pathname === "/onboard"
              ? "Onboard Creators"
              : location.pathname === "/restaurants"
              ? "Restaurants"
              : location.pathname === "/payouts"
              ? "Payouts"
              : location.pathname === "/community"
              ? "Community"
              : location.pathname === "/extensionCodes"
              ? "Extension Codes"
              : location.pathname === "/cloutflow-users"
              ? "Cloutflow Users"
              : location.pathname == "/admin"
              ? "Admins"
              : "Campaign"}
          </p>
        </div>

        <div>
          <MainRoutes />
        </div>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
