import { sendRequest } from "../helper";

interface updateInfluencerProps {
  influencerId?: string;
  kycGst?: string;
  kycGstVerificationDocRef?: string[];
  kycPan?: string;
  kycPanVerificationDocRef?: string[];
  kycIsGstRegistered?: boolean;
  kycAddress?: Partial<{
    line: string;
    city: string;
    pincode: string;
    country: string;
    state: string;
  }>;
  kycCompanyName?: string;
  adminStatus?: string;
  adminStatusReason?: string;
}

export const updateInfluencer = ({
  influencerId,
  kycGst,
  kycGstVerificationDocRef,
  kycPan,
  kycPanVerificationDocRef,
  kycIsGstRegistered,
  kycAddress,
  kycCompanyName,
  adminStatus,
  adminStatusReason,
}: updateInfluencerProps) => {
  return sendRequest(
    "put",
    `/influencer/me?influencerId=${influencerId}`,
    false,
    {
      kycGst,
      kycGstVerificationDocRef,
      kycPan,
      kycPanVerificationDocRef,
      kycIsGstRegistered,
      kycAddress,
      kycCompanyName,
      adminStatus,
      adminStatusReason,
    }
  ).then((res) => res.data);
};
