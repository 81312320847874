import { sendRequest } from "../helper";

type getTotalCampaignDealCountResult = {
  status: string;
  message: string;
  data: number;
};

export const getTotalCampaignDealCount = (campaignId?: string) => {
  const queryKey = ["campaigns", "deal", "count", campaignId];
  const queryFunction = () =>
    sendRequest<getTotalCampaignDealCountResult>(
      "get",
      `/brand/offer/payment/in-payment-count?campaignId=${campaignId}`,
      false
    ).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
