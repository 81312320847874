import { sendRequest } from "../helper";

interface manualPaymentOtpProps {
  offerPayInId?: string;
  utr: string;
}

export const manualPaymentOtp = ({
  offerPayInId,
  utr,
}: manualPaymentOtpProps) => {
  return sendRequest(
    "post",
    `/brand/offer/payment/manual-in-otp?offerPayInId=${offerPayInId}&utr=${utr}`,
    false
  ).then((res) => res.data);
};
