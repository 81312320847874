import { format } from "date-fns";
import nf from "@tuplo/numberfmt";
import styles from "./InfluencerPaymentTile.module.css";
import { Button, Input, message, Modal } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { cancelDeal } from "../../../../handler/Influencer";
import { AxiosError } from "axios";
import { PaymentOffers } from "../../../../typings";
import CampaignDrawer from "../../CampaignDrawer";
import CampaignPaymentDrawer from "../../CampaignPaymentDrawer";
const { TextArea } = Input;
interface InfluencerPaymentTileProps {
  influencerPayment: PaymentOffers;
  getInfluencerAllPaymentsKey: (string | number | undefined)[];
}

const InfluencerPaymentTile: React.FC<InfluencerPaymentTileProps> = ({
  influencerPayment,
  getInfluencerAllPaymentsKey,
}) => {
  const queryClient = useQueryClient();
  // Use States
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openPaymentDrawer, setOpenPaymentDrawer] = useState<boolean>(false);
  const [viewRemarks, setViewRemarks] = useState<boolean>(false);
  const [openCancelDealModal, setOpenCancelDealModal] =
    useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>();
  const [finalizedOfferId, setFinalizedOfferId] = useState<string>();

  // Cancel Deal Mutatation
  const cancelDealMutation = useMutation(cancelDeal, {
    onSuccess: () => {
      message.success("Deal Cancelled");
      setOpenCancelDealModal(false);
      setFinalizedOfferId("");
      queryClient.invalidateQueries(getInfluencerAllPaymentsKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <>
      <div className={styles.campaign}>
        <p
          onClick={() => setOpenPaymentDrawer(true)}
          className={styles.timeStamp}>
          {format(
            new Date(influencerPayment.createdDateTime),
            "hh:mm a, do LLL yy"
          )}
        </p>
        <p onClick={() => setOpenPaymentDrawer(true)}>
          <p className={styles.campaignName}>
            {influencerPayment.campaign.campaignName}
          </p>{" "}
          <p className={styles.brandName}>
            {influencerPayment.brand.businessDetails.name}
          </p>
        </p>
        <p
          onClick={() => setOpenPaymentDrawer(true)}
          className={styles.platform}>
          <img src={`/assets/img/${influencerPayment.platform}.png`} alt="" />
          <div className="">
            <p>
              {influencerPayment.platform === "instagram" && "@"}

              {influencerPayment.socialMediaKit?.socialMediaName}
            </p>
            <span className={styles.platformStatus}>
              {influencerPayment.socialMediaKit?.isSocialMediaRevoked
                ? "Revoked"
                : influencerPayment.socialMediaKit?.isSocialMediaConnected ===
                    false && "Disconnected"}
            </span>
          </div>
        </p>
        <p
          onClick={() => setOpenPaymentDrawer(true)}
          className={styles.totalPrice}>
          ₹{nf(influencerPayment.billingInfo.paidToInfluencerPrice, "0,0.00")}
        </p>
        <p
          onClick={() => setOpenPaymentDrawer(true)}
          className={styles.totalPrice}>
          ₹{nf(influencerPayment.billingInfo.totalPrice, "0,0.00")}
        </p>

        <p>
          <p onClick={() => setOpenPaymentDrawer(true)}>
            {influencerPayment?.finalizedOffers?.ecrowChequeStatus}
          </p>
          {influencerPayment?.finalizedOffers?.ecrowChequeStatus ===
            "Cancel" && (
            <p
              onClick={() => {
                setViewRemarks(true);
                setRemarks(
                  influencerPayment?.finalizedOffers?.Offerpay?.remarks
                );
              }}
              className={styles.viewRemarks}>
              View Remarks
            </p>
          )}
        </p>
        {(influencerPayment?.finalizedOffers?.ecrowChequeStatus ===
          "Approved" ||
          influencerPayment?.finalizedOffers?.ecrowChequeStatus ===
            "Acquired") && (
          <Button
            onClick={() => {
              setFinalizedOfferId(influencerPayment?.finalizedOffers?._id);
              setOpenCancelDealModal(true);
            }}>
            Cancel deal
          </Button>
        )}
      </div>
      <Modal
        visible={viewRemarks}
        title="Remarks"
        cancelButtonProps={{ hidden: true }}
        onOk={() => {
          setViewRemarks(false);
          setRemarks("");
        }}
        onCancel={() => {
          setViewRemarks(false);
          setRemarks("");
        }}>
        <p>{remarks}</p>
      </Modal>
      <Modal
        onOk={() => cancelDealMutation.mutate({ finalizedOfferId, remarks })}
        visible={openCancelDealModal}
        title="Cancel Deal"
        okButtonProps={{
          disabled: remarks === "",
          loading: cancelDealMutation.isLoading,
        }}
        onCancel={() => setOpenCancelDealModal(false)}>
        <p>Cancellation reason</p>
        <TextArea
          rows={5}
          style={{ marginTop: "10px" }}
          placeholder="Write remakrs"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        />
      </Modal>
      <CampaignDrawer
        id={influencerPayment?.campaignId}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
      <CampaignPaymentDrawer
        openPaymentDrawer={openPaymentDrawer}
        setOpenPaymentDrawer={setOpenPaymentDrawer}
        setOpenDrawer={setOpenDrawer}
        campaignId={influencerPayment?.campaignId}
        openFromInfluencerDrawer={true}
        dealId={influencerPayment.dealId}
        brandId={influencerPayment.brandId}
      />
    </>
  );
};

export default InfluencerPaymentTile;
