const KycConst = {
  status: {
    NOT_INITIATED: "Not-Initiated",
    UNDER_REVIEW: "Under-Review",
    VERIFIED: "Verified",
    NEEDS_ATTENTION: "Needs-Attention",
    NON_VERIFIED: "Non-Verified",
  },
};

export { KycConst };
