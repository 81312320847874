import { Brand } from "../../../typings";
import { sendRequest } from "../../helper";

type brandResult = {
  status: string;
  message: string;
  data: { users: Brand[] };
};

type getAllOrganizationsProps = {
  limit: number;
  query: string;
};

export const getAllOrganizations = ({
  limit,
  query,
}: getAllOrganizationsProps) => {
  const queryKey = ["organizations", limit, query];

  const queryFunction = () =>
    sendRequest<brandResult>(
      "get",
      `/brand/organization/?limit=${limit}&query=${query}`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
