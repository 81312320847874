import { Skeleton, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { Input } from "antd";
import { format } from "date-fns";

import { useQuery } from "react-query";
import { getSocialMediaConnectErrorLog } from "../../../../handler/Logs/getSocialMediaConnectErrorLog";
import { getSocialMediaConnectLogsCount } from "../../../../handler/Logs/getSocialMediaErrorLogsCount";
import SocialMediaConnectErrorLogsDrawer from "../../../Drawers/SocialMediaConnectErrorDrawer";
const { Search } = Input;

const SocialMediaConnectErrorLogs = () => {
	const [pageParams, setPageParams] = useState({
		page: 1,
		limit: 10,
		phoneNumber: "",
		influencerId: "",
		call: Array<string>(0),
		status: Array<string>(0),
		socialMediaPlatformType: Array<string>(0),
	});

	const [getTotalCountKey, getTotalCountFn] = getSocialMediaConnectLogsCount({
		...pageParams,
	});
	const { data: totalCount, isLoading: isCountLoading } = useQuery(
		getTotalCountKey,
		getTotalCountFn,
	);

	const [getSocialMediaConnectErrorKey, getFrontEndErrorLogsFn] =
		getSocialMediaConnectErrorLog(pageParams);

	const { data, isLoading } = useQuery(
		getSocialMediaConnectErrorKey,
		getFrontEndErrorLogsFn,
	);

	const [drawerParams, setDrawerParams] = useState<{
		isOpen: Boolean;
		_id: string;
		getSocialMediaConnectErrorCountKey: Array<any>;
		getSocialMediaConnectErrorKey: Array<any>;
	}>({
		isOpen: false,
		_id: "",
		getSocialMediaConnectErrorCountKey: [],
		getSocialMediaConnectErrorKey: [],
	});

	useEffect(() => {
		setDrawerParams((prev) => {
			return {
				...prev,
				getSocialMediaConnectErrorCountKey: getTotalCountKey,
				getSocialMediaConnectErrorKey: getSocialMediaConnectErrorKey,
			};
		});
	}, [getTotalCountKey.toString(), getSocialMediaConnectErrorKey.toString()]);

	const onPhoneNumberChange = (val: string) => {
		setPageParams((prev) => {
			return {
				...prev,
				phoneNumber: val,
			};
		});
	};

	const onInfluencerIdChange = (val: string) => {
		setPageParams((prev) => {
			return {
				...prev,
				influencerId: val,
			};
		});
	};

	const onTableParamsChange = (
		pagination: any,
		filters: any,
		sorter: any,
		extra: any,
	) => {
		var _call: Array<string> = pageParams.call || [];

		if (filters.call) {
			_call = filters?.call;
		} else {
			_call = [];
		}

		var _status: Array<string> = pageParams.status || [];
		if (filters.status) {
			_status = filters?.status;
		} else {
			_status = [];
		}

		var _socialMediaPlatformType: Array<string> =
			pageParams.socialMediaPlatformType || [];

		if (filters.socialMediaPlatformType) {
			_socialMediaPlatformType = filters?.socialMediaPlatformType;
		} else {
			_socialMediaPlatformType = [];
		}

		setPageParams((prev: any) => {
			return {
				...prev,
				page: pagination.current,
				call: _call,
				status: _status,
				socialMediaPlatformType: _socialMediaPlatformType,
			};
		});
	};

	const columns = [
		{
			title: "createdDateTime",
			dataIndex: "createdDateTime",
			key: "createdDateTime",
		},

		{
			title: "Influencer id",
			dataIndex: "influencerId",
			key: "influencerId",
		},

		{
			title: "Phone Number",
			dataIndex: "phoneNumber",
			key: "phoneNumber",
		},

		{
			title: "status",
			dataIndex: "status",
			key: "status",
			filters: [
				{
					text: "Open",
					value: "Open",
				},
				{
					text: "Pending",
					value: "Pending",
				},
				{
					text: "Resolved",
					value: "Resolved",
				},
				{
					text: "Critical",
					value: "Critical",
				},
				{
					text: "All",
					value: "",
				},
			],
			filterSearch: true,
			defaultFilteredValue:
				pageParams.status.length === 0 ? null : pageParams.status,
		},

		{
			title: "Social Media Platform",
			dataIndex: "socialMediaPlatformType",
			key: "socialMediaPlatformType",
			filters: [
				{
					text: "Instagram",
					value: "instagram",
				},
				{
					text: "Youtube",
					value: "youtube",
				},
				{
					text: "All",
					value: "",
				},
			],
			filterSearch: true,
			defaultFilteredValue:
				pageParams.socialMediaPlatformType.length === 0
					? null
					: pageParams.socialMediaPlatformType,
		},
		{
			title: "call",
			dataIndex: "call",
			key: "call",
			filters: [
				{
					text: "Ig-Eligibility",
					value: "Ig-Eligibility",
				},
				{
					text: "Ig-Access",
					value: "Ig-Access",
				},
				{
					text: "Yt-Access",
					value: "Yt-Access",
				},

				{
					text: "Ig-Revoke-Reconnect",
					value: "Ig-Revoke-Reconnect",
				},

				{
					text: "Ig-Singular-Connect-V2",
					value: "Ig-Singular-Connect-V2",
				},
				{
					text: "Ig-Singular-Connect-Iterative-V2",
					value: "Ig-Singular-Connect-Iterative-V2",
				},
				{
					text: "Ig-Access-V2",
					value: "Ig-Access-V2",
				},
				{
					text: "Ig-Eligibility-V2",
					value: "Ig-Eligibility-V2",
				},

				{
					text: "All",
					value: "",
				},
			],
			filterSearch: true,
			defaultFilteredValue:
				pageParams.call.length === 0 ? null : pageParams.call,
		},
		{
			title: "error",
			dataIndex: "error",
			key: "error",
		},
	];

	return (
		<>
			{isCountLoading || isLoading ? (
				<Skeleton paragraph={{ rows: 10 }} />
			) : (
				<>
					<div style={{ margin: "1rem" }}>
						<div style={{ display: "inline-block", margin: ".5rem" }}>
							<Search
								placeholder="Influencer Id"
								onSearch={onInfluencerIdChange}
								style={{ width: 200 }}
								defaultValue={pageParams.influencerId}
							/>
						</div>
						<div style={{ display: "inline-block", margin: ".5rem" }}>
							<Search
								placeholder="Phone Number"
								onSearch={onPhoneNumberChange}
								style={{ width: 200 }}
								defaultValue={pageParams.phoneNumber}
							/>
						</div>
					</div>

					<Table
						size={"middle"}
						scroll={{ y: "70vh" }}
						dataSource={
							data?.data?.map((ele: any) => {
								return {
									key: ele._id,
									...ele,
									error: (
										<ReactJson
											src={ele?.logInfo?.error}
											collapsed={true}
											collapseStringsAfterLength={1}
										></ReactJson>
									),
									socialMediaPlatformType:
										ele?.logInfo?.socialMediaPlatformType,
									call: ele?.logInfo?.call,

									createdDateTime: format(
										new Date(ele.createdDateTime),
										"hh:mm a, do LLL yy",
									),
								};
							}) || []
						}
						columns={columns}
						pagination={{
							pageSize: 10,
							total: totalCount?.data.count,
							pageSizeOptions: [10],
							current: pageParams.page,
						}}
						onChange={onTableParamsChange}
						onRow={(record, rowIdx) => {
							return {
								onClick: (event: any) => {
									setDrawerParams((prev) => {
										return {
											...prev,
											isOpen: true,
											_id: record?._id,
										};
									});
								},
							};
						}}
					/>

					{drawerParams.isOpen ? (
						<SocialMediaConnectErrorLogsDrawer
							drawerParams={drawerParams}
							setDrawerParams={setDrawerParams}
						></SocialMediaConnectErrorLogsDrawer>
					) : (
						<></>
					)}
				</>
			)}
		</>
	);
};

export default SocialMediaConnectErrorLogs;
