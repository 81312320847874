import React, { useState, useEffect } from "react";
import { Modal, InputNumber, Input, message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import EditUserDetailsModal from "../../Drawers/EditUserDetailsModal";
import { createInfluencer } from "../../../handler/Influencer";
import { getInfluencer } from "../../../handler/Influencer";
import styles from "./create.influencer.module.css";

interface Props {
  visible: boolean;
  handleClose: () => void;
}

const CreateInfluencer: React.FC<Props> = ({ visible, handleClose }) => {
  const [uInput, setUInput] = useState<{
    name: string;
    lastName: string;
    phoneNumber: string;
  }>({
    name: "",
    lastName: "",
    phoneNumber: "",
  });

  const queryClient = useQueryClient();

  const createInfluencerMutation = useMutation(createInfluencer, {
    onSuccess: () => {
      message.success("Influencer Created");
      setEditUserDetailsModalVisible(true);
      handleClose();
    },
    onError: (err: AxiosError) => {
      message.error((err.response?.data as any)?.message);
    },
  });

  const handleSubmit = () => {
    if (!uInput?.name?.trim().length) {
      return message.error("Please Enter Creator Name");
    }

    if (uInput?.phoneNumber?.trim().length !== 10) {
      return message.error("Please Enter Valid Contact Number");
    }

    return createInfluencerMutation.mutate({
      fName: uInput.name,
      lName: uInput.lastName,
      phoneNumber: `+91${uInput.phoneNumber}`,
    });
  };

  const [editUserDetailsModalVisible, setEditUserDetailsModalVisible] =
    useState<boolean>(false);

  const [influencerKey, _] = getInfluencer(
    createInfluencerMutation.data?.data?._id
  );

  useEffect(() => {
    if (!visible) {
      setUInput({
        name: "",
        lastName: "",
        phoneNumber: "",
      });
    }
  }, [visible]);

  console.log("hello", createInfluencerMutation.data);

  return (
    <>
      <Modal
        open={visible}
        onCancel={handleClose}
        title="Create Influencer"
        okText="Add"
        okButtonProps={{ loading: createInfluencerMutation.isLoading }}
        onOk={() => handleSubmit()}
      >
        <div className={styles.container}>
          <div className={styles.inputWrapper}>
            <p>Creator First Name</p>
            <Input
              value={uInput.name}
              onChange={(e) =>
                setUInput((prev) => ({ ...prev, name: e.target.value }))
              }
              placeholder="John"
            />
          </div>
          <div className={styles.inputWrapper}>
            <p>Creator Last Name</p>
            <Input
              value={uInput.lastName}
              onChange={(e) =>
                setUInput((prev) => ({ ...prev, lastName: e.target.value }))
              }
              placeholder="Watson"
            />
          </div>
          <div className={styles.inputWrapper}>
            <p>Phone Number</p>
            <InputNumber
              value={uInput.phoneNumber}
              style={{ width: "100%" }}
              onChange={(value) =>
                setUInput((prev) => ({
                  ...prev,
                  phoneNumber: String(value || ""),
                }))
              }
              placeholder="9897123496"
              prefix="(Ind) +91"
            />
          </div>
        </div>
      </Modal>
      <EditUserDetailsModal
        handleClose={() => setEditUserDetailsModalVisible(false)}
        visible={editUserDetailsModalVisible}
        initialData={createInfluencerMutation.data?.data || null}
        handleSubmit={() => {
          setTimeout(() => {
            queryClient.invalidateQueries(["influencers"]);
          }, 5000);
          setEditUserDetailsModalVisible(false);
        }}
        getInfluencerKey={influencerKey}
      />
    </>
  );
};

export default CreateInfluencer;
