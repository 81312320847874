import React from "react";
import { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Row,
  Col,
  Button,
  Divider,
  Tag,
  Spin,
  Empty,
  message,
} from "antd";
import {
  collection,
  query,
  getDocs,
  onSnapshot,
  orderBy,
  startAfter,
  limit,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import InfiniteScroll from "react-infinite-scroller";

// import AddModal from "./AddModal";

import { firebaseDb } from "../../../firebase/firebase";

import AddModal from "./AddModal";

export type CodeInfo = {
  name: string;
  code: string;
  id: string;
  isActive: boolean;
};
const ExtensionCodes = () => {
  const [addModalProps, setAddModalProps] = useState<{
    visible: boolean;
    data: CodeInfo | null;
  }>({
    visible: false,
    data: null,
  });

  const [input, setInput] = useState<string>("");

  const [resData, setResData] = useState<CodeInfo[]>([]);
  const [docSnapshot, setDocSnapshot] = useState<any>();

  const fetchCreatorData = async () => {
    let arr = [...resData];
    const firebaseQuery = query(
      collection(firebaseDb, "ExtensionCodes"),
      orderBy("createdDateTime", "desc"),
      limit(10)
    );

    const dataListener = onSnapshot(firebaseQuery, (snapshot) => {
      setDocSnapshot(snapshot.docs);
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (arr.findIndex((not) => not.id === change.doc.id) === -1) {
            arr.push({
              ...change.doc.data(),
              id: change.doc.id,
            } as CodeInfo);
          }
        }
        if (change.type === "modified") {
          const newArray = arr.map((item) => {
            if (item.id === change.doc.id) {
              return {
                ...change.doc.data(),
                id: change.doc.id,
              } as CodeInfo;
            }
            return item;
          });
          arr = newArray;
        }
        if (change.type === "removed") {
          const newArray = arr.filter((item) => item.id !== change.doc.id);
          arr = newArray;
        }
      });

      return setResData(arr);
    });
    return dataListener;
  };

  const loadMore = async () => {
    if (docSnapshot[docSnapshot.length - 1] !== undefined) {
      const next = query(
        collection(firebaseDb, "ExtensionCodes"),
        orderBy("createdDateTime", "desc"),
        startAfter(docSnapshot[docSnapshot.length - 1]),
        limit(10)
      );

      const documentSnapshots = await getDocs(next);

      let docData: CodeInfo[] = [];

      documentSnapshots.forEach((doc) => {
        docData.push({
          id: doc.id,
          ...doc.data(),
        } as CodeInfo);
      });
      setResData((resData) => [...resData, ...docData]);
      setDocSnapshot(documentSnapshots.docs);
    }
  };

  useEffect(() => {
    fetchCreatorData();
  }, []);

  return (
    <div>
      <Row justify={"space-between"}>
        <div>
          <p> Search by name or code</p>
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value || "")}
            style={{ marginBottom: 20 }}
          />
        </div>
        <Button
          type="primary"
          onClick={() => setAddModalProps({ visible: true, data: null })}>
          Add Codes
        </Button>
      </Row>
      <Row
        style={{
          background: " #f5f5f5",
          borderRadius: "10px",
          boxShadow:
            "inset 1px 0 0 #d9d9d9, inset -1px 0 0 #d9d9d9, inset 0 1px 0 #d9d9d9, inset 0 -1px 0 #d9d9d9",
          padding: " 16px 10px 16px 44px",
        }}>
        <Col span={6} id={"1"}>
          Account Holder Name
        </Col>
        <Col span={6} id={"2"}>
          Code
        </Col>
        <Col span={6} id={"3"}>
          Is Code Active{" "}
        </Col>
        <Col span={6} id={"4"}>
          Actions
        </Col>
      </Row>

      {resData?.length > 0 ? (
        <InfiniteScroll
          loadMore={() => {
            loadMore();
          }}
          // dataLength={resData.length}
          hasMore={docSnapshot[docSnapshot.length - 1] !== undefined}
          loader={
            <Row justify="center">
              <Spin />
            </Row>
          }
          style={{ height: "75vh", overflowY: "scroll" }}>
          {resData.length === 0 && <Empty description="No Codes" />}
          {resData
            ?.filter(
              (item) =>
                item.name.toLowerCase().includes(input) ||
                item.code.toLowerCase().includes(input)
            )
            ?.map((item) => {
              return (
                <div id={item.id}>
                  <Row
                    style={{
                      padding: " 16px 10px 16px 44px",
                    }}
                    key={item.id}>
                    <Col span={6}>{item.name}</Col>
                    <Col span={6}>{item.code}</Col>
                    <Col span={6}>
                      {item.isActive ? (
                        <Tag color="success"> Yes</Tag>
                      ) : (
                        <Tag color="error">NO</Tag>
                      )}
                    </Col>

                    <Col span={2}>
                      <Button
                        type="primary"
                        onClick={() =>
                          setAddModalProps({ visible: true, data: item })
                        }>
                        Edit
                      </Button>
                      <Button
                        style={{ marginTop: "10px" }}
                        onClick={async () =>
                          await updateDoc(
                            doc(firebaseDb, "ExtensionCodes", item.id),
                            { isActive: !item.isActive }
                          )
                        }>
                        Change Code Visibility
                      </Button>
                      <Button
                        style={{ marginTop: "10px" }}
                        type="primary"
                        danger
                        onClick={() =>
                          deleteDoc(
                            doc(firebaseDb, "ExtensionCodes", item.id)
                          ).then(() =>
                            message.success(
                              "ExtensionCode Info Deleted Successfully"
                            )
                          )
                        }>
                        Delete
                      </Button>
                    </Col>
                  </Row>
                  <Divider style={{ margin: "5px 0" }} />
                </div>
              );
            })}
        </InfiniteScroll>
      ) : (
        <Empty />
      )}
      <AddModal
        visible={addModalProps.visible}
        handleClose={() => setAddModalProps({ visible: false, data: null })}
        handleSubmit={() => setAddModalProps({ visible: false, data: null })}
        type={addModalProps?.data ? "Edit" : "Add"}
        initialData={addModalProps.data}
      />
    </div>
  );
};

export default ExtensionCodes;
