import React, { useState } from "react";
import {
	AppstoreOutlined,
	DisconnectOutlined,
	SettingOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import FrontEndLogs from "./Front End Logs";
import SocialMediaConnectErrorLogs from "./Social Media Connect Error";

const items: MenuProps["items"] = [
	{
		label: "Social Media Connect Error",
		key: "Social Media Connect Error",
		icon: <DisconnectOutlined />,
	},
	{
		label: "Front End Error",
		key: "Front End Error",
		icon: <AppstoreOutlined />,
	},
];

const Logs = () => {
	const [current, setCurrent] = useState("Social Media Connect Error");

	const onClick: MenuProps["onClick"] = (e) => {
		setCurrent(e.key);
	};

	return (
		<>
			<Menu
				onClick={onClick}
				selectedKeys={[current]}
				mode="horizontal"
				items={items}
			/>
			{current === "Front End Error" ? (
				<FrontEndLogs></FrontEndLogs>
			) : current === "Social Media Connect Error" ? (
				<SocialMediaConnectErrorLogs></SocialMediaConnectErrorLogs>
			) : (
				<div>None</div>
			)}
		</>
	);
};

export default Logs;
