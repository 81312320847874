import { sendRequest } from "../helper";

type getSocialMediaLogsByIdResponse = {
	status: string;
	message: string;
	data: any;
};

export const getSocialMediaConnectErrorLogById = ({ id }: { id: string }) => {
	const queryKey = ["social-media-connect-log", id];
	const queryFunction = () =>
		sendRequest<getSocialMediaLogsByIdResponse>(
			"get",
			`/log/by-id?logsType=Social-Media-Connect-Error&id=${id}`,
			false,
		).then((res) => {
			return res.data;
		});
	return [queryKey, queryFunction] as const;
};
