import { sendRequest } from "../helper";

interface DeleteUserProps {
  userId: string;
}

export const deleteUser = ({ userId }: DeleteUserProps) => {
  return sendRequest("delete", `/admin/admin/${userId}`, false).then(
    (res) => res.data
  );
};
