import { sendRequest } from "../helper";
import { Campaign } from "../../typings";

interface editCampaignProps {
  campaign: Campaign;
}

export const editCampaign = ({ campaign }: editCampaignProps) => {
  return sendRequest("put", `/brand/campaign/`, false, {
    campaign: campaign.campaign,
    deal: campaign.deal,
  }).then((res) => res.data);
};
