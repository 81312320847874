import axios from "axios";
import { Modal } from "antd";
import ReactJson from "react-json-view";

interface uploadNumberCSVProps {
  csvData: {
    name: string;
    phoneNumber: string;
    emailId: string;
  }[];
}

export const uploadNumberCSV = ({ csvData }: uploadNumberCSVProps) => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_CSV_UPLOAD_URL}`,
    data: { data: [...csvData] },

    headers: {
      "Content-Type": "application/json",
      token: "E0IoI1jHLFCoTeTw3Lv0e5loJu0oU1",
    },
  }).catch((err) => {
    Modal.error({
      title: "Error Occured",
      width: "1000px",
      content: <ReactJson src={err?.response?.data} collapsed={false} />,
    });
    throw err;
  });
};
