import { sendRequest } from "../helper";

type getFrontEndLogsCountResponse = {
	status: string;
	message: string;
	data: { count: number };
};

export const getFrontEndLogCount = () => {
	const queryKey = ["front-end-log-count"];
	const queryFunction = () =>
		sendRequest<getFrontEndLogsCountResponse>(
			"get",
			`/log/count?logsType=Front-End-Error`,
			false,
		).then((res) => {
			return res.data;
		});
	return [queryKey, queryFunction] as const;
};
