import { Button, message, Skeleton, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { Input } from "antd";
import { format } from "date-fns";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { getTnxPayoutsCount } from "../../../handler/Transaction/getPayoutsCount";
import { getTnxPayouts } from "../../../handler/Transaction/getPayouts";
import InfluencerKycDrawer from "../../Drawers/InfluencerKycDrawer";
import CampaignDrawer from "../../Drawers/CampaignDrawer";
import BrandKycDrawer from "../../Drawers/BrandKycDrawer";
import { releasePayoutTnx } from "../../../handler/Transaction/releasePayoutTnx";
import { AxiosError } from "axios";

const { Search } = Input;

const Payouts = () => {
	const queryClient = useQueryClient();

	const [pageParams, setPageParams] = useState({
		page: 1,
		limit: 10,
		status: ["Completed"],
		ecrowChequeStatus: ["Acquired"],
	});

	const [getTotalCountKey, getTotalCountFn] = getTnxPayoutsCount({
		status: pageParams.status,
		ecrowChequeStatus: pageParams.ecrowChequeStatus,
	});
	const { data: totalCount, isLoading: isCountLoading } = useQuery(
		getTotalCountKey,
		getTotalCountFn,
	);

	const [getTnxPayoutsKey, getTnxPayoutsFn] = getTnxPayouts(pageParams);

	const { data, isLoading } = useQuery(getTnxPayoutsKey, getTnxPayoutsFn);

	const [influencerDrawerParams, setInfluencerDrawerParams] = useState<{
		isOpen: boolean;
		_id: string;
	}>({
		isOpen: false,
		_id: "",
	});

	const [campaignDrawerParams, setcampaignDrawerParams] = useState<{
		isOpen: boolean;
		_id: string;
	}>({
		isOpen: false,
		_id: "",
	});

	const [brandDrawerParams, setBrandDrawerParams] = useState<{
		isOpen: boolean;
		_id: string;
	}>({
		isOpen: false,
		_id: "",
	});

	const releasePayoutTnxMutation = useMutation(releasePayoutTnx, {
		onSuccess: () => {
			message.success("Campaign Approved");
			queryClient.invalidateQueries(getTnxPayoutsKey);
			queryClient.invalidateQueries(getTotalCountKey);
		},
		onError: (err: AxiosError) => {
			console.log(err.code, err.message, "error-message", err);
		},
	});

	const onTableParamsChange = (
		pagination: any,
		filters: any,
		sorter: any,
		extra: any,
	) => {
		var _status: Array<string> = pageParams.status || ["Completed"];
		if (filters.status) {
			_status = filters?.status;
		} else {
			_status = ["Completed"];
		}

		var _escrowStatus: Array<string> = pageParams?.ecrowChequeStatus || [
			"Acquired",
		];
		if (filters.ecrowChequeStatus) {
			_escrowStatus = filters?.ecrowChequeStatus;
		} else {
			_escrowStatus = ["Acquired"];
		}

		setPageParams((prev: any) => {
			return {
				...prev,
				page: pagination.current,
				status: _status,
				ecrowChequeStatus: _escrowStatus,
			};
		});
	};

	const columns = [
		{
			title: "Campaign Name",
			dataIndex: "camapaignName",
			key: "campaignName",
		},

		{
			title: "Influencer Name",
			dataIndex: "influencerName",
			key: "influencerName",
		},

		{
			title: "Brand Name",
			dataIndex: "brandName",
			key: "brandName",
		},

		{
			title: "Platform",
			dataIndex: "platform",
			key: "platform",
		},

		{
			title: "EsCrowStatus",
			dataIndex: "ecrowChequeStatus",
			key: "ecrowChequeStatus",
			filters: [
				{
					text: "Not-Initiated",
					value: "Not-Initiated",
				},
				{
					text: "Acquired",
					value: "Acquired",
				},
				{
					text: "Approved",
					value: "Approved",
				},
				{
					text: "Pending-Release",
					value: "Pending-Release",
				},
				{
					text: "Released",
					value: "Released",
				},
				{
					text: "Pending-Cancel",
					value: "Pending-Cancel",
				},
				{
					text: "Cancel",
					value: "Cancel",
				},

				{
					text: "All",
					value: "",
				},
			],
			filterSearch: true,
			defaultFilteredValue:
				pageParams.ecrowChequeStatus.length === 0
					? ["Completed"]
					: pageParams.ecrowChequeStatus,
		},

		{
			title: "status",
			dataIndex: "status",
			key: "status",
			filters: [
				{
					text: "Finalized",
					value: "Finalized",
				},
				{
					text: "Completed",
					value: "Completed",
				},
				{
					text: "Cancel",
					value: "Cancel",
				},

				{
					text: "All",
					value: "",
				},
			],
			filterSearch: true,
			defaultFilteredValue:
				pageParams.status.length === 0 ? ["Completed"] : pageParams.status,
		},

		{
			title: "createdDateTime",
			dataIndex: "createdDateTime",
			key: "createdDateTime",
		},

		{
			title: "Action",
			dataIndex: "actionButtons",
			key: "actionButtons",
		},
	];

	return (
		<>
			{isCountLoading || isLoading ? (
				<Skeleton paragraph={{ rows: 10 }} />
			) : (
				<>
					<Table
						size={"middle"}
						scroll={{ y: "70vh" }}
						dataSource={
							data?.data?.map((ele: any) => {
								return {
									key: ele._id,
									...ele,
									error: (
										<ReactJson
											src={ele?.logInfo?.error}
											collapsed={true}
											collapseStringsAfterLength={1}
										></ReactJson>
									),
									socialMediaPlatformType:
										ele?.logInfo?.socialMediaPlatformType,
									call: ele?.logInfo?.call,

									createdDateTime: format(
										new Date(ele.createdDateTime),
										"hh:mm a, do LLL yy",
									),
									influencerName: (
										<div
											onClick={() => {
												setInfluencerDrawerParams((prev) => {
													return {
														...prev,
														_id: ele?.influencerId,
														isOpen: true,
													};
												});
											}}
										>
											{" "}
											{ele?.influencer?.name + " " + ele?.influencer?.lastname}
										</div>
									),
									brandName: (
										<div
											onClick={() => {
												setBrandDrawerParams((prev) => {
													return {
														...prev,
														_id: ele?.brandId,
														isOpen: true,
													};
												});
											}}
										>
											{" "}
											{ele?.brand?.businessDetails.name}
										</div>
									),
									camapaignName: (
										<div
											onClick={() => {
												setcampaignDrawerParams((prev) => {
													return {
														...prev,
														_id: ele?.campaignId,
														isOpen: true,
													};
												});
											}}
										>
											{" "}
											{ele?.campaign?.campaignName}
										</div>
									),

									actionButtons:
										ele?.ecrowChequeStatus == "Acquired" ? (
											<Button
												loading={releasePayoutTnxMutation.isLoading}
												onClick={() => {
													console.log(ele?._id);
													releasePayoutTnxMutation.mutate({
														finalizedOfferId: ele?._id,
														brandId: ele?.brandId,
													});
												}}
												key={ele?._id}
											>
												Release
											</Button>
										) : (
											<div></div>
										),
								};
							}) || []
						}
						columns={columns}
						pagination={{
							pageSize: 10,
							total: totalCount?.data.count,
							pageSizeOptions: [10],
							current: pageParams.page,
						}}
						onChange={onTableParamsChange}
						// onRow={(record, rowIdx) => {
						// 	return {
						// 		onClick: (event: any) => {
						// 			setDrawerParams((prev) => {
						// 				return {
						// 					...prev,
						// 					isOpen: true,
						// 					_id: record?._id,
						// 				};
						// 			});
						// 		},
						// 	};
						// }}
					/>

					<InfluencerKycDrawer
						openDrawer={influencerDrawerParams.isOpen}
						id={influencerDrawerParams._id}
						setOpenDrawer={() => {
							setInfluencerDrawerParams((prev) => {
								return {
									...prev,
									isOpen: false,
								};
							});
						}}
					></InfluencerKycDrawer>

					<CampaignDrawer
						id={campaignDrawerParams._id}
						openDrawer={campaignDrawerParams.isOpen}
						setOpenDrawer={() => {
							setcampaignDrawerParams((prev) => {
								return {
									...prev,
									isOpen: false,
								};
							});
						}}
					></CampaignDrawer>

					<BrandKycDrawer
						id={brandDrawerParams._id}
						openDrawer={brandDrawerParams.isOpen}
						setOpenDrawer={() => {
							setBrandDrawerParams((prev) => {
								return {
									...prev,
									isOpen: false,
								};
							});
						}}
						status="None"
						getAllBrandsKey={[undefined]}
					></BrandKycDrawer>
				</>
			)}
		</>
	);
};

export default Payouts;
