import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Radio,
  Button,
  message,
  Space,
  Row,
  Col,
  DatePicker,
  DatePickerProps,
  Divider,
} from "antd";
import { useMutation, useQueryClient, useQuery } from "react-query";
import moment from "moment";

import AddPlanModal from "../AddPlanModal";

import {
  createCustomSubscription,
  getSubstate,
  getPlans,
} from "../../../../handler/plan";
import { tierDetail } from "../../../../typings";

interface Props {
  visible: boolean;
  handleClose: () => void;
  brandId: string;
  initialSelectedPlanId: string;
}

const ChangeSubscriptionModal: React.FC<Props> = ({
  visible,
  handleClose,
  brandId,
  initialSelectedPlanId,
}) => {
  const [dateString, setDateString] = useState<string>(
    new Date().toISOString()
  );
  const [selectedPlanId, setSelectedPlanId] = useState<string>("");
  const [viewPlanModalProps, setViewPlanProps] = useState<{
    visible: boolean;
    selectedPlan: tierDetail | undefined;
  }>({ visible: false, selectedPlan: undefined });

  const firstRender = useRef(true);
  const [subkey] = getSubstate({ brandId });
  const queryClient = useQueryClient();

  const [getPlansKey, getPlansFn] = getPlans();

  const createSubMutation = useMutation(createCustomSubscription, {
    onSuccess: () => {
      message.success("Subscription Changed");
      queryClient.invalidateQueries(subkey);
      handleClose();
      setSelectedPlanId(selectedPlanId);
    },
    onError: (err) => {
      message.error("Something went wrong");
      console.log(err);
    },
  });

  const { data: plansData } = useQuery(getPlansKey, getPlansFn, {
    enabled: visible,
  });

  useEffect(() => {
    if (visible && firstRender.current && initialSelectedPlanId) {
      setSelectedPlanId(initialSelectedPlanId);
      firstRender.current = false;
    }

    if (!visible) {
      setSelectedPlanId("");
      firstRender.current = true;
    }
  }, [visible, initialSelectedPlanId]);

  const onDateChange: DatePickerProps["onChange"] = (
    date: moment.Moment | null,
    _: string
  ) => {
    setDateString(date?.toDate().toISOString() || new Date().toISOString());
  };

  return (
    <>
      <Modal
        open={visible}
        okText={`Upgrade `}
        bodyStyle={{ height: "70vh", overflowY: "scroll" }}
        onOk={() => {
          createSubMutation.mutate({
            subscription: {
              brandId,
              start: dateString,
              tierPlanId: selectedPlanId,
            },
          });
        }}
        okButtonProps={{
          loading: createSubMutation.isLoading,
          disabled:
            !Boolean(selectedPlanId.trim()) || !Boolean(dateString.trim()),
        }}
        onCancel={handleClose}
        title={"Upgrade Subscription"}>
        <p>Plans:</p>
        <Radio.Group
          onChange={(e) => setSelectedPlanId(e.target.value)}
          value={selectedPlanId}>
          <Space direction="vertical">
            {plansData?.data?.tierPlans?.map((planItem) => (
              <Radio value={planItem?._id || ""}>
                <div style={{ display: "flex", gap: 10 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setViewPlanProps({
                        selectedPlan: planItem,
                        visible: true,
                      });
                    }}>
                    View Plan
                  </Button>
                  <p>
                    {planItem?.planName} - ({planItem.cycle}) Cycle
                  </p>
                </div>
              </Radio>
            ))}
          </Space>
        </Radio.Group>
        <Divider>Or</Divider>
        <Row justify={"center"} style={{ marginBlock: 20 }}>
          <Button
            type="primary"
            onClick={() =>
              setViewPlanProps({ visible: true, selectedPlan: undefined })
            }>
            Add Custom Plan
          </Button>
        </Row>

        <div style={{ marginBlock: 20 }}>
          <p> Start Date: </p>
          <DatePicker onChange={onDateChange} value={moment(dateString)} />
        </div>
      </Modal>
      <AddPlanModal
        handleClose={() =>
          setViewPlanProps({ visible: false, selectedPlan: undefined })
        }
        isView={Boolean(viewPlanModalProps?.selectedPlan)}
        visible={viewPlanModalProps.visible}
        selectedPlan={viewPlanModalProps.selectedPlan}
      />
    </>
  );
};

export default ChangeSubscriptionModal;
