import React, { useState } from "react";
import { Space, Button } from "antd";

import styles from "./styles.module.css";

import InfluencerKycDrawer from "../InfluencerKycDrawer";

import { ReportCampaignInfluencer } from "../../../typings";
import { socialMediaUrlGen } from "../../../Utils";

interface Props {
  influencerData: ReportCampaignInfluencer;
}

const InfluencerTile: React.FC<Props> = ({ influencerData }) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div className={styles.row}>
      <Space size={20}>
        <img
          src={influencerData?.socialMediaKit?.profilePicUrl}
          alt="profile"
          className={styles.profile}
        />
        <p className={styles.totalPrice}>
          {influencerData?.socialMediaKit?.influencerName}
          <br />
          <a
            href={socialMediaUrlGen({
              userNameOrChannelId:
                influencerData?.socialMediaKit?.socialMediaName,
              platform: influencerData?.platform as "instagram" | "youtube",
            })}>
            {influencerData?.socialMediaKit?.socialMediaName}
          </a>
        </p>
      </Space>
      <p className={styles.totalPrice}>{influencerData?.noOfMedias}</p>
      <p className={styles.totalPrice}>{influencerData?.brandPrice}</p>
      <Button type="primary" onClick={() => setVisible(true)}>
        Open Profile
      </Button>

      <InfluencerKycDrawer
        openDrawer={visible}
        setOpenDrawer={setVisible}
        getAllInfluencersCountKey={[""]}
        getAllInfluencersKey={[""]}
        id={influencerData?.influencerId}
      />
    </div>
  );
};

export default InfluencerTile;
