import { sendRequest } from "../helper";

type getSocialMediaConnectLogsCountResponse = {
	status: string;
	message: string;
	data: { count: number };
};

export const getSocialMediaConnectLogsCount = ({
	phoneNumber,
	influencerId,
	call,
	status,
	socialMediaPlatformType,
}: {
	phoneNumber: string;
	influencerId: string;
	call: Array<string>;
	status: Array<string>;
	socialMediaPlatformType: Array<string>;
}) => {
	const queryKey = [
		"social-media-connect-log-count",
		phoneNumber,
		influencerId,
		call.toString(),
		status.toString(),
		socialMediaPlatformType.toString(),
	];
	const queryFunction = () =>
		sendRequest<getSocialMediaConnectLogsCountResponse>(
			"get",
			`/log/count?logsType=Social-Media-Connect-Error&phoneNumber=${encodeURIComponent(
				phoneNumber,
			)}&influencerId=${influencerId}&call=${call.toString()}&status=${status.toString()}&socialMediaPlatformType=${socialMediaPlatformType.toString()}`,
			false,
		).then((res) => {
			return res.data;
		});
	return [queryKey, queryFunction] as const;
};
