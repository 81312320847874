import React from "react";
import { Modal, Form, Input, Button, Select, Checkbox } from "antd";
import { AccessLevels } from "../../../const/AccessLevel";

interface AddUserModalProps {
  visible: boolean;
  handleClose: () => void;
  handleSubmit: (values: any) => void;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  visible,
  handleClose,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const accessLevels = AccessLevels;

  const mapNotificationPreferences = (values: any) => {
    return {
      isSmsEnabled: values.includes("isSmsEnabled"),
      isWhatsappEnabled: values.includes("isWhatsappEnabled"),
      isEmailEnabled: values.includes("isEmailEnabled"),
      isPushEnabled: values.includes("isPushEnabled"),
    };
  };

  return (
    <Modal
      visible={visible}
      title="Add User"
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                const notificationPreferences = mapNotificationPreferences(
                  values.notificationPreferences
                );
                handleSubmit({ ...values, notificationPreferences });
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please input the email!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: "Please input the password!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="accessLevel"
          label="Access Level"
          rules={[
            {
              required: true,
              message: "Please select at least one access level!",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Please select access levels"
          >
            {accessLevels.map((level) => (
              <Select.Option key={level} value={level}>
                {level}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="notificationPreferences"
          label="Notification Preferences"
          rules={[
            {
              validator: async (_, value) => {
                if (!value || value.length === 0) {
                  return Promise.reject(
                    new Error(
                      "At least one notification preference is required"
                    )
                  );
                }
              },
            },
          ]}
        >
          <Checkbox.Group>
            <Checkbox value="isSmsEnabled">SMS</Checkbox>
            <Checkbox value="isWhatsappEnabled">WhatsApp</Checkbox>
            <Checkbox value="isEmailEnabled">Email</Checkbox>
            <Checkbox value="isPushEnabled">Push</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
