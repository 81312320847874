import { sendRequest } from "../helper";

interface approveCampaignProps {
  campaignId?: string;
  campaignBannerUrl: string;
  paymentTerms: string;
}

export const approveCampaign = ({
  campaignId,
  campaignBannerUrl,
  paymentTerms,
}: approveCampaignProps) => {
  return sendRequest("patch", `/brand/campaign/accept/${campaignId}`, false, {
    campaignBannerUrl,
    paymentTerms,
  }).then((res) => res.data);
};
