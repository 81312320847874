import { sendRequest } from "../helper";
import { tierDetail } from "../../typings";

type Response = {
  status: string;
  message: string;
  data: {
    tierPlans: tierDetail[];
    countTierPlans: number;
  };
};

export const getPlans = () => {
  const queryKey = ["plans"];
  const queryFunction = () =>
    sendRequest<Response>(
      "get",
      `/brand/saas-subscription/tier-plans?pageNo=${1}&limit=${500}`,
      false
    ).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
