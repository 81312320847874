import { sendRequest } from "../helper";

interface inviteProps {
  phoneNumber: String;
  influencername: String;
  campaignname: String;
}

export const inviteInfluencer = ({
  phoneNumber,
  influencername,
  campaignname,
}: inviteProps) => {
  return sendRequest("post", `/influencer/me/invite`, false, {
    phoneNumber,
    influencername,
    campaignname,
  }).then((res) => res.data);
};
