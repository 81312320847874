import { sendRequest } from "../helper";

interface postNotesProps {
  influencerId: string;
  notes: string;
}

export const postNotes = ({ influencerId, notes }: postNotesProps) => {
  return sendRequest("post", `/influencer/relation/note`, false, {
    influencerId,
    notes,
  }).then((res) => res.data);
};
