import { sendRequest } from "../helper";

type getTotalInfluencerPaymentCountResult = {
  status: string;
  message: string;
  data: number;
};

export const getTotalInfluencerPaymentCount = (influencerId?: string) => {
  const queryKey = ["influencers", "payment", "count", influencerId];
  const queryFunction = () =>
    sendRequest<getTotalInfluencerPaymentCountResult>(
      "get",
      `/influencer/offer/in-payment-count?influencerId=${influencerId}`,
      false
    ).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
