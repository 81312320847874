import { sendRequest } from "../helper";

interface createWalletProps {
  brandId: string;
}

export const createBrandWallet = ({ brandId }: createWalletProps) => {
  return sendRequest("post", `/brand/wallet?brandId=${brandId}`, false).then(
    (res) => res.data
  );
};
