import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ColumnsType } from "antd/es/table";
import { Space, Tag, Button, Table, Row, Pagination } from "antd";

import { CUserType } from "../../../handler/Brand/org";

import {
  getCloutflowUsers,
  getCloutflowUsersCount,
} from "../../../handler/Brand/org";

import AddOrganizationModal from "./AddOrganizationModal";
import RemoveOrganizationModal from "./RemoveOrganizationModal";

const CloutflowUsers = () => {
  const [searchProps, setsearchProps] = useState<{
    limit: number;
    pageNo: number;
  }>({ limit: 5, pageNo: 1 });

  const queryClient = useQueryClient();

  const [countKey, countFn] = getCloutflowUsersCount();
  const [usersKey, usersFn] = getCloutflowUsers({ ...searchProps });

  const { data: usersData, isLoading: userDataLoading } = useQuery(
    usersKey,
    usersFn
  );

  const { data: countData, isLoading: countDataLoading } = useQuery(
    countKey,
    countFn
  );

  const [addOrgProps, setAddOrgProps] = useState<{
    visible: boolean;
    userId: string;
  }>({ visible: false, userId: "" });

  const [removeOrgProps, setRemoveOrgProps] = useState<{
    visible: boolean;
    organizations: CUserType["organizations"];
    userId: string;
  }>({ visible: false, organizations: [], userId: "" });

  const columns: ColumnsType<CUserType> = [
    {
      title: "Profile",
      dataIndex: "dp",
      key: "profile",
      render: (_, { cloutflowUser }) => (
        <Space size={10} align="center">
          {/* <div className={styles.profilePictureContainer}> */}
          <img
            src={cloutflowUser?.businessDetails?.logoUrl}
            alt="profile"
            style={{ height: 30, width: 30, borderRadius: 999 }}
          />

          <div>
            <p>{cloutflowUser?.name}</p>
            <p>{cloutflowUser?.emailId}</p>
          </div>
        </Space>
      ),
    },
    {
      title: "Organizations",
      key: "org",
      render: (_, { organizations }) => (
        <Space wrap>
          {organizations?.map((item) => (
            <Tag>{item?.ownerDetails?.businessDetails?.name}</Tag>
          ))}
        </Space>
      ),
    },

    {
      title: "Actions",
      render: (_, { organizations, cloutflowUser }) => (
        <div style={{ display: "grid", gap: 8 }}>
          <Button
            type="primary"
            style={{ width: 300 }}
            onClick={() => {
              setAddOrgProps({ userId: cloutflowUser?._id, visible: true });
            }}>
            Add Organization
          </Button>

          <Button
            danger
            style={{ width: 300 }}
            onClick={() => {
              setRemoveOrgProps({
                userId: cloutflowUser?._id,
                visible: true,
                organizations,
              });
            }}>
            Remove Organization
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        loading={countDataLoading || userDataLoading}
        columns={columns}
        dataSource={usersData?.data?.cloutflowUsers}
        pagination={false}
        scroll={{ y: 540 }}
      />

      {countData?.data.count !== 0 && (
        <Row justify="center">
          <Pagination
            total={countData?.data.count}
            style={{ margin: "46px auto" }}
            onChange={(page, _) =>
              setsearchProps((prev) => ({ ...prev, pageNo: page }))
            }
            pageSize={searchProps?.limit}
            showSizeChanger={false}
            current={searchProps?.pageNo}
          />
        </Row>
      )}
      <AddOrganizationModal
        handleClose={() => {
          setAddOrgProps({ userId: "", visible: false });
        }}
        handleSubmit={() => {
          setAddOrgProps({ userId: "", visible: false });
          queryClient.invalidateQueries(["cloutflow-users"]);
        }}
        userId={addOrgProps?.userId}
        visible={addOrgProps?.visible}
      />
      <RemoveOrganizationModal
        handleClose={() => {
          setRemoveOrgProps({ userId: "", visible: false, organizations: [] });
        }}
        handleSubmit={() => {
          setRemoveOrgProps({ userId: "", visible: false, organizations: [] });
          queryClient.invalidateQueries(["cloutflow-users"]);
        }}
        userId={removeOrgProps?.userId}
        visible={removeOrgProps?.visible}
        organizations={removeOrgProps?.organizations}
      />
    </div>
  );
};

export default CloutflowUsers;
