import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { ColumnsType } from "antd/es/table";
import { Space, Tag, Button, Table, Row, Pagination } from "antd";
import EditUserModal from "./EditUserModal";
import DeleteUserModal from "./DeleteUserModal";
import AddUserModal from "./AddUserModal";
import { getAllUsers } from "../../../handler/Admin/getAllAdmins";
import { addUser } from "../../../handler/Admin/addAdmin";
import { updateUser } from "../../../handler/Admin/updateAdmin";
import { deleteUser } from "../../../handler/Admin/deleteAdmin";

const AdminUsers: React.FC = () => {
  const [searchProps, setSearchProps] = useState<{
    limit: number;
    page: number;
  }>({ limit: 10, page: 1 });

  const queryClient = useQueryClient();

  const [usersKey, usersFn] = getAllUsers({ ...searchProps });

  const { data: usersData, isLoading: userDataLoading } = useQuery(
    usersKey,
    usersFn
  );

  const countData = usersData?.totalUsers;
  const countDataLoading = userDataLoading;

  const [editUserProps, setEditUserProps] = useState<{
    visible: boolean;
    user: any; // Replace with specific user type if available
  }>({ visible: false, user: null });

  const [deleteUserProps, setDeleteUserProps] = useState<{
    visible: boolean;
    userId: string;
  }>({ visible: false, userId: "" });

  const [addUserProps, setAddUserProps] = useState<{
    visible: boolean;
  }>({ visible: false });

  const columns: ColumnsType<any> = [
    {
      title: "Name & Email",
      key: "nameEmail",
      render: (_, { name, emailId }) => (
        <Space direction="vertical">
          <span>{name || "N/A"}</span>
          <span>{emailId}</span>
        </Space>
      ),
    },
    {
      title: "Permissions",
      key: "permissions",
      render: (_, { accessLevel }) => (
        <Space wrap>
          {accessLevel?.map((permission: string) => (
            <Tag key={permission}>{permission}</Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Actions",
      render: (_, user) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setEditUserProps({ visible: true, user });
            }}
          >
            Edit
          </Button>
          <Button
            danger
            onClick={() => {
              setDeleteUserProps({ visible: true, userId: user._id });
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const handleEditUserSubmit = async (values: any) => {
    await updateUser({
      userId: editUserProps.user._id,
      ...values,
    });
    setEditUserProps({ visible: false, user: null });
    queryClient.invalidateQueries(usersKey);
  };

  const handleDeleteUserSubmit = async (userId: string) => {
    await deleteUser({ userId });
    setDeleteUserProps({ visible: false, userId: "" });
    queryClient.invalidateQueries(usersKey);
  };

  const handleAddUserSubmit = async (values: any) => {
    await addUser(values);
    setAddUserProps({ visible: false });
    queryClient.invalidateQueries(usersKey);
  };

  return (
    <div>
      <Button
        type="primary"
        style={{ marginBottom: 16 }}
        onClick={() => setAddUserProps({ visible: true })}
      >
        Add User
      </Button>
      <Table
        loading={countDataLoading || userDataLoading}
        columns={columns}
        dataSource={usersData?.users}
        pagination={false}
        scroll={{ y: 540 }}
      />

      {countData !== 0 && (
        <Row justify="center">
          <Pagination
            total={countData}
            style={{ margin: "46px auto" }}
            onChange={(page, _) =>
              setSearchProps((prev) => ({ ...prev, page }))
            }
            pageSize={searchProps?.limit}
            showSizeChanger={false}
            current={searchProps?.page}
          />
        </Row>
      )}
      <EditUserModal
        visible={editUserProps.visible}
        user={editUserProps.user}
        handleClose={() => setEditUserProps({ visible: false, user: null })}
        handleSubmit={handleEditUserSubmit}
      />
      <DeleteUserModal
        visible={deleteUserProps.visible}
        userId={deleteUserProps.userId}
        handleClose={() => setDeleteUserProps({ visible: false, userId: "" })}
        handleSubmit={handleDeleteUserSubmit}
      />
      <AddUserModal
        visible={addUserProps.visible}
        handleClose={() => setAddUserProps({ visible: false })}
        handleSubmit={handleAddUserSubmit}
      />
    </div>
  );
};

export default AdminUsers;
