import { Brand } from "../../../typings";
import { sendRequest } from "../../helper";

type brandResult = {
  status: string;
  message: string;
  data: { count: number };
};

export const getCloutflowUsersCount = () => {
  const queryKey = ["cloutflow-users", "count"];

  const queryFunction = () =>
    sendRequest<brandResult>(
      "get",
      `/brand/organization/users-count`,
      false
    ).then((res) => res.data);
  return [queryKey, queryFunction] as const;
};
