import { Influencer } from "../../typings";
import { sendRequest } from "../helper";

export type influencerResult = {
	status: "SUCCESS";
	message: "Fetching All Influencers";
	data: Influencer[];
};

type getAllInfluencersProps = {
	page?: number;
	searchQuery: {
		text: string;
		received: string;
		kycStatus: string;
		isPriceRequested: boolean;
		minFollowers?: number;
		maxFollowers?: number;
		isArchived?: boolean;
		isPushEnabled: string;
		isTokenRevoked: string;
	};
};

export const getAllInfluencers = ({
	page,
	searchQuery,
}: getAllInfluencersProps) => {
	const queryKey = ["influencers", page, searchQuery];

	var tmp = `/influencer/?limit=7&pageNo=${page}&received=${
		searchQuery.received
	}&kycStatus=${searchQuery.kycStatus}&text=${
		searchQuery.text
	}&isPriceRequested=${searchQuery.isPriceRequested}${
		searchQuery?.minFollowers ? `&minFollowers=${searchQuery.minFollowers}` : ""
	}${searchQuery?.isArchived ? `&adminStatus=Archive` : ""}
  ${
		searchQuery?.maxFollowers ? `&maxFollowers=${searchQuery.maxFollowers}` : ""
	}${
		searchQuery?.isPushEnabled
			? `&isPushEnabled=${searchQuery.isPushEnabled}`
			: ""
	}${
		searchQuery?.isTokenRevoked
			? `&isTokenRevoked=${searchQuery.isTokenRevoked}`
			: ""
	}`.replace(/\s+/g, "");

	const queryFunction = () =>
		sendRequest<influencerResult>("get", tmp, false).then((res) => res.data);
	return [queryKey, queryFunction] as const;
};
