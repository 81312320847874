import { sendRequest } from "../helper";

interface UpdateUserProps {
  userId: string;
  name?: string;
  accessLevel?: string[];
}

export const updateUser = ({ userId, name, accessLevel }: UpdateUserProps) => {
  return sendRequest("put", `/admin/admin/${userId}`, false, {
    name,
    accessLevel,
  }).then((res) => res.data);
};
