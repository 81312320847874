import { useState, useEffect } from "react";
import { Button, InputNumber, MenuProps, message } from "antd";
import {
  Pagination,
  Row,
  Space,
  Empty,
  Input,
  Select,
  Skeleton,
  Switch,
  Menu,
} from "antd";

import { useMutation, useQuery } from "react-query";
import styles from "./Influencer.module.css";
import nf from "@tuplo/numberfmt";

import InfluencerTile from "./InfluencerTile/InfluencerTile";
import PushNotificationInfluencerModal from "./PushModal/pushNotificationInfluencerModal";
import {
  getAllInfluencers,
  getTotalInfluencerCount,
} from "../../../handler/Influencer";
import { BooleanLiteral } from "typescript";
import { sendInfluencerPushNotification } from "../../../handler/Influencer/Notification/sendInfluencerPushNotification";
import { AxiosError } from "axios";
import CreateInfluencer from "./CreateInfluencer";
import { getUserRole } from "../../../handler/User/getUserRole";
import InviteInfluencer from "./InviteInfluencer";

const { Search } = Input;
const { Option } = Select;

const Influencer = () => {
  //Use States
  const [page, setPage] = useState(1);

  const [queryKey, queryFunction] = getUserRole();
  const {
    data,
    isLoading: userLoading,
    error,
  } = useQuery(queryKey, queryFunction);

  const accessLevel = data?.admin.accessLevel;
  const [searchQuery, setSearchQuery] = useState<{
    text: string;
    received: string;
    kycStatus: string;
    isPriceRequested: boolean;
    minFollowers?: number;
    maxFollowers?: number;
    isArchived: boolean;
    isPushEnabled: string;
    isTokenRevoked: string;
  }>({
    text: "",
    received: "",
    kycStatus: "",
    isPriceRequested: false,
    isArchived: false,
    isPushEnabled: "",
    isTokenRevoked: "",
  });
  const [inputFollowers, setInputFollowers] = useState<{
    min?: number;
    max?: number;
  }>({});
  //Get All influencers and count
  const [getTotalCountKey, getTotalCountFn] =
    getTotalInfluencerCount(searchQuery);
  const { data: totalCount } = useQuery(getTotalCountKey, getTotalCountFn);
  const [getAllInfluencersKey, getAllInfluencersFn] = getAllInfluencers({
    page,
    searchQuery: searchQuery,
  });
  const { data: influencers, isLoading } = useQuery(
    getAllInfluencersKey,
    getAllInfluencersFn
  );

  const [createInfluencerModalVisible, setCreateInfluencerModalVisible] =
    useState<boolean>(false);

  const [inviteInfluencerModalVisible, setInviteInfluencerModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const onClick: MenuProps["onClick"] = (e) => {
    let inputRange: { min?: number; max?: number } = {};
    switch (e.key) {
      case "All":
        inputRange = { min: undefined, max: undefined };
        break;
      case "Nano":
        inputRange = { min: 1000, max: 10000 };
        break;
      case "Micro":
        inputRange = { min: 10001, max: 100000 };
        break;
      case "Macro":
        inputRange = { min: 100001, max: 1000000 };
        break;
      case "Celeb":
        inputRange = { min: 1000001, max: undefined };
        break;
      case "Apply":
        inputRange = { min: inputFollowers.min, max: inputFollowers.max };
        break;
      default:
        break;
    }
    setSearchQuery({
      ...searchQuery,
      minFollowers: inputRange.min,
      maxFollowers: inputRange.max,
    });
  };

  const getFollowersName = () => {
    if (searchQuery.minFollowers && searchQuery.maxFollowers) {
      return `${nf(searchQuery.minFollowers, "a", { locale: "en-US" })}-${nf(
        searchQuery.maxFollowers,
        "a",
        { locale: "en-US" }
      )}`;
    }
    if (searchQuery.minFollowers)
      return `${nf(searchQuery.minFollowers, "a", { locale: "en-US" })} + `;
    if (searchQuery.maxFollowers)
      return `0 - ${nf(searchQuery.maxFollowers, "a", { locale: "en-US" })}`;
    return "All";
  };

  const [selectedInfluencer, setSeletectedInfluencer] = useState<string[]>([]);

  const [pushNotificationModal, setpushNotificationModal] = useState<{
    visible: boolean;
    confirmLoading: boolean;
    props: {
      title: string;
      body: string;
    };
  }>({
    visible: false,
    confirmLoading: false,
    props: {
      title: "",
      body: "",
    },
  });

  const sendInfluencerPushNotificationMutation = useMutation(
    sendInfluencerPushNotification,
    {
      onSuccess: (data) => {
        message.success("notification sent");
        setpushNotificationModal({
          confirmLoading: false,
          visible: false,
          props: {
            body: "",
            title: "",
          },
        });

        setSeletectedInfluencer((prev) => []);
      },
      onError: (err: AxiosError) => {
        setpushNotificationModal((prev) => ({
          ...prev,
          confirmLoading: false,
          visible: false,
        }));
      },
    }
  );

  return (
    <>
      <div className={styles.countAndSearch}>
        <div className={styles.count}>
          <div>
            <p>Showing {totalCount?.data.count || 0} results</p>
            <p>{`${(page - 1) * 7 + 1}-${Math.min(
              (page - 1) * 7 + 8,
              totalCount?.data.count || 0
            )} of ${totalCount?.data.count}`}</p>
          </div>
        </div>
        <div className="">
          <Search
            onSearch={(val) => setSearchQuery({ ...searchQuery, text: val })}
            placeholder="Search influencer by name or email"
            enterButton="Search"
            size="large"
          />
        </div>
        <div>
          {accessLevel.includes("User") && (
            <Button
              type="primary"
              size={"large"}
              onClick={() => {
                setpushNotificationModal((prev) => ({
                  ...prev,
                  visible: true,
                }));
              }}
            >
              Send Push Notification{" "}
              {selectedInfluencer?.length == 0
                ? "to All"
                : "to " + selectedInfluencer?.length}
            </Button>
          )}
        </div>
        <div>
          <Button
            type="primary"
            size={"large"}
            onClick={() => {
              setCreateInfluencerModalVisible(true);
            }}
          >
            Create Influencer
          </Button>

          <Button
            type="default"
            size={"large"}
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setInviteInfluencerModalVisible(true);
            }}
          >
            Invite Influencer
          </Button>
        </div>
      </div>
      <div></div>
      <div>
        <div className={styles.table}>
          <div className={styles.filters}>
            <div>
              <span> Is Token Revoked : </span>
              <Select
                onChange={(val) =>
                  setSearchQuery({ ...searchQuery, isTokenRevoked: val })
                }
                style={{ width: "120px" }}
                placeholder="is Push Enabled"
              >
                <Option value="">All</Option>
                <Option value="yes">yes</Option>
                <Option value="no">No</Option>
              </Select>
            </div>

            <div>
              <span> Is Push Enabled : </span>
              <Select
                onChange={(val) =>
                  setSearchQuery({ ...searchQuery, isPushEnabled: val })
                }
                style={{ width: "120px" }}
                placeholder="is Push Enabled"
              >
                <Option value="">All</Option>
                <Option value="yes">yes</Option>
                <Option value="no">No</Option>
              </Select>
            </div>
            <Menu
              style={{ height: 32, borderRight: 0, marginTop: "-10px" }}
              onClick={onClick}
              mode="vertical"
              triggerSubMenuAction="click"
              items={[
                {
                  label: <p>Follower Range : {getFollowersName()}</p>,
                  key: "Follower Range",
                  children: [
                    {
                      label: "All",
                      key: "All",
                    },
                    {
                      label: "Nano",
                      key: "Nano",
                    },
                    { label: "Micro", key: "Micro" },
                    { label: "Macro", key: "Macro" },
                    { label: "Celeb", key: "Celeb" },
                    {
                      label: "Custom",
                      key: "Custom",
                      children: [
                        {
                          key: "hello",
                          label: (
                            <Space
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <InputNumber
                                placeholder="Min"
                                value={inputFollowers?.min}
                                onChange={(val) =>
                                  setInputFollowers({
                                    ...inputFollowers,
                                    min: val || undefined,
                                  })
                                }
                              />{" "}
                              -{" "}
                              <InputNumber
                                placeholder="Max"
                                value={inputFollowers?.max}
                                onChange={(val) =>
                                  setInputFollowers({
                                    ...inputFollowers,
                                    max: val || undefined,
                                  })
                                }
                              />
                            </Space>
                          ),
                        },
                        {
                          key: "Apply",
                          label: (
                            <Row justify="center">
                              <Button type="primary">Apply</Button>
                            </Row>
                          ),
                        },
                      ],
                    },
                  ],
                },
              ]}
            />
            <div>
              <Select
                onChange={(val) =>
                  setSearchQuery({ ...searchQuery, received: val })
                }
                style={{ width: "120px" }}
                placeholder="Filter by received"
              >
                <Option value="">All</Option>
                <Option value="yes">Received</Option>
                <Option value="no">Not Received</Option>
              </Select>
            </div>
            {searchQuery.received !== "no" && (
              <div>
                <Select
                  onChange={(val) =>
                    setSearchQuery({ ...searchQuery, kycStatus: val })
                  }
                  style={{ width: "120px" }}
                  placeholder="Filter by status"
                >
                  <Option value="">All</Option>
                  {searchQuery.received !== "yes" && (
                    <Option value="Not-Initiated">Incomplete</Option>
                  )}
                  <Option value="Under-Review">Pending</Option>
                  <Option value="Needs-Attention">Not Verified</Option>
                  <Option value="Verified">KYC Verifed</Option>
                </Select>
              </div>
            )}
            <div>
              <span> Is Archived : </span>
              <Switch
                checked={searchQuery.isArchived}
                onChange={(value) =>
                  setSearchQuery((searchQuery) => ({
                    ...searchQuery,
                    isArchived: value,
                  }))
                }
                style={{ width: "40px" }}
              />
            </div>
            <div>
              <span> Price Requested : </span>
              <Switch
                checked={searchQuery.isPriceRequested}
                onChange={(value) =>
                  setSearchQuery((searchQuery) => ({
                    ...searchQuery,
                    isPriceRequested: value,
                  }))
                }
                style={{ width: "40px" }}
              />
            </div>
          </div>
          <div className={styles.tableHeader}>
            <p>Time</p>
            <p>Name</p>
            <p>Number</p>
            <p>Platforms/Usernames</p>
            <p>Kyc Received or not</p>
            <p>Status</p>
            <p>Invited By</p>
          </div>
          {isLoading ? (
            <Skeleton paragraph={{ rows: 10 }} />
          ) : (
            <>
              {totalCount?.data.count !== 0 ? (
                <div className={styles.tableBody}>
                  {influencers?.data.map((item, idx) => {
                    return (
                      <InfluencerTile
                        key={item._id}
                        tileData={item}
                        getAllInfluencersKey={getAllInfluencersKey}
                        getAllInfluencersCountKey={getTotalCountKey}
                        setSelectedState={setSeletectedInfluencer}
                        isSelected={selectedInfluencer.includes(item._id)}
                      />
                    );
                  })}
                </div>
              ) : (
                <Row
                  style={{ height: "200px" }}
                  align="middle"
                  justify="center"
                >
                  <Space direction="vertical" align="center">
                    <Empty />
                  </Space>
                </Row>
              )}
            </>
          )}
        </div>
        {totalCount?.data.count !== 0 && (
          <Row justify="center">
            <Pagination
              total={totalCount?.data.count}
              style={{ margin: "46px auto" }}
              onChange={(page, _) => setPage(page)}
              pageSize={7}
              showSizeChanger={false}
              current={page}
            />
          </Row>
        )}
      </div>
      <PushNotificationInfluencerModal
        handleCancel={() => {
          setpushNotificationModal((prev) => ({
            ...prev,
            visible: false,
          }));
        }}
        handleOk={() => {
          setpushNotificationModal((prev) => ({
            ...prev,
            confirmLoading: true,
            visible: true,
          }));
          sendInfluencerPushNotificationMutation.mutate({
            searchQuery,
            body: {
              body: pushNotificationModal.props.body,
              title: pushNotificationModal.props.title,
              influencerIds: selectedInfluencer,
              isSendToAll: selectedInfluencer.length == 0 ? true : false,
            },
          });
        }}
        state={pushNotificationModal}
        setState={setpushNotificationModal}
      ></PushNotificationInfluencerModal>
      <CreateInfluencer
        handleClose={() => setCreateInfluencerModalVisible(false)}
        visible={createInfluencerModalVisible}
      />
      <InviteInfluencer
        handleClose={() => setInviteInfluencerModalVisible(false)}
        visible={inviteInfluencerModalVisible}
      />
    </>
  );
};

export default Influencer;
