import { sendRequest } from "../helper";

type getTotalInfluencerCountResult = {
	status: string;
	message: string;
	data: { count: number };
};

export const getTotalInfluencerCount = (searchQuery: {
	text: string;
	received: string;
	kycStatus: string;
	isPriceRequested: boolean;
	minFollowers?: number;
	maxFollowers?: number;
	isArchived?: boolean;
	isPushEnabled: string;
	isTokenRevoked: string;
}) => {
	const queryKey = ["influencers", "count", searchQuery];

	let tmp = `/influencer/count?&received=${searchQuery.received}&kycStatus=${
		searchQuery.kycStatus
	}&text=${searchQuery.text}&isPriceRequested=${searchQuery.isPriceRequested}${
		searchQuery?.minFollowers ? `&minFollowers=${searchQuery.minFollowers}` : ""
	}${searchQuery?.isArchived ? `&adminStatus=Archive` : ""}
  ${
		searchQuery?.maxFollowers ? `&maxFollowers=${searchQuery.maxFollowers}` : ""
	}${
		searchQuery?.isPushEnabled
			? `&isPushEnabled=${searchQuery.isPushEnabled}`
			: ""
	}${
		searchQuery?.isTokenRevoked
			? `&isTokenRevoked=${searchQuery.isTokenRevoked}`
			: ""
	}`.replace(/\s+/g, "");
	const queryFunction = () =>
		sendRequest<getTotalInfluencerCountResult>("get", tmp, false).then(
			(res) => {
				return res.data;
			},
		);
	return [queryKey, queryFunction] as const;
};
