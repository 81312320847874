import { sendRequest } from "../helper";

export type ListInfluencer = {
  brand: {
    _id: string;
    businessDetails: {
      name: string;
    };
  };
  isRemoved?: boolean;
  socialMediaKit?: {
    _id?: string;
  };
  brandId: string;
  createdDateTime: string;
  emailId?: string;
  fullName: string;
  influencerNotifiedDateTime: string;
  inviteBy: string;
  isBrandNotified: boolean;
  isInviteAccepted: boolean;
  isSendByFlags: {
    email: boolean;
    phoneNumber: boolean;
    _id: string;
  };
  lastUpdatedDateTime: string;
  phoneNumber?: string;
  platform: "instagram" | "youtube";
  searchAbleParams: string;
  socialMediaName: string;
  status: string;
  _id: string;
};

interface getInfluencersResponse {
  status: string;
  message: string;
  data: ListInfluencer[];
}

export const getInfluencers = ({
  pageNumber,
  brandId = "",
}: {
  pageNumber: number;
  brandId?: string;
}) => {
  const queryKey = ["saas-influencers", "lists", "page", pageNumber, brandId];

  const queryFunction = () =>
    sendRequest<getInfluencersResponse>(
      "get",
      `/brand/connect-request?pageNo=${pageNumber}&brandId=${brandId}&limit=5&status=&platform=`,
      false
    ).then((res) => res.data);

  return [queryKey, queryFunction] as const;
};
