import { sendRequest } from "../helper";

type getPayoutsResponse = {
	status: string;
	message: string;
	data: any;
};

export const getTnxPayouts = ({
	page,
	limit,
	status,
	ecrowChequeStatus,
}: {
	page: number;
	limit: number;
	status: Array<string>;
	ecrowChequeStatus: Array<string>;
}) => {
	const queryKey = ["tnx-payouts", page, limit, status, ecrowChequeStatus];
	const queryFunction = () =>
		sendRequest<getPayoutsResponse>(
			"get",
			`/brand/tnx/payouts?page=${page}&limit=${limit}&status=${status}&ecrowChequeStatus=${ecrowChequeStatus}`,
			false,
		).then((res) => {
			return res.data;
		});
	return [queryKey, queryFunction] as const;
};
