import { Modal, message } from "antd";
import axios, { AxiosResponse } from "axios";
import { auth } from "../firebase/firebase.js";
import React from "react";
import ReactJson from "react-json-view";

type Client = <T = any>(
  method: string,
  url: string,
  iasAuthAPI: boolean,
  body?: any,
  options?: any,
  isV2?: boolean
) => Promise<AxiosResponse<T>>;

export const sendRequest: Client = async (
  method: string,
  url: string,
  iasAuthAPI = false,
  body?: any,
  options?: any,
  isV2?: boolean
) => {
  let token = "";
  token = await auth.currentUser!.getIdToken();
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return axios({
    method,
    url: `${process.env.REACT_APP_BACKEND_URL}${isV2 ? "v2" : "v1"}${url}`,
    data: body,
    // withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      ...options,
    },
  }).catch((err) => {
    Modal.error({
      title: "Error Occured",
      width: "1000px",
      content: <ReactJson src={err?.response?.data} collapsed={false} />,
      //@ts-ignore
    });
    // console.log("logs", err?.response);

    var msg = "";
    if (err?.response) {
      msg = err?.response?.data?.msg;
    } else if (err?.request) {
      msg = "Request Error";
    } else {
      msg = "No Defined";
    }

    message.error(msg);

    throw err;
  });
};
