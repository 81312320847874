import React from "react";
import { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Row,
  Col,
  Button,
  Divider,
  Tag,
  Spin,
  Empty,
  message,
} from "antd";
import {
  collection,
  query,
  getDocs,
  onSnapshot,
  orderBy,
  startAfter,
  limit,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import InfiniteScroll from "react-infinite-scroll-component";

import AddModal from "./AddModal";

import { firebaseDb } from "../../../firebase/firebase";

export type RestaurantInfo = {
  bannerUrls: string[];
  collabInfo: string;
  description: string;
  dineoutLink: string;
  formLink: string;
  isActive: boolean;
  location: string;
  title: string;
  zomatoLink: string;
  id: string;
};

const Restaurants = () => {
  const [addModalProps, setAddModalProps] = useState<{
    visible: boolean;
    data: RestaurantInfo | null;
  }>({
    visible: false,
    data: null,
  });

  const [resData, setResData] = useState<RestaurantInfo[]>([]);
  const [docSnapshot, setDocSnapshot] = useState<any>();

  const fetchCreatorData = async () => {
    let arr = [...resData];
    const firebaseQuery = query(
      collection(firebaseDb, "Restaurant"),
      orderBy("createdDateTime", "desc"),
      limit(10)
    );

    const dataListener = onSnapshot(firebaseQuery, (snapshot) => {
      setDocSnapshot(snapshot.docs);
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (arr.findIndex((not) => not.id === change.doc.id) === -1) {
            arr.push({
              ...change.doc.data(),
              id: change.doc.id,
            } as RestaurantInfo);
          }
        }
        if (change.type === "modified") {
          const newArray = arr.map((item) => {
            if (item.id === change.doc.id) {
              return {
                ...change.doc.data(),
                id: change.doc.id,
              } as RestaurantInfo;
            }
            return item;
          });
          arr = newArray;
        }
        if (change.type === "removed") {
          const newArray = arr.filter((item) => item.id !== change.doc.id);
          arr = newArray;
        }
      });

      setResData(arr);
    });
    return dataListener;
  };

  const loadMore = async () => {
    if (docSnapshot[docSnapshot.length - 1] !== undefined) {
      const next = query(
        collection(firebaseDb, "Restaurant"),
        orderBy("createdDateTime", "desc"),
        startAfter(docSnapshot[docSnapshot.length - 1]),
        limit(10)
      );

      const documentSnapshots = await getDocs(next);

      let docData: RestaurantInfo[] = [];

      documentSnapshots.forEach((doc) => {
        docData.push({
          id: doc.id,
          ...doc.data(),
        } as RestaurantInfo);
      });
      setResData((resData) => [...resData, ...docData]);
      setDocSnapshot(documentSnapshots.docs);
    }
  };

  useEffect(() => {
    fetchCreatorData();
  }, []);

  return (
    <div>
      <Row justify={"space-between"}>
        <h2>Restaurant List</h2>
        <Button
          type="primary"
          onClick={() => setAddModalProps({ visible: true, data: null })}>
          Add Restaurant
        </Button>
      </Row>
      <Row
        style={{
          background: " #f5f5f5",
          borderRadius: "10px",
          boxShadow:
            "inset 1px 0 0 #d9d9d9, inset -1px 0 0 #d9d9d9, inset 0 1px 0 #d9d9d9, inset 0 -1px 0 #d9d9d9",
          padding: " 16px 10px 16px 44px",
        }}>
        <Col span={6}>Name</Col>
        <Col span={6}>Location</Col>
        <Col span={6}>Is Offer Active </Col>
        <Col span={6}>Actions</Col>
      </Row>

      {resData?.length > 0 ? (
        <InfiniteScroll
          next={() => {
            loadMore();
          }}
          dataLength={resData.length}
          hasMore={docSnapshot[docSnapshot.length - 1] !== undefined}
          loader={
            <Row justify="center">
              <Spin />
            </Row>
          }
          style={{ height: "50vh", overflowY: "scroll" }}>
          {resData.length === 0 && <Empty description="No Restaurants" />}
          {resData?.map((item) => {
            return (
              <>
                <Row
                  style={{
                    padding: " 16px 10px 16px 44px",
                  }}>
                  <Col span={6}>{item.title}</Col>
                  <Col span={6}>{item.location}</Col>
                  <Col span={6}>
                    {item.isActive ? (
                      <Tag color="success"> Yes</Tag>
                    ) : (
                      <Tag color="error">NO</Tag>
                    )}
                  </Col>

                  <Col span={2}>
                    <Button
                      type="primary"
                      onClick={() =>
                        setAddModalProps({ visible: true, data: item })
                      }>
                      Edit
                    </Button>
                    <Button
                      style={{ marginTop: "10px" }}
                      onClick={async () =>
                        await updateDoc(
                          doc(firebaseDb, "Restaurant", item.id),
                          { isActive: !item.isActive }
                        )
                      }>
                      Change Offer Visibility
                    </Button>
                    <Button
                      style={{ marginTop: "10px" }}
                      type="primary"
                      danger
                      onClick={() =>
                        deleteDoc(doc(firebaseDb, "Restaurant", item.id)).then(
                          () =>
                            message.success(
                              "Restaurant Info Deleted Successfully"
                            )
                        )
                      }>
                      Delete
                    </Button>
                  </Col>
                </Row>
                <Divider style={{ margin: "5px 0" }} />
              </>
            );
          })}
        </InfiniteScroll>
      ) : (
        <Empty />
      )}
      <AddModal
        visible={addModalProps.visible}
        handleClose={() => setAddModalProps({ visible: false, data: null })}
        handleSubmit={() => setAddModalProps({ visible: false, data: null })}
        type={addModalProps?.data ? "Edit" : "Add"}
        initialData={addModalProps.data}
      />
    </div>
  );
};

export default Restaurants;
