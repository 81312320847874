import React, { useState } from "react";
import { Modal, Input, InputNumber, message } from "antd";
import { useMutation } from "react-query";

import { inviteInfluencer } from "../../../handler/Campaign";

interface InviteInfluencerDrawerProps {
  isOpen: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  brandId: string;
  dealId: string;
}

const InviteInfluencerDrawer: React.FC<InviteInfluencerDrawerProps> = ({
  isOpen,
  setOpenDrawer,
  dealId,
  brandId,
}) => {
  const [inviteProps, setInviteProps] = useState<{
    brandPrice: number;
    influencerId: string;
    influencerPrice: number;
  }>({ brandPrice: 0, influencerId: "", influencerPrice: 0 });

  const inviteInfluencerMutation = useMutation(inviteInfluencer, {
    onSuccess: () => {
      message.success("Influencer Invited");
      setOpenDrawer(false);
    },
    onError: () => {
      message.error("some error Occurred while inviting influencer");
    },
  });

  return (
    <Modal
      title="Invite an Influencer"
      centered
      okText="Invite"
      confirmLoading={inviteInfluencerMutation.isLoading}
      open={isOpen}
      onCancel={() => {
        setInviteProps({ brandPrice: 0, influencerId: "", influencerPrice: 0 });
        setOpenDrawer(false);
      }}
      okButtonProps={{
        disabled:
          inviteInfluencerMutation.isLoading ||
          inviteProps.brandPrice <= 0 ||
          inviteProps.influencerPrice <= 0 ||
          inviteProps.influencerId === "",
      }}
      onOk={() =>
        inviteInfluencerMutation.mutate({
          brandId: brandId,
          brandPrice: inviteProps.brandPrice,
          dealId: dealId,
          socialMediaKitId: inviteProps.influencerId,
          influencerPrice: inviteProps.influencerPrice,
        })
      }>
      <h3 style={{ marginBottom: "5px" }}> Brand Offer price</h3>
      <InputNumber
        value={inviteProps.brandPrice}
        onChange={(value) =>
          setInviteProps((inviteProps) => {
            return { ...inviteProps, brandPrice: value || 0 };
          })
        }
      />
      <h3 style={{ marginBottom: "5px", marginTop: "16px" }}>
        {" "}
        Influencer Offer price
      </h3>
      <InputNumber
        value={inviteProps.influencerPrice}
        onChange={(value) =>
          setInviteProps((inviteProps) => {
            return { ...inviteProps, influencerPrice: value || 0 };
          })
        }
      />
      <h3 style={{ marginBottom: "5px", marginTop: "16px" }}>
        SocialMediaKit Id
      </h3>
      <Input
        value={inviteProps.influencerId}
        onChange={(event) => {
          setInviteProps((inviteProps) => {
            return { ...inviteProps, influencerId: event.target.value };
          });
        }}
      />
    </Modal>
  );
};

export default InviteInfluencerDrawer;
