import { Brand } from "../../typings";
import { sendRequest } from "../helper";

type getBrandResult = {
  status: string;
  message: string;
  data: Brand;
};

export const getBrand = (id?: string) => {
  const queryKey = ["brand", id];
  const queryFunction = () =>
    sendRequest<getBrandResult>("get", `/brand/me?brandId=${id}`, false).then(
      (res) => {
        return res.data;
      }
    );
  return [queryKey, queryFunction] as const;
};
