import { createContext, useContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  User,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { auth } from "../firebase/firebase";
import { useQueryClient } from "react-query";

type ContextState = {
  currentUser: User | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
  setLoggedin: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  loggedin: boolean;
  admin: String;
  login: (email: string, password: string) => void;
  logout: () => Promise<void>;
};

const AuthContext = createContext<ContextState | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const queryClient = useQueryClient();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loggedin, setLoggedin] = useState<boolean>(false);
  const [admin, setAdmin] = useState<string>("false");

  const navigate = useNavigate();

  const login = async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password);
  };

  const logout = async () => {
    await signOut(auth);
    setLoggedin(false);
    localStorage.clear();
    queryClient.clear();
    navigate("/login");
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setCurrentUser(currentUser);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const value = {
    admin,
    currentUser,
    setCurrentUser,
    login,
    loading,
    logout,
    loggedin,
    setLoggedin,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used in <AuthProvider />");
  }

  return context;
};
