import { sendRequest } from "../helper";

interface inviteInfluencerProps {
  brandId: string;
  dealId: string;
  socialMediaKitId: string;
  brandPrice: number;
  influencerPrice: number;
}

export const inviteInfluencer = (
  InviteInfluencerProps: inviteInfluencerProps
) => {
  return sendRequest("post", `/brand/offer/invite-by-special-offer`, false, {
    ...InviteInfluencerProps,
  }).then((res) => res.data);
};
