import { sendRequest } from "../helper";

interface sendCampaignRemarksProps {
  campaignId: string;
  remarks: string;
}

export const sendCampaignRemarks = ({
  campaignId,
  remarks,
}: sendCampaignRemarksProps) => {
  return sendRequest("patch", `/brand/campaign/reject/${campaignId}`, false, {
    rejectReason: remarks,
  }).then((res) => res.data);
};
