import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Row,
  Col,
  Space,
  Input,
  Radio,
  Button,
  InputNumber,
  Switch,
  message,
} from "antd";
import { useMutation, useQueryClient } from "react-query";

import { tierDetail } from "../../../../typings";
import { createCustomPlan, getPlans } from "../../../../handler/plan";

interface Props {
  visible: boolean;
  handleClose: () => void;
  isView?: boolean;
  selectedPlan?: tierDetail;
}

const tierInitialValue: tierDetail = {
  planName: "",
  cycle: "month",
  featureLimit: {
    addMedia: {
      maxLimit: 0,
      isUnlimited: false,
    },
    community: {
      maxLimit: 0,
      isUnlimited: false,
    },
    canInviteTeamMembers: false,
    canInviteAgencyMembers: false,
    canDiscoverCreator: false,
    canInviteCreator: false,
    reportCustomize: false,
    canExportDataExcel: false,
  },
  description: [""],
  price: { usd: 0, inr: 0 },
  stripePriceId: undefined,
};

const AddPlanModal: React.FC<Props> = ({
  visible,
  handleClose,
  selectedPlan,
  isView = false,
}) => {
  const [planDetail, setPlanDetail] = useState<tierDetail>(tierInitialValue);

  const firstRender = useRef(true);
  const [plansKey] = getPlans();
  const queryClient = useQueryClient();

  const createPlanMutation = useMutation(createCustomPlan, {
    onSuccess: () => {
      message.success("Plan Created");
      queryClient.invalidateQueries(plansKey);
      handleClose();
      setPlanDetail(tierInitialValue);
      //  Invalidate Plan Queries
    },
    onError: (err) => {
      message.error("Something went wrong");
      console.log(err);
    },
  });

  useEffect(() => {
    if (visible && firstRender.current && selectedPlan) {
      setPlanDetail(selectedPlan);
      firstRender.current = false;
    }

    if (!visible) {
      setPlanDetail(tierInitialValue);
      firstRender.current = true;
    }
  }, [visible, selectedPlan]);

  return (
    <Modal
      open={visible}
      okText={`${isView ? "View" : "Add"}  Plan`}
      bodyStyle={{ height: "70vh", overflowY: "scroll" }}
      onOk={() => {
        if (isView) {
          return handleClose();
        }
        createPlanMutation.mutate({ plan: planDetail });
      }}
      okButtonProps={{ loading: createPlanMutation.isLoading }}
      onCancel={handleClose}
      title={`${isView ? "View" : "Create"} Custom Plan`}>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Space>
            Plan Name:{" "}
            <Input
              value={planDetail.planName}
              disabled={isView}
              onChange={(e) => {
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  planName: e.target.value.trim() || "",
                }));
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Cycle:{" "}
            <Radio.Group
              onChange={(e) =>
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  cycle: e.target.value || "",
                }))
              }
              disabled={isView}>
              <Radio value={"month"}>Month</Radio>
              <Radio value={"year"}>Year</Radio>
            </Radio.Group>
          </Space>
        </Col>

        <Col span={24}>
          <Space>
            Description:{" "}
            <Button
              disabled={isView}
              onClick={() =>
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  description: [...planDetail.description, ""],
                }))
              }>
              Add item
            </Button>
          </Space>
          <ol style={{ display: "grid", gap: "4px", margin: "5px 0 5px 50px" }}>
            {planDetail?.description?.map((item, idx) => (
              <li>
                <Col>
                  <Space>
                    <Input
                      value={item}
                      disabled={isView}
                      onChange={(e) => {
                        setPlanDetail((planDetail) => ({
                          ...planDetail,
                          description: [
                            ...planDetail?.description?.map(
                              (descItem, descIdx) => {
                                if (descIdx !== idx) return descItem;
                                return e.target.value;
                              }
                            ),
                          ],
                        }));
                      }}
                    />
                    <Button
                      disabled={isView}
                      onClick={() =>
                        setPlanDetail((planDetail) => ({
                          ...planDetail,
                          description: planDetail?.description?.filter(
                            (_, descIdx) => descIdx !== idx
                          ),
                        }))
                      }
                      danger>
                      Remove
                    </Button>
                  </Space>
                </Col>
              </li>
            ))}
          </ol>
        </Col>
        <Col span={24}>
          <Space>
            Stripe Price ID(optional):{" "}
            <Input
              value={planDetail.stripePriceId}
              disabled={isView}
              onChange={(e) => {
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  stripePriceId: e.target.value.trim() || undefined,
                }));
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Price (USD)
            <InputNumber
              value={planDetail?.price?.usd || 0}
              addonBefore={"$"}
              disabled={isView}
              onChange={(e) => {
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  price: { ...planDetail.price, usd: Number(e) },
                }));
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Price (Indian)
            <InputNumber
              value={planDetail?.price?.inr || 0}
              addonBefore={"₹"}
              disabled={isView}
              onChange={(e) => {
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  price: { ...planDetail.price, inr: Number(e) },
                }));
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Media Limit
            <Col>
              <ol>
                <li>
                  <Space>
                    Limit
                    <InputNumber
                      value={planDetail.featureLimit?.addMedia?.maxLimit}
                      disabled={isView}
                      onChange={(e) => {
                        setPlanDetail((planDetail) => ({
                          ...planDetail,
                          featureLimit: {
                            ...planDetail.featureLimit,
                            addMedia: {
                              ...planDetail.featureLimit.addMedia,
                              maxLimit: Number(e),
                            },
                          },
                        }));
                      }}
                    />
                  </Space>
                </li>
                <li>
                  <Space>
                    Is Unlimited?
                    <Switch
                      checked={planDetail?.featureLimit?.addMedia?.isUnlimited}
                      disabled={isView}
                      onChange={(checked) =>
                        setPlanDetail((planDetail) => ({
                          ...planDetail,
                          featureLimit: {
                            ...planDetail.featureLimit,
                            addMedia: {
                              ...planDetail.featureLimit.addMedia,
                              isUnlimited: checked,
                            },
                          },
                        }))
                      }
                    />
                  </Space>
                </li>
              </ol>
            </Col>
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Community Limit
            <Col>
              <ol>
                <li>
                  <Space>
                    Limit
                    <InputNumber
                      value={planDetail.featureLimit?.community?.maxLimit}
                      disabled={isView}
                      onChange={(e) => {
                        setPlanDetail((planDetail) => ({
                          ...planDetail,
                          featureLimit: {
                            ...planDetail.featureLimit,
                            community: {
                              ...planDetail.featureLimit.community,
                              maxLimit: Number(e),
                            },
                          },
                        }));
                      }}
                    />
                  </Space>
                </li>
                <li>
                  <Space>
                    Is Unlimited?
                    <Switch
                      checked={planDetail?.featureLimit?.community?.isUnlimited}
                      disabled={isView}
                      onChange={(checked) =>
                        setPlanDetail((planDetail) => ({
                          ...planDetail,
                          featureLimit: {
                            ...planDetail.featureLimit,
                            community: {
                              ...planDetail.featureLimit.community,
                              isUnlimited: checked,
                            },
                          },
                        }))
                      }
                    />
                  </Space>
                </li>
              </ol>
            </Col>
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            {" "}
            canInviteAgencyMembers?
            <Switch
              checked={planDetail?.featureLimit?.canInviteAgencyMembers}
              disabled={isView}
              onChange={(checked) =>
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  featureLimit: {
                    ...planDetail.featureLimit,
                    canInviteAgencyMembers: checked,
                  },
                }))
              }
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            {" "}
            CanInviteTeamMembers?
            <Switch
              checked={planDetail?.featureLimit?.canInviteTeamMembers}
              disabled={isView}
              onChange={(checked) =>
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  featureLimit: {
                    ...planDetail.featureLimit,
                    canInviteTeamMembers: checked,
                  },
                }))
              }
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            {" "}
            canDiscoverCreator?
            <Switch
              checked={planDetail?.featureLimit?.canDiscoverCreator}
              disabled={isView}
              onChange={(checked) =>
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  featureLimit: {
                    ...planDetail.featureLimit,
                    canDiscoverCreator: checked,
                  },
                }))
              }
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            {" "}
            canInviteCreator?
            <Switch
              checked={planDetail?.featureLimit?.canInviteCreator}
              disabled={isView}
              onChange={(checked) =>
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  featureLimit: {
                    ...planDetail.featureLimit,
                    canInviteCreator: checked,
                  },
                }))
              }
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            {" "}
            canExportDataExcel?
            <Switch
              checked={planDetail?.featureLimit?.canExportDataExcel}
              disabled={isView}
              onChange={(checked) =>
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  featureLimit: {
                    ...planDetail.featureLimit,
                    canExportDataExcel: checked,
                  },
                }))
              }
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            {" "}
            reportCustomize?
            <Switch
              checked={planDetail?.featureLimit?.reportCustomize}
              disabled={isView}
              onChange={(checked) =>
                setPlanDetail((planDetail) => ({
                  ...planDetail,
                  featureLimit: {
                    ...planDetail.featureLimit,
                    reportCustomize: checked,
                  },
                }))
              }
            />
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddPlanModal;
