import React from "react";
import { format } from "date-fns";
import clsx from "clsx";

import styles from "../styles.module.css";
import { Row, Col, Divider, Space, Button, Tag } from "antd";

import { AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";

import { ListInfluencer } from "../../../../handler/Onboard";

interface InfluencerTileProps {
  influencer: ListInfluencer;
}

const InfluencerTile: React.FC<InfluencerTileProps> = ({ influencer }) => {
  const getInviteTag = (status?: string) => {
    if (influencer?.isRemoved) {
      return <Tag color="red">Withdrawn</Tag>;
    }

    if (influencer?.isInviteAccepted) {
      return <Tag color="green">Invite Accepted</Tag>;
    }

    return <Tag color="orange">Pending</Tag>;
  };

  const getStatus = () => {
    if (influencer?.socialMediaKit?._id)
      return <Tag color="green">Connected</Tag>;
    return <Tag color="orange">Not Connected</Tag>;
  };

  return (
    <>
      <Row style={{ padding: "16px 10px 16px 44px" }}>
        <Col span={3}>
          <Space size={6}>
            {/* <img
              src={influencer.p}
              alt="profile"
              className={styles.profilePic}
            /> */}
            <p className={styles.fullName}>{influencer.fullName}</p>
          </Space>
        </Col>
        <Col span={6}>
          <p>
            {influencer?.emailId}
            <br />
            {influencer.phoneNumber}
          </p>
        </Col>
        <Col span={3} className={styles.column}>
          <Button
            type="link"
            onClick={() => {
              window.open(
                influencer.platform === "instagram"
                  ? `https://www.instagram.com/${influencer?.socialMediaName}`
                  : `https://www.youtube.com/channel/${influencer?.socialMediaName}`,
                "_blank"
              );
            }}
            style={{
              padding: 0,
              alignItems: "center",
              display: "inline-flex",
            }}
            className={styles.content}>
            {" "}
            {influencer.platform === "instagram" ? (
              <AiOutlineInstagram
                style={{ marginRight: "4px" }}
                size={"16px"}
                color="#595959"
              />
            ) : (
              <AiFillYoutube
                style={{ marginRight: "4px" }}
                size={"16px"}
                color="#595959"
              />
            )}
            @{influencer?.socialMediaName}{" "}
          </Button>
        </Col>
        <Col span={3} className={clsx(styles.column, styles.content)}>
          {format(new Date(influencer.createdDateTime), "d LLL, yyyy")}
        </Col>
        <Col span={3} className={clsx(styles.column, styles.content)}>
          {influencer?.brand?.businessDetails?.name || "Brand Name"}
        </Col>
        <Col span={3} className={styles.column}>
          {getInviteTag()}
        </Col>
        <Col span={3} className={styles.column}>
          {getStatus()}
        </Col>
      </Row>
      <Divider style={{ margin: "0px" }} />
    </>
  );
};

export default InfluencerTile;
