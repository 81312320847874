import {
  Button,
  Collapse,
  Drawer,
  Image,
  Input,
  message,
  Row,
  Skeleton,
  Space,
  Switch,
  Upload,
  Typography,
  Col,
  Modal,
  InputNumber,
  Tag,
} from "antd";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import cuid from "cuid";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { KycConst } from "../../../const/kycStatus.const";
import {
  approveInfluencerKyc,
  createInfluencerRazorpay,
  createInfluencerWallet,
  createInfluencerZoho,
  getInfluencer,
  getSocialMediaKits,
  sendInfluencerKycRemarks,
  updateInfluencer,
} from "../../../handler/Influencer";
import { getNotes, postNotes } from "../../../handler/Influencer";
import { EditOutlined, CheckOutlined, PlusOutlined } from "@ant-design/icons";

import { getStatus } from "../../../Utils/getStatus";
import CommercialCard from "./CommercialCard";
import styles from "./InfluencerKycDrawer.module.css";
import InsightCard from "./InsightCard";
import { RcFile, UploadProps } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { format } from "date-fns";
import InfluencerPaymentDrawer from "../InfluencerPaymentDrawer";
import RejectInfluencerModal from "./RejectInfluencerModal";
import AddPlatformModal from "./AddPlatformMdoal";

import { AiFillYoutube, AiFillInstagram, AiOutlineCheck } from "react-icons/ai";

import EditUserDetailsModal from "../EditUserDetailsModal";

import { LinkOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
const { TextArea } = Input;
const uploadButton = (
  <div>
    {<PlusOutlined />}
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

const { Paragraph } = Typography;
interface InfluencerKycDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  getAllInfluencersKey?: (
    | string
    | number
    | {
        text: string;
        received: string;
        kycStatus: string;
        isPriceRequested: boolean;
        minFollowers?: number | undefined;
        maxFollowers?: number | undefined;
      }
    | undefined
  )[];
  getAllInfluencersCountKey?: (
    | string
    | number
    | {
        text: string;
        received: string;
        kycStatus: string;
        isPriceRequested: boolean;
        minFollowers?: number | undefined;
        maxFollowers?: number | undefined;
      }
    | undefined
  )[];
}

const InfluencerKycDrawer: React.FC<InfluencerKycDrawerProps> = ({
  openDrawer,
  setOpenDrawer,
  id,
  getAllInfluencersKey,
  getAllInfluencersCountKey,
}) => {
  const queryClient = useQueryClient();

  // Use States
  const [openPaymentDrawer, setOpenPaymentDrawer] = useState<boolean>(false);
  const [uploadingImage, setUploadingImage] = useState<string>("");
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [editUserDetailsModalVisible, setEdtUserDetailsModalVisible] =
    useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<{
    gst: boolean;
    pan: boolean;
    address: boolean;
  }>({
    gst: false,
    pan: false,
    address: false,
  });
  const [kycGst, setKycGst] = useState<string>("");
  const [kycGstVerificationDocRef, setKycGstVerificationDocRef] =
    useState<string[]>();
  const [kycPan, setKycPan] = useState<string>("");
  const [kycCompanyName, setKycCompanyName] = useState<string>("");
  const [kycPanVerificationDocRef, setKycPanVerificationDocRef] =
    useState<string[]>();

  const [notesKey, notesFn] = getNotes(id);
  const { data: notesData } = useQuery(notesKey, notesFn, {
    enabled: openDrawer,
  });
  const [notes, setNotes] = useState<string>("");
  const [editNotes, setEditNotes] = useState<boolean>(false);

  const [kycIsGstRegistered, setKycIsGstRegistered] = useState<boolean>(false);
  const [kycAddress, setKycAddress] = useState<
    Partial<{
      line: string;
      city: string;
      pincode: string;
      country: string;
      state: string;
    }>
  >({ line: "", city: "", pincode: "", country: "", state: "" });

  const [connectPlatformProps, setConnectPlatformProps] = useState<{
    visible: boolean;
    platform: "instagram" | "youtube";
  }>({
    visible: false,
    platform: "instagram",
  });

  // Get Brand data by id
  const [getInfluencerKey, getInfluencerFn] = getInfluencer(id);
  const { data: influencerData, isLoading } = useQuery(
    getInfluencerKey,
    getInfluencerFn,
    {
      enabled: openDrawer,
    }
  );

  //Get social media kit
  // const [getSocialMediaKitsKey, getSocialMediaKitsFn] = getSocialMediaKits(
  //   influencerData?.data._id
  // );
  // const { data: socialMediaKits } = useQuery(
  //   getSocialMediaKitsKey,
  //   getSocialMediaKitsFn,
  //   {
  //     enabled: influencerData !== undefined,
  //   }
  // );

  // Update Influencer
  const updateInfluencerMutation = useMutation(updateInfluencer, {
    onSuccess: () => {
      message.success("Influencer Updated");
      setIsEdit({ gst: false, pan: false, address: false });
      queryClient.invalidateQueries(getInfluencerKey);
      queryClient.invalidateQueries(getAllInfluencersKey);
      queryClient.invalidateQueries(getAllInfluencersCountKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error(`"Something went wrong", ${err.code} ${err.message}`);
    },
  });

  // Create Zoho Customer
  const createZohoMutation = useMutation(createInfluencerZoho, {
    onSuccess: () => {
      message.success("Zoho Customer Created");
      queryClient.invalidateQueries(getInfluencerKey);
      queryClient.invalidateQueries(getAllInfluencersKey);
      queryClient.invalidateQueries(getAllInfluencersCountKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error(`"Something went wrong", ${err.code} ${err.message}`);
    },
  });
  // Create Wallet
  const createWalletMutation = useMutation(createInfluencerWallet, {
    onSuccess: () => {
      message.success("Wallet Created");
      queryClient.invalidateQueries(getInfluencerKey);
      queryClient.invalidateQueries(getAllInfluencersKey);
      queryClient.invalidateQueries(getAllInfluencersCountKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error(`"Something went wrong", ${err.code} ${err.message}`);
    },
  });
  // Create Razorpay account
  const createRazorpayMutation = useMutation(createInfluencerRazorpay, {
    onSuccess: () => {
      message.success("Razorpay account Created");
      queryClient.invalidateQueries(getInfluencerKey);
      queryClient.invalidateQueries(getAllInfluencersKey);
      queryClient.invalidateQueries(getAllInfluencersCountKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error(`"Something went wrong", ${err.code} ${err.message}`);
    },
  });
  // Approve Kyc
  const approveKycMutation = useMutation(approveInfluencerKyc, {
    onSuccess: () => {
      message.success("KYC Approved");
      queryClient.invalidateQueries(getInfluencerKey);
      queryClient.invalidateQueries(getAllInfluencersKey);
      queryClient.invalidateQueries(getAllInfluencersCountKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error(`"Something went wrong", ${err.code} ${err.message}`);
    },
  });

  const postNotesMutation = useMutation(postNotes, {
    onSuccess: () => {
      message.success("Updated User Notes");
      setEditNotes(false);
    },
    onError: (err: AxiosError) => {
      message.error("Some error Occurred");
    },
  });

  // Use Effects

  useEffect(() => {
    if (influencerData) {
      // console.log("influencerData", influencerData);
      setKycAddress(
        influencerData?.data?.kyc?.kycDetails?.address || {
          line: "",
          city: "",
          pincode: "",
          country: "",
          state: "",
        }
      );
      setKycGst(influencerData?.data?.kyc?.kycDetails?.gst || "");
      setKycGstVerificationDocRef(
        influencerData?.data?.kyc?.kycDetails?.gstVerificationDocRef
      );
      setKycIsGstRegistered(
        influencerData?.data?.kyc?.kycDetails?.isGstRegistered || false
      );
      setKycPan(influencerData?.data?.kyc?.kycDetails?.pan || "");
      setKycPanVerificationDocRef(
        influencerData?.data?.kyc?.kycDetails?.panVerificationDocRef
      );
      setKycCompanyName(
        influencerData?.data?.kyc?.kycDetails?.companyName || ""
      );
    }
  }, [influencerData]);

  useEffect(() => {
    if (notesData) {
      // console.log(notesData);
      setNotes(String(notesData?.data[0]?.note));
    }
  }, [notesData]);

  // Upload Documents

  const getUploadButtonProps = (docName: string) => {
    return {
      name: "file",
      multiple: false,
      onDrop(e) {},
      customRequest: (options) => {
        const { onSuccess, file, onProgress, onError } = options;
        setUploadingImage(docName);
        const storage = getStorage();
        const imageName = (file as RcFile).name;
        const storageRef = ref(storage, `/admin/documents/${docName + cuid()}`);
        const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
        // setLoading(true);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            onProgress &&
              onProgress({
                percent:
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
              });
          },
          (error) => {
            console.log("firebaseError", error.code, error.message);
            setUploadingImage("");
            onError && onError(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            onSuccess && onSuccess(downloadURL);
            if (docName === "gst-cert")
              setKycGstVerificationDocRef([downloadURL]);
            else setKycPanVerificationDocRef([downloadURL]);
            setUploadingImage("");
          }
        );
      },
      accept: "image/png, image/jpeg, application/pdf",
      onRemove(file) {
        if (docName === "gst-cert") setKycGstVerificationDocRef([]);
        else setKycPanVerificationDocRef([]);
      },
    } as UploadProps;
  };

  // Util Functions

  const getButtonText = () => {
    if (influencerData?.data.isZohoVendorCreated === false) {
      return "Create Zoho";
    }
    if (influencerData?.data.isWalletCreated === false) return "Create Wallet";
    if (influencerData?.data.isRazorpayContactCreated === false)
      return "Create Razorpay Contact";
    return "Approve";
  };
  const getButtonClickFn = () => {
    if (influencerData) {
      if (influencerData?.data.isZohoVendorCreated === false) {
        return createZohoMutation.mutate({
          influencerId: influencerData?.data._id,
        });
      }
      if (influencerData?.data.isWalletCreated === false)
        return createWalletMutation.mutate({
          influencerId: influencerData?.data._id,
        });
      if (influencerData?.data.isRazorpayContactCreated === false)
        return createRazorpayMutation.mutate({
          influencerId: influencerData?.data._id,
        });

      return approveKycMutation.mutate({
        influencerId: influencerData?.data._id,
      });
    }
  };

  // console.log(id, "id", openDrawer, notesData, notes);
  return (
    <>
      <Drawer
        width={"55vw"}
        title="KYC Details"
        extra={
          <div className={styles.extra}>
            <p className={styles.status}>
              <span className="">
                {getStatus({ status: influencerData?.data?.kyc?.status }).icon}
              </span>{" "}
              <span
                style={{
                  color: getStatus({
                    status: influencerData?.data?.kyc?.status,
                  }).color,
                }}>
                {
                  getStatus({ status: influencerData?.data?.kyc?.status })
                    .status
                }
              </span>
            </p>
            {getStatus({ status: influencerData?.data?.kyc?.status }).status ===
              "KYC Verified" && (
              <p
                onClick={() => setOpenPaymentDrawer(true)}
                className={styles.viewCampaign}>
                View all campaigns
              </p>
            )}
          </div>
        }
        footer={
          <Row justify="space-between">
            {influencerData?.data?.adminStatus === "Nothing" ? (
              <Button type="primary" onClick={() => setRejectModalOpen(true)}>
                Archive
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  updateInfluencerMutation.mutate({
                    influencerId: influencerData?.data?._id,
                    adminStatus: "Nothing",
                  });
                  queryClient.invalidateQueries(getInfluencerKey);
                  queryClient.invalidateQueries(getAllInfluencersCountKey);
                  queryClient.invalidateQueries(getAllInfluencersKey);
                  setOpenDrawer(false);
                }}>
                UnArchive
              </Button>
            )}

            {/* {influencerData?.data?.kyc?.status !== KycConst.status.VERIFIED && (
              <Button
                loading={
                  createWalletMutation.isLoading ||
                  createZohoMutation.isLoading ||
                  createRazorpayMutation.isLoading ||
                  approveKycMutation.isLoading
                }
                disabled={
                  influencerData?.data?.kyc?.status !==
                  KycConst.status.UNDER_REVIEW
                }
                onClick={() => getButtonClickFn()}
                type="primary">
                {getButtonText()}
              </Button>
            )} */}
          </Row>
        }
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}>
        {openDrawer && (
          <>
            {isLoading ? (
              <Skeleton avatar paragraph={{ rows: 16 }} />
            ) : (
              <div className={styles.container}>
                <Space>
                  <Image
                    width={91}
                    height={91}
                    className={styles.logo}
                    src={influencerData?.data.profilePicUrl}
                    alt="Influencer Pic"
                  />
                  <Col
                    style={{
                      marginLeft: "40px",
                      display: "grid",
                      gap: "10px",
                    }}>
                    <Col>
                      <Button
                        type="primary"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_CREATOR_SIDE_URL}/public/custom/${influencerData?.data?.phoneNumber}`,
                            "_blank"
                          )
                        }>
                        Login as Creator
                      </Button>
                    </Col>

                    {/* <Col>
                      <Button
                        onClick={() =>
                          setConnectPlatformProps({
                            visible: true,
                            platform: "instagram",
                          })
                        }
                        disabled={socialMediaKits?.data?.isIgConnected}>
                        Add Instagram
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          setConnectPlatformProps({
                            visible: true,
                            platform: "youtube",
                          })
                        }
                        disabled={socialMediaKits?.data?.isYtConnected}>
                        Add Youtube
                      </Button>
                    </Col> */}
                    <Col>
                      <Button
                        onClick={() => setEdtUserDetailsModalVisible(true)}>
                        Edit Details
                      </Button>
                    </Col>
                  </Col>
                </Space>
                <div className={styles.personalDetails}>
                  <div className={styles.info}>
                    <p className={styles.label}>Number</p>

                    <p className={styles.value}>
                      <Paragraph copyable>
                        {influencerData?.data.phoneNumber}
                      </Paragraph>
                    </p>
                  </div>

                  <p>Notes</p>
                  <div>
                    <div style={{ position: "relative" }}>
                      <TextArea
                        disabled={!editNotes}
                        autoSize={{ minRows: 4 }}
                        defaultValue={notes}
                        value={notes}
                        style={{
                          border: "0.5px solid #D9D9D9",
                          boxShadow: "0px 0px 8px rgba(10, 6, 18, 0.04)",
                          borderRadius: "8px",
                        }}
                        onChange={(event) => setNotes(event.target.value)}
                      />
                      {!editNotes && (
                        <div
                          onClick={() => setEditNotes(true)}
                          className={styles.editIcon}>
                          <EditOutlined />
                        </div>
                      )}
                    </div>
                    {editNotes && (
                      <Row justify="end" style={{ marginTop: "10px" }}>
                        <Button
                          type="default"
                          style={{ borderRadius: "2px" }}
                          onClick={() => {
                            setNotes(String(notesData?.data[0]?.note) || "");
                            setEditNotes(false);
                          }}>
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          style={{ marginLeft: "8px", borderRadius: "2px" }}
                          onClick={() =>
                            postNotesMutation.mutate({
                              influencerId: id,
                              notes,
                            })
                          }
                          loading={postNotesMutation.isLoading}>
                          Save
                        </Button>
                      </Row>
                    )}
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>Name</p>
                    <p className={styles.value}>
                      {influencerData?.data.name}{" "}
                      {influencerData?.data.lastname}
                    </p>
                  </div>
                  {influencerData?.data?.adminStatus !== "Nothing" && (
                    <div className={styles.info}>
                      <p className={styles.label}>Archive Reason</p>
                      <p className={styles.value}>
                        {influencerData?.data.adminStatusReason}
                      </p>
                    </div>
                  )}
                  <div className={styles.info}>
                    <p className={styles.label}>Gender</p>
                    <p className={styles.value}>
                      {influencerData?.data.gender}
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>DOB</p>
                    <p className={styles.value}>
                      {influencerData?.data.dateOfBirth &&
                        format(
                          new Date(influencerData?.data.dateOfBirth),
                          "dd-mm-yyyy"
                        )}
                    </p>
                  </div>
                  <div className={styles.communities}>
                    <div className={styles.label}>Communities</div>
                    <div className={styles.community}>
                      {influencerData?.data.niche.map((community, idx) => (
                        <span key={idx}>{community}</span>
                      ))}
                    </div>
                  </div>
                  <div className={styles.communities}>
                    <div className={styles.label}>Tags</div>
                    <div className={styles.community}>
                      {influencerData?.data?.tags?.map((community, idx) => (
                        <span key={idx}>{community}</span>
                      ))}
                    </div>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}> Connected Platforms</p>
                    <div className={styles.value}>
                      <Collapse
                        expandIconPosition="end"
                        defaultActiveKey={["1"]}
                        ghost>
                        {(influencerData?.data?.socialMediaKits || []).map(
                          (socialMedia, idx) => (
                            <Panel
                              header={
                                <Space>
                                  <img
                                    src={`/assets/img/${socialMedia.socialMediaPlatform}.png`}
                                    alt=""
                                  />
                                  <p>{socialMedia.socialMediaName}</p>
                                  <a
                                    href={
                                      socialMedia.socialMediaPlatform ===
                                      "instagram"
                                        ? `https://www.instagram.com/${socialMedia.socialMediaName}`
                                        : `https://www.youtube.com/channel/${socialMedia.socialMediaId}`
                                    }
                                    target={"_blank"}
                                    rel="noreferrer">
                                    <LinkOutlined />
                                  </a>

                                  {socialMedia?.dataProvider === "default" && (
                                    <Tag color="green">Verified</Tag>
                                  )}
                                </Space>
                              }
                              key={idx + 1}>
                              <Space
                                style={{ width: "100%" }}
                                size="large"
                                direction="vertical">
                                {/* <InsightCard socialMedia={socialMedia} /> */}
                                {/* <CommercialCard
                                  getSocialMediaKitsKey={getSocialMediaKitsKey}
                                  getAllInfluencersKey={getAllInfluencersKey}
                                  socialMedia={socialMedia}
                                /> */}
                              </Space>
                            </Panel>
                          )
                        )}
                      </Collapse>
                    </div>
                  </div>
                  <div className={styles.communities}>
                    <div className={styles.label}>User Entered Platforms</div>
                    <div className={styles.community}>
                      {influencerData?.data?.userPlatforms?.map(
                        (platformItem, idx) => (
                          <span key={idx}>
                            {platformItem.platform === "instagram" ? (
                              <AiFillInstagram style={{ marginRight: 5 }} />
                            ) : platformItem.platform === "youtube" ? (
                              <AiFillYoutube style={{ marginRight: 5 }} />
                            ) : null}
                            {platformItem.username}
                            {platformItem?.connectedThrough && (
                              <AiOutlineCheck style={{ marginLeft: 5 }} />
                            )}
                          </span>
                        )
                      )}
                    </div>
                  </div>

                  <div className={styles.info}>
                    <p className={styles.label}>Admin Entered Location</p>
                    <p className={styles.value}>
                      {influencerData?.data?.geoLocationName}
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>Delivery Address</p>
                    <p className={styles.value}>
                      {influencerData?.data.deliveryAddress.line !== ""
                        ? influencerData?.data.deliveryAddress.line +
                          ", " +
                          influencerData?.data.deliveryAddress.city +
                          ", " +
                          influencerData?.data.deliveryAddress.state +
                          ", " +
                          influencerData?.data.deliveryAddress.country +
                          ", Pincode-" +
                          influencerData?.data.deliveryAddress.pincode
                        : "-"}
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>GST</p>

                    <p className={styles.value}>
                      {!isEdit.gst ? (
                        <>
                          {influencerData?.data?.kyc?.kycDetails?.gst !== "" ? (
                            <Row justify="space-between">
                              <Space>
                                <p>
                                  {influencerData?.data?.kyc?.kycDetails?.gst}
                                </p>
                                {/* <p className={styles.docUrl}>
                                  <a
                                    href={influencerData?.data?.kyc?.kycDetails?.gstVerificationDocRef?.at(
                                      0
                                    )}
                                    target="_blank"
                                    rel="noreferrer">
                                    View Certificate
                                  </a>
                                </p> */}
                              </Space>
                              {/* <EditOutlined
                                onClick={() =>
                                  setIsEdit({ ...isEdit, gst: true })
                                }
                                style={{ cursor: "pointer" }}
                              /> */}
                            </Row>
                          ) : (
                            <Row justify="space-between">
                              <p>{kycIsGstRegistered ? "Yes" : "No"}</p>
                              {/* <EditOutlined
                                onClick={() =>
                                  setIsEdit({ ...isEdit, gst: true })
                                }
                                style={{ cursor: "pointer" }}
                              /> */}
                            </Row>
                          )}
                        </>
                      ) : (
                        <>
                          <Row justify="space-between">
                            <Row
                              style={{ width: "100%" }}
                              justify="space-between">
                              <Switch
                                checked={kycIsGstRegistered}
                                onChange={(e) => setKycIsGstRegistered(e)}
                              />
                              <Button
                                onClick={() =>
                                  updateInfluencerMutation.mutate({
                                    influencerId: influencerData?.data._id,
                                    kycIsGstRegistered,
                                    kycGst: kycIsGstRegistered ? kycGst : "",
                                    kycGstVerificationDocRef: kycIsGstRegistered
                                      ? kycGstVerificationDocRef
                                      : [],
                                    kycCompanyName,
                                  })
                                }
                                disabled={
                                  kycIsGstRegistered
                                    ? kycGst === "" ||
                                      kycGstVerificationDocRef?.length === 0
                                    : false
                                }
                                loading={updateInfluencerMutation.isLoading}>
                                <CheckOutlined
                                  style={{
                                    cursor: "pointer",
                                    color: "#00B578",
                                  }}
                                />
                              </Button>
                            </Row>
                            {kycIsGstRegistered && (
                              <Row style={{ marginTop: "10px" }}>
                                <Space direction="vertical">
                                  <Input
                                    placeholder="Enter GST No."
                                    value={kycGst}
                                    onChange={(e) => setKycGst(e.target.value)}
                                  />
                                  <Upload
                                    listType="picture-card"
                                    {...getUploadButtonProps("gst-cert")}
                                    fileList={
                                      kycGstVerificationDocRef?.length !== 0
                                        ? [
                                            {
                                              name: "image.png",
                                              status: "done",
                                              url:
                                                kycGstVerificationDocRef &&
                                                kycGstVerificationDocRef[0],
                                              uid:
                                                kycGstVerificationDocRef &&
                                                kycGstVerificationDocRef[0],
                                            } as UploadFile,
                                          ]
                                        : undefined
                                    }>
                                    {kycGstVerificationDocRef?.length === 0 &&
                                      uploadingImage !== "gst-cert" &&
                                      uploadButton}
                                  </Upload>
                                </Space>
                                <Input
                                  placeholder="Enter Company Name"
                                  value={kycCompanyName}
                                  onChange={(e) =>
                                    setKycCompanyName(e.target.value)
                                  }
                                />
                              </Row>
                            )}
                          </Row>
                        </>
                      )}
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>PAN</p>

                    <p className={styles.value}>
                      {!isEdit.pan ? (
                        <>
                          {influencerData?.data?.kyc?.kycDetails?.pan !== "" ? (
                            <Row justify="space-between">
                              <Space>
                                <p>
                                  {influencerData?.data?.kyc?.kycDetails?.pan}
                                </p>
                                {/* <p className={styles.docUrl}>
                                  <a
                                    href={influencerData?.data?.kyc?.kycDetails?.panVerificationDocRef?.at(
                                      0
                                    )}
                                    target="_blank"
                                    rel="noreferrer">
                                    View Doc
                                  </a>
                                </p> */}
                              </Space>
                              {/* <EditOutlined
                                onClick={() =>
                                  setIsEdit({ ...isEdit, pan: true })
                                }
                                style={{ cursor: "pointer" }}
                              /> */}
                            </Row>
                          ) : (
                            <Row justify="space-between">
                              <p>-</p>
                              {/* <EditOutlined
                                onClick={() =>
                                  setIsEdit({ ...isEdit, pan: true })
                                }
                                style={{ cursor: "pointer" }}
                              /> */}
                            </Row>
                          )}
                        </>
                      ) : (
                        <>
                          <Row justify="space-between">
                            <Row
                              style={{ width: "100%" }}
                              justify="space-between">
                              <Input
                                style={{ width: "30%" }}
                                placeholder="Enter PAN No."
                                value={kycPan}
                                onChange={(e) => setKycPan(e.target.value)}
                              />
                              <Button
                                onClick={() =>
                                  updateInfluencerMutation.mutate({
                                    influencerId: influencerData?.data._id,
                                    kycPan: kycPan,
                                    kycPanVerificationDocRef: [""],
                                  })
                                }
                                disabled={kycPan === ""}
                                loading={updateInfluencerMutation.isLoading}>
                                <CheckOutlined
                                  style={{
                                    cursor: "pointer",
                                    color: "#00B578",
                                  }}
                                />
                              </Button>
                            </Row>

                            {/* <Row style={{ marginTop: "10px" }}>
                              <Space direction="vertical">
                                <Upload
                                  listType="picture-card"
                                  {...getUploadButtonProps("pan")}
                                  fileList={
                                    kycPanVerificationDocRef?.length !== 0
                                      ? [
                                          {
                                            name: "image.png",
                                            status: "done",
                                            url:
                                              kycPanVerificationDocRef &&
                                              kycPanVerificationDocRef[0],
                                            uid:
                                              kycPanVerificationDocRef &&
                                              kycPanVerificationDocRef[0],
                                          } as UploadFile,
                                        ]
                                      : undefined
                                  }>
                                  {kycPanVerificationDocRef?.length === 0 &&
                                    uploadingImage !== "pan" &&
                                    uploadButton}
                                </Upload>
                              </Space>
                            </Row> */}
                          </Row>
                        </>
                      )}
                    </p>
                  </div>
                  <div className={styles.info}>
                    <p className={styles.label}>Billing Address</p>
                    <p className={styles.value}>
                      {!isEdit.address ? (
                        <>
                          <Row justify="space-between">
                            <p>
                              {influencerData?.data?.kyc?.kycDetails?.address
                                ?.line !== ""
                                ? influencerData?.data?.kyc?.kycDetails?.address
                                    ?.line +
                                  ", " +
                                  influencerData?.data?.kyc?.kycDetails?.address
                                    ?.city +
                                  ", " +
                                  influencerData?.data?.kyc?.kycDetails?.address
                                    ?.state +
                                  ", " +
                                  influencerData?.data?.kyc?.kycDetails?.address
                                    ?.country +
                                  ", Pincode-" +
                                  influencerData?.data?.kyc?.kycDetails?.address
                                    ?.pincode
                                : "-"}
                            </p>
                            <EditOutlined
                              onClick={() =>
                                setIsEdit({ ...isEdit, address: true })
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </Row>
                        </>
                      ) : (
                        <>
                          <Space direction="vertical">
                            <Space>
                              <Input
                                value={kycAddress.line}
                                onChange={(e) =>
                                  setKycAddress({
                                    ...kycAddress,
                                    line: e.target.value,
                                  })
                                }
                                placeholder="Enter Complete Address"
                              />
                              <Input
                                value={kycAddress.city}
                                onChange={(e) =>
                                  setKycAddress({
                                    ...kycAddress,
                                    city: e.target.value,
                                  })
                                }
                                placeholder="Enter City"
                              />
                              <Input
                                value={kycAddress.state}
                                onChange={(e) =>
                                  setKycAddress({
                                    ...kycAddress,
                                    state: e.target.value,
                                  })
                                }
                                placeholder="Enter State"
                              />
                              <Button
                                onClick={() =>
                                  updateInfluencerMutation.mutate({
                                    influencerId: influencerData?.data._id,
                                    kycAddress,
                                  })
                                }
                                disabled={
                                  kycAddress?.line === "" ||
                                  kycAddress?.city === "" ||
                                  kycAddress?.state === "" ||
                                  kycAddress?.country === "" ||
                                  kycAddress?.pincode === ""
                                }
                                loading={updateInfluencerMutation.isLoading}>
                                <CheckOutlined
                                  style={{
                                    cursor: "pointer",
                                    color: "#00B578",
                                  }}
                                />
                              </Button>
                            </Space>
                            <Space>
                              <Input
                                value={kycAddress.country}
                                onChange={(e) =>
                                  setKycAddress({
                                    ...kycAddress,
                                    country: e.target.value,
                                  })
                                }
                                placeholder="Enter Country"
                              />
                              <Input
                                value={kycAddress.pincode}
                                onChange={(e) =>
                                  setKycAddress({
                                    ...kycAddress,
                                    pincode: e.target.value,
                                  })
                                }
                                placeholder="Enter Pincode"
                              />
                            </Space>
                          </Space>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}{" "}
          </>
        )}
      </Drawer>
      {openDrawer && (
        <InfluencerPaymentDrawer
          openDrawer={openPaymentDrawer}
          setOpenDrawer={setOpenPaymentDrawer}
          influencerId={influencerData?.data._id}
        />
      )}
      <RejectInfluencerModal
        visible={rejectModalOpen}
        handleSubmit={() => {
          setOpenDrawer(false);
          setRejectModalOpen(false);
        }}
        handleCancel={() => setRejectModalOpen(false)}
        getInfluencerKey={getInfluencerKey}
        getAllInfluencersCountKey={getAllInfluencersCountKey}
        getAllInfluencersKey={getAllInfluencersKey}
        influencerId={influencerData?.data._id || ""}
      />
      <AddPlatformModal
        initialPlatform={connectPlatformProps?.platform}
        handleClose={() => {
          // queryClient.invalidateQueries(getSocialMediaKitsKey);
          setConnectPlatformProps({
            visible: false,
            platform: "instagram",
          });
        }}
        visible={connectPlatformProps?.visible}
        influencerId={influencerData?.data?._id || ""}
      />
      <EditUserDetailsModal
        handleClose={() => setEdtUserDetailsModalVisible(false)}
        visible={editUserDetailsModalVisible}
        initialData={influencerData?.data || null}
        handleSubmit={() => {
          setTimeout(() => {
            queryClient.invalidateQueries(getInfluencerKey);
          }, 500);
          setTimeout(() => {
            queryClient.invalidateQueries(getAllInfluencersKey);
            queryClient.invalidateQueries(getAllInfluencersCountKey);
          }, 5000);
          setEdtUserDetailsModalVisible(false);
        }}
        getInfluencerKey={getInfluencerKey}
      />
    </>
  );
};

export default InfluencerKycDrawer;
