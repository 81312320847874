import { Route, Routes, Navigate } from "react-router-dom";
import Brand from "../components/AdminDashboard/Brand";
import Campaign from "../components/AdminDashboard/Campaign";
import Influencer from "../components/AdminDashboard/Influencer";
import Logs from "../components/AdminDashboard/Logs";
import OnboardCreators from "../components/AdminDashboard/OnboardCreators";
import Payouts from "../components/AdminDashboard/Payouts";
import Restaurants from "../components/AdminDashboard/Restaurants";
import Community from "../components/AdminDashboard/Community";
import ExtensionCodes from "../components/AdminDashboard/ExtensionCodes";
import CloutflowUsers from "../components/AdminDashboard/CloutflowUsers";
import AdminUsersComponent from "../components/AdminDashboard/Admin";
import { getUserRole } from "../handler/User/getUserRole";
import { useQuery } from "react-query";

const MainRoutes = () => {
  const [queryKey, queryFunction] = getUserRole();
  const { data, isLoading, error } = useQuery(queryKey, queryFunction);
  const accessLevel = data?.admin.accessLevel;

  return (
    <Routes>
      {accessLevel.includes("Brand") && <Route path="/" element={<Brand />} />}
      {accessLevel.includes("Creator") && (
        <Route path="/creator" element={<Influencer />} />
      )}
      {accessLevel.includes("Campaign") && (
        <Route path="/campaign" element={<Campaign />} />
      )}
      {accessLevel.includes("Logs") && (
        <Route path="/logs" element={<Logs />} />
      )}
      {accessLevel.includes("Connect-Request") && (
        <Route path="/onboard" element={<OnboardCreators />} />
      )}
      {accessLevel.includes("Restaurant") && (
        <Route path="/restaurants" element={<Restaurants />} />
      )}
      {accessLevel.includes("Payouts") && (
        <Route path="/payouts" element={<Payouts />} />
      )}
      {accessLevel.includes("Community") && (
        <Route path="/community" element={<Community />} />
      )}
      {accessLevel.includes("Extention") && (
        <Route path="/extensionCodes" element={<ExtensionCodes />} />
      )}
      {accessLevel.includes("User") && (
        <Route path="/cloutflow-users" element={<CloutflowUsers />} />
      )}
      {accessLevel.includes("User") && (
        <Route path="/admin" element={<AdminUsersComponent />} />
      )}
      {accessLevel.includes("Creator") && (
        <Route path="*" element={<Navigate to={"/creator"} />} />
      )}
    </Routes>
  );
};

export default MainRoutes;
