import {createContext, useContext, useState} from 'react'

interface Props {
  children: React.ReactNode;
}

interface ContextInterFace {
    showOrgDetails: boolean;
    setShowOrgDetails: (c: boolean) => void;
}

export const OrgDetailsContext = createContext<ContextInterFace | undefined>(undefined)

export const useOrgDetailsContext = ()=> {
  const context = useContext(OrgDetailsContext)
  if (!context) {
    throw new Error("useOrgDetailsContext must be used in <OrgDetailsProvider />");
  }

  return context;
}

export const OrgDetailsProvider:React.FC<Props> = (props:any) => {

    const [showOrgDetails, setShowOrgDetails] = useState(false)

  return (
    <OrgDetailsContext.Provider value={{showOrgDetails, setShowOrgDetails}}>
        {props.children}
    </OrgDetailsContext.Provider>
  ) 
}

