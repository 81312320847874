import { Influencer } from "../../typings";
import { sendRequest } from "../helper";

interface createInfluencerProps {
  phoneNumber: string;
  fName: string;
  lName: string;
}

interface Result {
  status: string;
  message: string;
  data: Influencer;
}

export const createInfluencer = (props: createInfluencerProps) => {
  return sendRequest<Result>("post", `/influencer/me/create`, false, {
    ...props,
  }).then((res) => res.data);
};
