import React, { useState } from "react";
import styles from "./InfluencerTile.module.css";
import { Influencer } from "../../../../typings";
import { CheckOutlined } from "@ant-design/icons";
import { getStatus } from "../../../../Utils/getStatus";
import { KycConst } from "../../../../const/kycStatus.const";
import { format } from "date-fns";
import { Checkbox, Space, Typography, Tag } from "antd";
import { LinkOutlined } from "@ant-design/icons";

import InfluencerKycDrawer from "../../../Drawers/InfluencerKycDrawer";

const { Paragraph } = Typography;

interface InfluencerTileProps {
  tileData: Influencer;
  getAllInfluencersKey: (
    | string
    | number
    | {
        text: string;
        received: string;
        kycStatus: string;
        isPriceRequested: boolean;
        minFollowers?: number | undefined;
        maxFollowers?: number | undefined;
      }
    | undefined
  )[];
  getAllInfluencersCountKey: (
    | string
    | number
    | {
        text: string;
        received: string;
        kycStatus: string;
        isPriceRequested: boolean;
        minFollowers?: number | undefined;
        maxFollowers?: number | undefined;
      }
    | undefined
  )[];
  setSelectedState: React.Dispatch<React.SetStateAction<string[]>>;
  isSelected: boolean;
}

const InfluencerTile: React.FC<InfluencerTileProps> = ({
  tileData,
  getAllInfluencersKey,
  setSelectedState,
  isSelected,
}) => {
  //Use States
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  return (
    <>
      <div className={styles.tile}>
        <Checkbox
          checked={isSelected}
          onChange={(e) => {
            if (e?.target?.checked) {
              setSelectedState((prev) => {
                let set = new Set(prev);
                set.add(tileData._id);
                return Array.from(set);
              });
            } else {
              setSelectedState((prev) => {
                let set = new Set(prev);
                set.delete(tileData._id);

                return Array.from(set);
              });
            }
          }}></Checkbox>
        {tileData.lastUpdatedDateTime ? (
          <p onClick={() => setOpenDrawer(true)}>
            {format(
              new Date(tileData?.lastUpdatedDateTime),
              "hh:mm a, do LLL yy"
            )}
          </p>
        ) : (
          <p>-</p>
        )}
        <p onClick={() => setOpenDrawer(true)}>
          {tileData.name === "" || !tileData.name
            ? "-"
            : tileData.name + " " + tileData.lastname}
          <Paragraph
            copyable={{
              text: `https://www.instagram.com/${
                tileData?.instagramUsername || "-"
              }`,
            }}>
            IG : {tileData?.instagramUsername || "-"}
          </Paragraph>
          <p></p>
        </p>
        <p onClick={() => setOpenDrawer(true)}>{tileData.phoneNumber}</p>
        <p onClick={() => setOpenDrawer(true)}>
          <Space direction="vertical">
            {tileData.socialMediaKits.map((socialMedia, idx) => (
              <Space key={idx}>
                <img
                  src={`/assets/img/${socialMedia.socialMediaPlatform}.png`}
                  alt=""
                />
                <p>
                  {socialMedia.socialMediaPlatform === "instagram" && "@"}
                  {socialMedia.socialMediaName}

                  {socialMedia?.dataProvider === "default" && (
                    <p>
                      <Tag color="green">Verified</Tag>
                    </p>
                  )}
                </p>
                <a
                  href={
                    socialMedia.socialMediaPlatform === "instagram"
                      ? `https://www.instagram.com/${socialMedia.socialMediaName}`
                      : `https://www.youtube.com/channel/${socialMedia.socialMediaId}`
                  }
                  target={"_blank"}
                  rel="noreferrer">
                  <LinkOutlined />
                </a>
              </Space>
            ))}
            {tileData.socialMediaKits.length === 0 && "-"}
          </Space>
        </p>
        <p onClick={() => setOpenDrawer(true)}>
          {tileData?.kyc?.status === KycConst.status.NOT_INITIATED ? (
            "-"
          ) : (
            <CheckOutlined style={{ color: "#00B578" }} />
          )}
        </p>
        <p onClick={() => setOpenDrawer(true)}>
          <span className="">
            {getStatus({ status: tileData?.kyc?.status }).icon}
          </span>{" "}
          <span
            style={{
              color: getStatus({ status: tileData?.kyc?.status }).color,
            }}>
            {getStatus({ status: tileData?.kyc?.status }).status}
          </span>
        </p>
        <>{tileData?.inviteCode || "None"}</>
      </div>

      <InfluencerKycDrawer
        id={tileData._id}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        getAllInfluencersKey={getAllInfluencersKey}
      />
    </>
  );
};

export default InfluencerTile;
