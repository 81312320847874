import { Button, Input, message, Modal } from "antd";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { cancelDeal } from "../../../../handler/Influencer";
import { PaymentOffers } from "../../../../typings";
import nf from "@tuplo/numberfmt";
import styles from "./CampaignDealTile.module.css";
import CampaignDrawer from "../../CampaignDrawer";
import CampaignPaymentDrawer from "..";
import InfluencerKycDrawer from "../../InfluencerKycDrawer";
const { TextArea } = Input;

interface CampaignDealTileProps {
  campaignDeal: PaymentOffers;
  getCampaignAllDealsKey: (string | number | undefined)[];
  openFromInfluencerDrawer: boolean;
}

const CampaignDealTile: React.FC<CampaignDealTileProps> = ({
  campaignDeal,
  getCampaignAllDealsKey,
  openFromInfluencerDrawer,
}) => {
  const queryClient = useQueryClient();
  // Use States
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [viewRemarks, setViewRemarks] = useState<boolean>(false);
  const [openCancelDealModal, setOpenCancelDealModal] =
    useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>();
  const [finalizedOfferId, setFinalizedOfferId] = useState<string>();

  // Cancel Deal Mutatation
  const cancelDealMutation = useMutation(cancelDeal, {
    onSuccess: () => {
      message.success("Deal Cancelled");
      setOpenCancelDealModal(false);
      setFinalizedOfferId("");
      queryClient.invalidateQueries(getCampaignAllDealsKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <>
      <div className={styles.campaign}>
        <p
          style={openFromInfluencerDrawer ? { cursor: "inherit" } : {}}
          onClick={() => {
            if (!openFromInfluencerDrawer) {
              setOpenDrawer(true);
            }
          }}
          className={styles.timeStamp}>
          {format(new Date(campaignDeal.createdDateTime), "hh:mm a, do LLL yy")}
        </p>
        <p
          style={openFromInfluencerDrawer ? { cursor: "inherit" } : {}}
          onClick={() => {
            if (!openFromInfluencerDrawer) {
              setOpenDrawer(true);
            }
          }}>
          <p className={styles.campaignName}>
            {campaignDeal?.influencer?.name}{" "}
            {campaignDeal?.influencer?.lastname}
          </p>{" "}
          <p className={styles.brandName}>
            {campaignDeal.platform === "instagram" && "@"}
            {campaignDeal.socialMediaKit.socialMediaName}
          </p>
        </p>

        <p
          style={openFromInfluencerDrawer ? { cursor: "inherit" } : {}}
          onClick={() => {
            if (!openFromInfluencerDrawer) {
              setOpenDrawer(true);
            }
          }}
          className={styles.totalPrice}>
          ₹{nf(campaignDeal.billingInfo.paidToInfluencerPrice, "0,0.00")}
        </p>
        <p
          style={openFromInfluencerDrawer ? { cursor: "inherit" } : {}}
          onClick={() => {
            if (!openFromInfluencerDrawer) {
              setOpenDrawer(true);
            }
          }}
          className={styles.totalPrice}>
          ₹{nf(campaignDeal.billingInfo.totalPrice, "0,0.00")}
        </p>

        <p>
          <p
            onClick={() => {
              if (!openFromInfluencerDrawer) {
                setOpenDrawer(true);
              }
            }}>
            {campaignDeal?.finalizedOffers?.ecrowChequeStatus}
          </p>
          {campaignDeal?.finalizedOffers?.ecrowChequeStatus === "Cancel" && (
            <p
              onClick={() => {
                setViewRemarks(true);
                setRemarks(campaignDeal?.finalizedOffers?.Offerpay?.remarks);
              }}
              className={styles.viewRemarks}>
              View Remarks
            </p>
          )}
        </p>
        {(campaignDeal?.finalizedOffers?.ecrowChequeStatus === "Approved" ||
          campaignDeal?.finalizedOffers?.ecrowChequeStatus === "Acquired") && (
          <Button
            onClick={() => {
              setFinalizedOfferId(campaignDeal?.finalizedOffers?._id);
              setOpenCancelDealModal(true);
            }}>
            Cancel deal
          </Button>
        )}
      </div>
      <Modal
        visible={viewRemarks}
        title="Remarks"
        cancelButtonProps={{ hidden: true }}
        onOk={() => {
          setViewRemarks(false);
          setRemarks("");
        }}
        onCancel={() => {
          setViewRemarks(false);
          setRemarks("");
        }}>
        <p>{remarks}</p>
      </Modal>
      <Modal
        onOk={() => cancelDealMutation.mutate({ finalizedOfferId, remarks })}
        visible={openCancelDealModal}
        title="Cancel Deal"
        okButtonProps={{
          disabled: remarks === "",
          loading: cancelDealMutation.isLoading,
        }}
        onCancel={() => setOpenCancelDealModal(false)}>
        <p>Cancellation reason</p>
        <TextArea
          rows={5}
          style={{ marginTop: "10px" }}
          placeholder="Write remakrs"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        />
      </Modal>
      <InfluencerKycDrawer
        id={campaignDeal.influencerId}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
    </>
  );
};

export default CampaignDealTile;
