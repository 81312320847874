import React, { useState, useEffect } from "react";
import {
  Modal,
  Space,
  Input,
  InputNumber,
  Col,
  Button,
  Row,
  Switch,
  Upload,
} from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import { PlusOutlined } from "@ant-design/icons";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import cuid from "cuid";

import { collection, addDoc, updateDoc, doc } from "firebase/firestore";

import { RestaurantInfo } from "..";

import { firebaseDb } from "../../../../firebase/firebase";

const { TextArea } = Input;

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

interface AddModalProps {
  visible: boolean;
  handleSubmit: () => void;
  handleClose: () => void;
  type: "Add" | "Edit";
  initialData: RestaurantInfo | null;
}

const AddModal: React.FC<AddModalProps> = ({
  visible,
  handleSubmit,
  handleClose,
  type,
  initialData,
}) => {
  const [inputData, setInputData] = useState<RestaurantInfo>({
    bannerUrls: [],
    collabInfo: "",
    description: "",
    dineoutLink: "",
    formLink: "",
    isActive: true,
    location: "",
    title: "",
    zomatoLink: "",
    id: "",
  });

  const resetInputData = () => {
    setInputData({
      bannerUrls: [],
      collabInfo: "",
      description: "",
      dineoutLink: "",
      formLink: "",
      isActive: true,
      location: "",
      title: "",
      zomatoLink: "",
      id: "",
    });
    setLoading(false);
  };

  useEffect(() => {
    if (initialData) {
      setInputData(initialData);
    }
  }, [initialData]);

  const UploadButtonProps: UploadProps = {
    name: "file",
    multiple: false,
    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;
      const storage = getStorage();
      const fileName = (file as RcFile).name;

      const storageRef = ref(
        storage,
        `/Restaurants/banners/${fileName.slice(0, 10)} + ${cuid()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
      // setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);
          setInputData((inputData) => {
            return {
              ...inputData,
              bannerUrls: [...inputData.bannerUrls, downloadURL],
            };
          });
        }
      );
    },
    accept: "image/*",
    onRemove(file) {
      setInputData((inputData) => {
        return {
          ...inputData,
          bannerUrls: inputData.bannerUrls.filter(
            (reference) => reference !== file.url
          ),
        };
      });
    },
  };

  const [loading, setLoading] = useState(false);

  const validateInput = () => {
    if (
      inputData?.bannerUrls?.length > 0 &&
      inputData.title &&
      inputData?.description &&
      inputData?.location &&
      inputData.formLink
    )
      return true;
    return false;
  };

  return (
    <Modal
      open={visible}
      okButtonProps={{ loading, disabled: !validateInput() }}
      width={700}
      onOk={async () => {
        setLoading(true);
        const docRef =
          type === "Add"
            ? await addDoc(collection(firebaseDb, "Restaurant"), {
                ...inputData,
                createdDateTime: new Date(),
                id: cuid(),
              })
            : await updateDoc(
                doc(firebaseDb, "Restaurant", inputData.id),
                inputData
              );
        console.log("Document written with ID: ", docRef);
        resetInputData();
        handleSubmit();
      }}
      onCancel={() => {
        resetInputData();
        handleClose();
      }}
      title="Add Restaurant Details">
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Space>
            Name
            <Input
              value={inputData.title}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  title: e.target.value.trim() || "",
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Description
            <TextArea
              value={inputData.description}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  description: e.target.value.trim() || "",
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Banner Urls
            <Upload
              listType="picture-card"
              fileList={inputData.bannerUrls.map((imgItem) => {
                return {
                  uid: imgItem,
                  name: imgItem,
                  status: "done",
                  url: imgItem,
                };
              })}
              {...UploadButtonProps}>
              {inputData.bannerUrls.length >= 8 ? null : uploadButton}
            </Upload>
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Location
            <Input
              value={inputData.location}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  location: e.target.value.trim() || "",
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Collab info
            <Input
              value={inputData.collabInfo}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  collabInfo: e.target.value.trim() || "",
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Zomato link
            <Input
              value={inputData.zomatoLink}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  zomatoLink: e.target.value.trim() || "",
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Dineout Link
            <Input
              value={inputData.dineoutLink}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  dineoutLink: e.target.value.trim() || "",
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Form Link
            <Input
              value={inputData.formLink}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  formLink: e.target.value.trim() || "",
                });
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            Is Offer Active
            <Switch
              checked={inputData.isActive}
              onChange={(checked) =>
                setInputData({ ...inputData, isActive: checked })
              }
            />
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddModal;
