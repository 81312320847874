import React, { useState, useEffect } from "react";
import { Modal, InputNumber, Input, message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import styles from "./create.influencer.module.css";
import { inviteInfluencer } from "../../../handler/Onboard";

interface Props {
  visible: boolean;
  handleClose: () => void;
}

const InviteInfluencer: React.FC<Props> = ({ visible, handleClose }) => {
  const [uInput, setUInput] = useState<{
    name: string;
    phoneNumber: string;
    campaignName: string;
  }>({
    name: "",
    phoneNumber: "",
    campaignName: "",
  });

  const queryClient = useQueryClient();

  const inviteInfluencerMutation = useMutation(inviteInfluencer, {
    onSuccess: () => {
      message.success("Influencer Invited");
      handleClose();
    },
    onError: (err: AxiosError) => {
      message.error((err.response?.data as any)?.message);
    },
  });

  const handleSubmit = () => {
    if (!uInput?.name?.trim().length) {
      return message.error("Please Enter Influencer First Name");
    }

    if (uInput?.phoneNumber?.trim().length !== 12) {
      return message.error("Please Enter Valid Contact Number");
    }

    if (!uInput?.campaignName?.trim().length) {
      return message.error("Please Enter Campaign Name");
    }

    return inviteInfluencerMutation.mutate({
      influencername: uInput.name,
      phoneNumber: uInput.phoneNumber,
      campaignname: uInput.campaignName,
    });
  };

  useEffect(() => {
    if (!visible) {
      setUInput({
        name: "",
        phoneNumber: "",
        campaignName: "",
      });
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      title="Invite Influencer"
      okText="Send Invite"
      okButtonProps={{ loading: inviteInfluencerMutation.isLoading }}
      onOk={() => handleSubmit()}
    >
      <div className={styles.container}>
        <div className={styles.inputWrapper}>
          <p>Influencer First Name</p>
          <Input
            value={uInput.name}
            onChange={(e) =>
              setUInput((prev) => ({ ...prev, name: e.target.value }))
            }
            placeholder="John"
          />
        </div>
        <div className={styles.inputWrapper}>
          <p>Phone Number</p>
          <InputNumber
            value={uInput.phoneNumber}
            style={{ width: "100%" }}
            onChange={(value) =>
              setUInput((prev) => ({
                ...prev,
                phoneNumber: String(value || ""),
              }))
            }
            placeholder="9897123496"
          />
        </div>
        <div className={styles.inputWrapper}>
          <p>Campaign Name</p>
          <Input
            value={uInput.campaignName}
            onChange={(e) =>
              setUInput((prev) => ({ ...prev, campaignName: e.target.value }))
            }
            placeholder="Campaign XYZ"
          />
        </div>
      </div>
    </Modal>
  );
};

export default InviteInfluencer;
