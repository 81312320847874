import { sendRequest } from "../helper";

type getUserRoleResponse = {
  status: string;
  message: string;
  admin: any;
};

export const getUserRole = () => {
  const queryKey = ["user-role"];
  const queryFunction = () =>
    sendRequest<getUserRoleResponse>("get", `/admin/user`, false).then(
      (res) => {
        return res.data;
      }
    );
  return [queryKey, queryFunction] as const;
};
