import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./index.css";
import "antd/dist/antd.min.css";
import { AuthContextProvider } from "./context/AuthContext";
import { OrgDetailsProvider } from "./context/OrgDetailsContext";

import { NotificationTemplateProvider } from "./context/NotificationTemplateContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();
root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <BrowserRouter>
      <AuthContextProvider>
        <OrgDetailsProvider>
          <NotificationTemplateProvider>
            <App />
          </NotificationTemplateProvider>
        </OrgDetailsProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
