// @ts-nocheck

import {
  Button,
  Drawer,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Space,
  Switch,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styles from "./CampaignDrawer.module.css";
import {
  ClockCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  approveCampaign,
  getCampaign,
  sendCampaignRemarks,
  editCampaign,
} from "../../../handler/Campaign";
import { getFollowerRange } from "../../../Utils/getFollowerRange";
import nf from "@tuplo/numberfmt";
import { AxiosError } from "axios";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { RcFile, UploadProps } from "antd/lib/upload";
import cuid from "cuid";

import Dragger from "antd/lib/upload/Dragger";
import { Campaign } from "../../../typings";
const { TextArea } = Input;
const { Paragraph } = Typography;

interface CampaignDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  getAllCampaignsKey?: (
    | string
    | number
    | {
        text: string;
        brandId: string;
        status: string;
      }
    | undefined
  )[];
}

const CampaignDrawer: React.FC<CampaignDrawerProps> = ({
  openDrawer,
  setOpenDrawer,
  id,
  getAllCampaignsKey,
}) => {
  const queryClient = useQueryClient();
  // Use States
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);
  // const [campaignBannerUrl, setCampaignBannerUrl] = useState<string>("");
  const [campaignDetails, setCampaignDetails] = useState<{
    isEdit: boolean;
    campaignForm: Campaign | null;
  }>({ isEdit: false, campaignForm: null });
  const [isSendRemarksVisible, setIsSendRemarksVisible] =
    useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>("");
  const [viewRemarks, setViewRemarks] = useState<boolean>(false);

  //For Payment Terms
  const [paymentTerms, setPaymentTerms] = useState<{
    checked: boolean;
    description: string;
  }>({ checked: false, description: "" });

  // Get Brand data by id
  const [getCampaignKey, getCampaignFn] = getCampaign(id);
  const { data: campaignData, isLoading } = useQuery(
    getCampaignKey,
    getCampaignFn,
    {
      enabled: openDrawer,
    }
  );

  // Send Remakrs
  const sendRemarksMutation = useMutation(sendCampaignRemarks, {
    onSuccess: () => {
      message.success("Remarks Sent");
      setIsSendRemarksVisible(false);
      queryClient.invalidateQueries(getCampaignKey);
      queryClient.invalidateQueries(getAllCampaignsKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  // Approve Campaign
  const approveCampaignMutation = useMutation(approveCampaign, {
    onSuccess: () => {
      message.success("Campaign Approved");
      queryClient.invalidateQueries(getCampaignKey);
      queryClient.invalidateQueries(getAllCampaignsKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });
  // Edit Campaign Handler
  const editCampaignMutation = useMutation(editCampaign, {
    onSuccess: () => {
      message.success("Campaign Edited");
      queryClient.invalidateQueries(getCampaignKey);
      queryClient.invalidateQueries(getAllCampaignsKey);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  // Upload Documents

  const getUploadButtonProps = () => {
    return {
      name: "file",
      multiple: false,
      onDrop(e) {},
      customRequest: (options) => {
        const { onSuccess, file, onProgress, onError } = options;
        setUploadingImage(true);
        const storage = getStorage();
        const imageName = (file as RcFile).name;
        const storageRef = ref(
          storage,
          `/admin/campaign/${campaignData?.data?.campaign?._id}/${
            imageName + cuid()
          }`
        );
        const uploadTask = uploadBytesResumable(storageRef, file as RcFile);
        // setLoading(true);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            onProgress &&
              onProgress({
                percent:
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
              });
          },
          (error) => {
            console.log("firebaseError", error.code, error.message);

            onError && onError(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            onSuccess && onSuccess(downloadURL);
            setUploadingImage(false);
            setCampaignDetails((campaignDetails) => ({
              ...campaignDetails,
              campaignForm: {
                ...campaignDetails.campaignForm,
                campaign: {
                  ...campaignDetails.campaignForm?.campaign,
                  campaignBannerUrl: downloadURL,
                },
              },
            }));
          }
        );
      },
      accept: "image/png, image/jpeg",
      onRemove(file) {
        setCampaignDetails((campaignDetails) => ({
          ...campaignDetails,
          campaignForm: {
            ...campaignDetails.campaignForm,
            campaign: {
              ...campaignDetails.campaignForm?.campaign,
              campaignBannerUrl: "",
            },
          },
        }));
      },
    } as UploadProps;
  };

  //   if (openDrawer) console.log(campaignData);

  useEffect(() => {
    if (campaignData?.data) {
      if (campaignData?.data?.campaign?.paymentTerms) {
        setPaymentTerms({
          checked: true,
          description: campaignData?.data?.campaign?.paymentTerms,
        });
      }
      return setCampaignDetails({
        isEdit: false,
        campaignForm: campaignData?.data,
      });
    }
  }, [campaignData]);

  const getCampaignData = () => {
    if (campaignDetails?.isEdit)
      return campaignDetails?.campaignForm as Campaign;
    else return campaignData?.data;
  };

  return (
    <>
      <Drawer
        width={"40vw"}
        title="Campaign Details"
        extra={
          <div className={styles.extra}>
            <p className={styles.status}>
              {getCampaignData()?.campaign?.status === "Under-Review" && (
                <>
                  <span className="">
                    <ClockCircleOutlined style={{ color: "#FF8F1F" }} />
                  </span>{" "}
                  <span style={{ color: "#FF8F1F" }}>Review Pending</span>
                </>
              )}
              {getCampaignData()?.campaign?.status === "Active" && (
                <>
                  <span>
                    <CheckOutlined style={{ color: "#00B578" }} />
                  </span>{" "}
                  <span style={{ color: "#00B578" }}>Approved</span>
                </>
              )}
              {getCampaignData()?.campaign?.status === "Needs-Attention" && (
                <>
                  <span>
                    <CloseOutlined style={{ color: "#FF3141" }} />
                  </span>{" "}
                  <span style={{ color: "#FF3141" }}>Not Verified</span>
                </>
              )}
              <Button
                type="link"
                onClick={() => {
                  if (campaignDetails?.isEdit) {
                    editCampaignMutation.mutate({
                      campaign: {
                        ...campaignDetails?.campaignForm,
                        campaign: {
                          ...campaignDetails?.campaignForm?.campaign,
                          paymentTerms: paymentTerms.checked
                            ? paymentTerms.description
                            : "",
                        },
                      },
                    });
                    //  call edit Api
                  }
                  setCampaignDetails((cDetails) => ({
                    ...cDetails,
                    isEdit: !cDetails.isEdit,
                  }));
                }}
                loading={editCampaignMutation.isLoading}>
                {campaignDetails?.isEdit ? "Save" : "Edit"}
              </Button>
            </p>
          </div>
        }
        footer={
          <Row justify="end">
            {getCampaignData()?.campaign?.status !== "Active" && (
              <Space>
                {getCampaignData()?.campaign?.remarks !== "" && (
                  <Button onClick={() => setViewRemarks(true)}>
                    View Prev Remarks
                  </Button>
                )}
                {getCampaignData()?.campaign?.status !== "Needs-Attention" && (
                  <Button onClick={() => setIsSendRemarksVisible(true)}>
                    Mark as not verifed
                  </Button>
                )}
                <Button
                  loading={approveCampaignMutation.isLoading}
                  disabled={
                    getCampaignData()?.campaign?.status === "Needs-Attention" ||
                    campaignDetails?.campaignForm?.campaign
                      ?.campaignBannerUrl === "" ||
                    campaignDetails?.isEdit
                  }
                  onClick={() =>
                    approveCampaignMutation.mutate({
                      campaignId: campaignDetails?.campaignForm?.campaign?._id,
                      campaignBannerUrl:
                        campaignDetails?.campaignForm?.campaign
                          ?.campaignBannerUrl,
                      paymentTerms: paymentTerms.checked
                        ? paymentTerms.description
                        : "",
                    })
                  }
                  type="primary">
                  Approve
                </Button>
              </Space>
            )}
          </Row>
        }
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}>
        {openDrawer && (
          <>
            {isLoading ? (
              <Skeleton avatar paragraph={{ rows: 16 }} />
            ) : (
              <div className={styles.container}>
                <div className={styles.banner}>
                  {!campaignDetails?.campaignForm?.campaign
                    ?.campaignBannerUrl ? (
                    <Dragger
                      showUploadList={false}
                      style={{ height: "100%", width: "100%" }}
                      {...getUploadButtonProps()}>
                      {uploadingImage ? "Uploading..." : "+ Add a banner"}
                    </Dragger>
                  ) : (
                    <div className={styles.bannerImageContainer}>
                      <img
                        className={styles.bannerImage}
                        src={
                          campaignDetails?.campaignForm?.campaign
                            ?.campaignBannerUrl
                        }
                        alt="Banner"
                      />

                      <div className={styles.delete}>
                        <div
                          onClick={() =>
                            setCampaignDetails((campaignDetails) => ({
                              ...campaignDetails,
                              campaignForm: {
                                ...campaignDetails.campaignForm,
                                campaign: {
                                  ...campaignDetails.campaignForm?.campaign,
                                  campaignBannerUrl: "",
                                },
                              },
                            }))
                          }
                          className="">
                          <span>
                            <DeleteOutlined />
                          </span>
                          <span>Delete</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.header}>
                  <div className="">
                    <img
                      src={getCampaignData()?.brand.businessDetails?.logoUrl}
                      alt="Brand Logo"
                    />
                    <div className="">
                      {campaignDetails?.isEdit ? (
                        <Input
                          value={
                            campaignDetails?.campaignForm?.campaign
                              ?.campaignName
                          }
                          onChange={(e) => {
                            setCampaignDetails((cDetails) => ({
                              ...cDetails,
                              campaignForm: {
                                ...cDetails?.campaignForm,
                                campaign: {
                                  ...cDetails?.campaignForm?.campaign,
                                  campaignName: e.target.value || "",
                                },
                              },
                            }));
                          }}
                        />
                      ) : (
                        <p className={styles.campaignName}>
                          {getCampaignData()?.campaign?.campaignName}
                        </p>
                      )}
                      <p className={styles.brandName}>
                        {getCampaignData()?.brand.businessDetails.name}
                      </p>
                      <Button
                        size="middle"
                        type="primary"
                        style={{ marginTop: "20px" }}
                        onClick={() =>
                          window.open(
                            `${
                              process.env.REACT_APP_BRAND_SIDE_URL
                            }/public/custom/${
                              getCampaignData()?.brand?.emailId
                            }`,
                            "_blank"
                          )
                        }>
                        {" "}
                        Login as Brand
                      </Button>
                    </div>
                  </div>
                  <img
                    src={`/assets/img/${
                      getCampaignData()?.campaign?.platform
                    }.png`}
                    alt=""
                  />
                </div>

                <div className={styles.pocDetails}>
                  <div className="">
                    <p className={styles.pocTitle}>POC Name</p>
                    <p className={styles.pocValue}>
                      {getCampaignData()?.brand.name}{" "}
                      {getCampaignData()?.brand.lastname}
                    </p>
                  </div>
                  <div className="">
                    <p className={styles.pocTitle}>POC Email</p>
                    <p className={styles.pocValue}>
                      {getCampaignData()?.brand?.emailId}
                    </p>
                  </div>
                  <div className="">
                    <p className={styles.pocTitle}>POC Number</p>
                    <p className={styles.pocValue}>
                      {getCampaignData()?.brand.phoneNumber}
                    </p>
                  </div>
                </div>
                <div className={styles.communityAndFollower}>
                  <div className={styles.community}>
                    <p>Community</p>
                    <div className={styles.communities}>
                      {getCampaignData()?.campaign?.filter?.niche?.map(
                        (community, idx) => (
                          <p>{community}</p>
                        )
                      )}
                    </div>
                  </div>
                  <div className={styles.follower}>
                    <p>Follower Range</p>
                    <div className={styles.ranges}>
                      {getCampaignData()
                        ?.campaign?.filter?.followerCategory?.filter(
                          (item) => item !== "all"
                        )
                        .map((range, idx) => (
                          <p key={idx}>
                            {range} {getFollowerRange(range)}
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
                <div className={styles.productDetails}>
                  {getCampaignData()?.deal?.isProduct ? (
                    <>
                      <p>Products</p>
                      <div className={styles.products}>
                        {getCampaignData()?.deal?.productDetails?.map(
                          (product, idx) => (
                            <div key={idx} className={styles.product}>
                              <span>•</span>
                              <div className="">
                                <Paragraph
                                  editable={{
                                    editing: campaignDetails?.isEdit,
                                    icon: <div />,
                                    onChange(value) {
                                      setCampaignDetails((cDetails) => ({
                                        ...cDetails,
                                        campaignForm: {
                                          ...cDetails?.campaignForm,
                                          deal: {
                                            ...cDetails?.campaignForm?.deal,
                                            productDetails: [
                                              ...cDetails?.campaignForm?.deal?.productDetails.map(
                                                (item, index) =>
                                                  index === idx
                                                    ? {
                                                        ...item,
                                                        productTitle: value,
                                                      }
                                                    : item
                                              ),
                                            ],
                                          },
                                        },
                                      }));
                                    },
                                    text: product.productTitle,
                                  }}>
                                  {product.productTitle}
                                </Paragraph>
                                <Paragraph
                                  editable={{
                                    editing: campaignDetails?.isEdit,
                                    icon: <div />,
                                    onChange(value) {
                                      setCampaignDetails((cDetails) => ({
                                        ...cDetails,
                                        campaignForm: {
                                          ...cDetails?.campaignForm,
                                          deal: {
                                            ...cDetails?.campaignForm?.deal,
                                            productDetails: [
                                              ...cDetails?.campaignForm?.deal?.productDetails.map(
                                                (item, index) =>
                                                  index === idx
                                                    ? {
                                                        ...item,
                                                        price: value,
                                                      }
                                                    : item
                                              ),
                                            ],
                                          },
                                        },
                                      }));
                                    },
                                    text: product.price,
                                  }}>
                                  Price: ₹ {nf(product.price, "0,0.00")}/-
                                </Paragraph>
                                <Paragraph
                                  editable={{
                                    editing: campaignDetails?.isEdit,
                                    icon: <div />,
                                    onChange(value) {
                                      setCampaignDetails((cDetails) => ({
                                        ...cDetails,
                                        campaignForm: {
                                          ...cDetails?.campaignForm,
                                          deal: {
                                            ...cDetails?.campaignForm?.deal,
                                            productDetails: [
                                              ...cDetails?.campaignForm?.deal?.productDetails.map(
                                                (item, index) =>
                                                  index === idx
                                                    ? {
                                                        ...item,
                                                        productLink: value,
                                                      }
                                                    : item
                                              ),
                                            ],
                                          },
                                        },
                                      }));
                                    },
                                    text: product.productLink,
                                  }}>
                                  {product.productLink}
                                </Paragraph>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <p>What are you promoting?</p>
                      <div className={styles.promotion}>
                        <Paragraph
                          editable={{
                            editing: campaignDetails?.isEdit,
                            icon: <div />,
                            onChange(value) {
                              setCampaignDetails((cDetails) => ({
                                ...cDetails,
                                campaignForm: {
                                  ...cDetails?.campaignForm,
                                  deal: {
                                    ...cDetails?.campaignForm?.deal,
                                    promotionDescription: value,
                                  },
                                },
                              }));
                            },
                            text: getCampaignData()?.deal?.promotionDescription,
                          }}>
                          {getCampaignData()?.deal?.promotionDescription}
                        </Paragraph>
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.deliverableDetails}>
                  <p>Deliverables</p>
                  <div className={styles.deliverables}>
                    {getCampaignData()?.deal?.deliverables?.map(
                      (deliverable, idx) => (
                        <div key={idx} className={styles.deliverable}>
                          <span>•</span>
                          <div className="">
                            <p className={styles.deliverableName}>
                              {deliverable.platform === "youtube"
                                ? `${deliverable.duration} min ${deliverable.type} Video`
                                : deliverable.repost
                                ? `Repost ${deliverable.typeDel}`
                                : deliverable.typeDel === "Video" ||
                                  deliverable.typeDel === "Reel"
                                ? `${deliverable.duration}s ${deliverable.typeDel}`
                                : deliverable.typeDel === "Post"
                                ? `${deliverable.type} Image Post`
                                : deliverable.type === "video"
                                ? `${deliverable.duration}s Video Story`
                                : "Image Story"}
                            </p>
                            {deliverable.contentRights && (
                              <p className={styles.deliverableDescription}>
                                Content Rights: {deliverable.contentRights}
                              </p>
                            )}
                            {deliverable.link && (
                              <p className={styles.deliverableDescription}>
                                Swipe Up Link: {deliverable.link}
                              </p>
                            )}

                            {deliverable.products &&
                              deliverable.products.length !== 0 && (
                                <>
                                  <p className={styles.deliverableDescription}>
                                    Products
                                  </p>
                                  <div className={styles.products}>
                                    {deliverable.products?.map(
                                      (product, idx) => (
                                        <div
                                          key={idx}
                                          className={styles.productDescription}>
                                          <span>•</span>
                                          <div className="">
                                            <p>
                                              {
                                                getCampaignData()?.deal?.productDetails?.find(
                                                  (item) => item._id === product
                                                )?.productTitle
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              )}
                            {deliverable.repost ? (
                              <div className="">
                                <p className={styles.deliverableDescription}>
                                  Brief: {deliverable.repostData.instructions}
                                </p>
                                <div className={styles.repostImages}>
                                  {deliverable.repostData.content?.map(
                                    (repostImage, idx) => (
                                      <img
                                        key={idx}
                                        className={styles.repostImage}
                                        src={repostImage}
                                        alt=""
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            ) : (
                              <p className={styles.deliverableDescription}>
                                Brief:
                                <Paragraph
                                  editable={{
                                    editing: campaignDetails?.isEdit,
                                    icon: <div />,
                                    onChange(value) {
                                      setCampaignDetails((cDetails) => ({
                                        ...cDetails,
                                        campaignForm: {
                                          ...cDetails?.campaignForm,
                                          deal: {
                                            ...cDetails?.campaignForm?.deal,
                                            deliverables:
                                              cDetails?.campaignForm?.deal?.deliverables?.map(
                                                (item) =>
                                                  item._id !== deliverable._id
                                                    ? item
                                                    : {
                                                        ...item,
                                                        description: value,
                                                      }
                                              ),
                                          },
                                        },
                                      }));
                                    },
                                    text: deliverable.description,
                                  }}>
                                  {deliverable.description}
                                </Paragraph>
                              </p>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className={styles.guidelinesDetails}>
                  <p>Campaign Guidelines</p>
                  <div className="">
                    <p className={styles.guidelines}>
                      <Paragraph
                        editable={{
                          editing: campaignDetails?.isEdit,
                          icon: <div />,
                          onChange(value) {
                            setCampaignDetails((cDetails) => ({
                              ...cDetails,
                              campaignForm: {
                                ...cDetails?.campaignForm,
                                campaign: {
                                  ...cDetails?.campaignForm?.campaign,
                                  brandGuidelines: value,
                                },
                              },
                            }));
                          },
                          text: getCampaignData()?.campaign?.brandGuidelines,
                        }}>
                        {getCampaignData()?.campaign?.brandGuidelines}
                      </Paragraph>
                    </p>
                  </div>
                </div>
                <div className={styles.paymentGuidelines}>
                  <p>Payment Guidelines</p>
                  <Space style={{ alignItems: "center", display: "flex" }}>
                    <span>Do you need Payment Terms?</span>
                    <Switch
                      checked={paymentTerms.checked}
                      onChange={(val) =>
                        setPaymentTerms((paymentTerms) => ({
                          ...paymentTerms,
                          checked: val,
                        }))
                      }
                      disabled={
                        campaignData?.data?.campaign?.status === "Under-Review"
                          ? false
                          : !campaignDetails?.isEdit
                      }
                    />
                  </Space>

                  <Input
                    value={paymentTerms.description}
                    onChange={(e) => {
                      setPaymentTerms((paymentTerms) => ({
                        ...paymentTerms,
                        description: e.target.value || "",
                      }));
                    }}
                    disabled={!paymentTerms.checked}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </Drawer>
      {openDrawer && (
        <Modal
          open={isSendRemarksVisible}
          okText="Send"
          bodyStyle={{ maxHeight: "40vh" }}
          onOk={() =>
            sendRemarksMutation.mutate({
              campaignId: campaignData?.data?.campaign?._id as string,
              remarks: remarks,
            })
          }
          okButtonProps={{ loading: sendRemarksMutation.isLoading }}
          onCancel={() => setIsSendRemarksVisible(false)}
          title="Why is it not verified">
          <p className={styles.subText}>
            Explain the problem. the brand can see it
          </p>
          <TextArea
            style={{ marginTop: "10px" }}
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Write any message"
            rows={8}
          />
        </Modal>
      )}
      {openDrawer && (
        <Modal
          open={viewRemarks}
          okText="OK"
          bodyStyle={{ maxHeight: "40vh" }}
          onOk={() => setViewRemarks(false)}
          onCancel={() => setViewRemarks(false)}
          title="Previous Remarks">
          <p>{campaignData?.data?.campaign?.remarks}</p>
        </Modal>
      )}
    </>
  );
};

export default CampaignDrawer;
