import React, { useState, useEffect } from "react";

import { useMutation, useQuery } from "react-query";

import {
  addToOrganization,
  getAllOrganizations,
} from "../../../handler/Brand/org";

import { Modal, Checkbox, message, Input, Radio, Row, Spin } from "antd";
import { AxiosError } from "axios";

interface Props {
  userId: string;
  visible: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const AddOrganizationModal: React.FC<Props> = ({
  userId,
  visible,
  handleClose,
  handleSubmit,
}) => {
  const [uInput, setUInput] = useState<string>("");
  const [query, setQuery] = useState<string>("");

  const addOrgMutation = useMutation(addToOrganization, {
    onSuccess: () => {
      message.success("User Added");
      handleSubmit();
    },
    onError: (err: AxiosError) => {
      console.log(err);
    },
  });

  const [queryKey, queryFn] = getAllOrganizations({ limit: 10, query });

  const { data: orgData, isLoading } = useQuery(queryKey, queryFn, {
    enabled: Boolean(query.trim()),
  });

  useEffect(() => {
    if (!visible) {
      setQuery("");
      setUInput("");
    }
  }, [visible]);

  useEffect(() => {
    setUInput("");
  }, [query]);

  return (
    <Modal
      open={visible}
      okText={`Add  `}
      onOk={() => {
        addOrgMutation.mutate({ userId, orgId: uInput });
      }}
      okButtonProps={{
        loading: addOrgMutation.isLoading,
        disabled: !Boolean(uInput.trim()),
      }}
      onCancel={handleClose}
      title={"Add User from Organizations"}>
      <p>Search for Organization by name</p>
      <Input
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search For Org"
      />

      {isLoading ? (
        <Row justify={"center"}>
          <Spin />
        </Row>
      ) : (
        <Radio.Group
          style={{ width: "100%", display: "grid", gap: 8 }}
          value={uInput}
          onChange={(e) => setUInput(e.target.value)}>
          {orgData?.data?.users?.map((orgItem) => (
            <Radio value={orgItem?._id}>
              <div style={{ display: "grid" }}>
                <p>Owner:{orgItem?.businessDetails?.name}</p>
                <p>Owner Email:{orgItem?.emailId}</p>
              </div>
            </Radio>
          ))}
        </Radio.Group>
      )}
    </Modal>
  );
};

export default AddOrganizationModal;
