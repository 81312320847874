import { sendRequest } from "../helper";

interface approveKycProps {
  influencerId: string;
}

export const approveInfluencerKyc = ({ influencerId }: approveKycProps) => {
  return sendRequest(
    "put",
    `/influencer/kyc/approve?influencerId=${influencerId}`,
    false
  ).then((res) => res.data);
};
