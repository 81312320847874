import { sendRequest } from "../helper";

import { tierDetail } from "../../typings";

export const createCustomPlan = ({ plan }: { plan: tierDetail }) => {
  return sendRequest(
    "put",
    `/brand/saas-subscription/custom-tier-plan`,
    false,
    { ...plan }
  ).then((res) => res.data);
};
