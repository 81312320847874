import { sendRequest } from "../helper";

type getPayoutsCountResponse = {
	status: string;
	message: string;
	data: {
		count: number;
	};
};

export const getTnxPayoutsCount = ({
	status,
	ecrowChequeStatus,
}: {
	status: Array<string>;
	ecrowChequeStatus: Array<string>;
}) => {
	const queryKey = ["tnx-payouts-count", status, ecrowChequeStatus];

	console.log(queryKey);
	const queryFunction = () =>
		sendRequest<getPayoutsCountResponse>(
			"get",
			`/brand/tnx/payouts-count?status=${status}&ecrowChequeStatus=${ecrowChequeStatus}`,
			false,
		).then((res) => {
			return res.data;
		});
	return [queryKey, queryFunction] as const;
};
