import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import {
  Col,
  Select,
  Checkbox,
  Space,
  Divider,
  Button,
  Row,
  Spin,
  Table,
  message,
} from "antd";
import { useInfiniteQuery, useQuery } from "react-query";
import { ColumnsType } from "antd/es/table";

import { getInfluencersCommunity } from "../../../handler/Community/getInfluencersCommunity";
import { getAllBrands } from "../../../handler/Brand";

import BrandKycDrawer from "../../Drawers/BrandKycDrawer";
import InfluencerKycDrawer from "../../Drawers/InfluencerKycDrawer";

import {
  capitalizeFirstLetter,
  formatData,
  socialMediaUrlGen,
} from "../../../Utils";

import styles from "./styles.module.css";

interface DataType {
  dp: string;
  username: string;
  followers: number;
  // campaigns: number;
  platform: "instagram" | "youtube";
  name: string;
  brandName: string;
  brandId: string;
  isInviteAccepted: boolean;
  influencerId: string;
}

type PlatformConstant = "instagram" | "youtube";
type AccountStatusConstant = "connected" | "disconnected" | "session-expired";

const Community = () => {
  const [selectedBrand, setSelectedBrand] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [filters, setFilters] = useState<{
    platform: PlatformConstant[];
    accountStatus: AccountStatusConstant[];
  }>({
    platform: [],
    accountStatus: [],
  });

  const [influencersKey, influencersFn] = getInfluencersCommunity({
    pageNumber: 1,
    platform: filters.platform,
    accountStatus: filters.accountStatus,
    brandId: selectedBrand?.value || "",
  });

  const [brandSearchQuery, setBrandSearchQuery] = useState<{
    text: string;
    received: string;
    kycStatus: string;
  }>({
    text: "",
    received: "",
    kycStatus: "Verified",
  });

  const [getAllBrandsKey, getAllBrandsFn] = getAllBrands({
    page: 1,
    searchQuery: brandSearchQuery,
  });
  const { data: brands } = useQuery(getAllBrandsKey, getAllBrandsFn);

  const [brandDrawerProps, setBrandDrawerProps] = useState<{
    visible: boolean;
    brandId: string;
  }>({
    visible: false,
    brandId: "",
  });

  const [influencerDrawerProps, setInfluencerDrawerProps] = useState<{
    visible: boolean;
    influencerId: string;
  }>({
    visible: false,
    influencerId: "",
  });

  //   const { data: influencers } = useQuery(influencersKey, influencersFn);

  const {
    data: influencers,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(influencersKey, influencersFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.community?.length ? lastPage.data.pageNo + 1 : null,
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Profile",
      dataIndex: "dp",
      key: "profile",
      render: (_, { dp, name, influencerId, isInviteAccepted }) => (
        <Space size={10} align="center">
          <div className={styles.profilePictureContainer}>
            <img src={dp ?? ""} className={styles.profile} alt="profile" />
          </div>
          <p
            className={styles.name}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              if (isInviteAccepted)
                return setInfluencerDrawerProps({
                  visible: true,
                  influencerId,
                });
              return message.info("Influencer Not Connected ");
            }}>
            {name || "Unknown"}
          </p>
        </Space>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (_, { username, platform }) => (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          {/* {platform === "instagram" ? (
            <FaInstagram size={15} color="rgba(0, 0, 0, 0.70)" />
          ) : (
            <FaYoutube size={15} color="rgba(0, 0, 0, 0.70)" />
          )} */}
          <a
            href={socialMediaUrlGen({
              userNameOrChannelId: username,
              platform,
            })}>
            <p className={styles.username}>{username ?? ""}</p>
          </a>
        </div>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      render: (_, record) => (
        <span>{capitalizeFirstLetter(record.platform)}</span>
      ),

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8, display: "grid", gap: 10 }}>
          <p>Platform</p>
          {["instagram", "youtube"].map((platformItem) => (
            <Checkbox
              checked={filters.platform.includes(
                platformItem as PlatformConstant
              )}
              onChange={(event) => {
                let newFiltersPlatform = [...filters.platform];
                if (event.target.checked) {
                  newFiltersPlatform = [
                    ...newFiltersPlatform,
                    platformItem as PlatformConstant,
                  ];
                } else if (
                  newFiltersPlatform.includes(platformItem as PlatformConstant)
                ) {
                  newFiltersPlatform = newFiltersPlatform.filter(
                    (item) => item !== platformItem
                  );
                }
                setFilters((filters) => ({
                  ...filters,
                  platform: newFiltersPlatform,
                }));
              }}
              style={{ margin: 0 }}>
              {capitalizeFirstLetter(platformItem)}
            </Checkbox>
          ))}

          <Divider type="horizontal" style={{ marginBlock: 5 }} />

          <p>Account Status</p>
          {["connected", "disconnected", "session-expired"].map(
            (accountStatusItem) => (
              <Checkbox
                checked={filters.accountStatus.includes(
                  accountStatusItem as AccountStatusConstant
                )}
                style={{ margin: 0 }}
                onChange={(event) => {
                  let newFiltersAccountStatus = [...filters.accountStatus];
                  if (event.target.checked) {
                    newFiltersAccountStatus = [
                      ...newFiltersAccountStatus,
                      accountStatusItem as AccountStatusConstant,
                    ];
                  } else if (
                    newFiltersAccountStatus.includes(
                      accountStatusItem as AccountStatusConstant
                    )
                  ) {
                    newFiltersAccountStatus = newFiltersAccountStatus.filter(
                      (item) => item !== accountStatusItem
                    );
                  }
                  setFilters((filters) => ({
                    ...filters,
                    accountStatus: newFiltersAccountStatus,
                  }));
                }}>
                {capitalizeFirstLetter(accountStatusItem)}
              </Checkbox>
            )
          )}

          <div style={{ marginTop: 8 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedKeys([JSON.stringify(filters)]);
                confirm();
              }}>
              OK
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => {
        // const filterData = JSON.parse(value);
        // if (filterData.name) {
        //   return record.name.toLowerCase().includes("name");
        // }
        // if (filterData.age) {
        //   return record.age > 30;
        // }
        return true; // If no filters are selected, show all data
      },
    },
    {
      title: "Followers",
      dataIndex: "followers",
      key: "followers",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.followers - b.followers,
      render: (_, { followers }) => (
        <p className={styles.followers}> {formatData(followers ?? 0, "a")}</p>
      ),
    },
    {
      title: "Invited By",
      dataIndex: "brandName",
      key: "brandName",

      render: (_, { brandId, brandName }) => (
        <p
          className={styles.followers}
          style={{ cursor: "pointer" }}
          onClick={() => setBrandDrawerProps({ visible: true, brandId })}>
          {brandName}
        </p>
      ),
    },
    {
      title: "Connection Status",
      dataIndex: "isInviteAccepted",
      key: "isInviteAccepted",

      render: (_, { isInviteAccepted }) => (
        <p className={styles.followers}>
          {isInviteAccepted ? "Connected" : "Pending"}
        </p>
      ),
    },
  ];

  return (
    <div>
      <Col span={12}>
        <Select
          showSearch
          value={selectedBrand}
          style={{ width: 200 }}
          placeholder="Filter by brand name"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          labelInValue
          onSearch={(val) => {
            setBrandSearchQuery({ ...brandSearchQuery, text: val });
          }}
          allowClear
          onChange={(value) => {
            if (!value) {
              return setSelectedBrand(null);
            }
            setSelectedBrand({ label: value.label, value: value.value });
          }}
          notFoundContent={<p>No result Found</p>}
          options={(brands?.data || []).map((brand) => ({
            value: brand._id,
            label: brand.businessDetails?.name,
          }))}
        />
      </Col>

      <div
        className={styles.g10}
        style={{
          height: "auto",
          overflowY: "scroll",
        }}>
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          className={styles.g10}
          style={{ alignItems: "flex-start" }}
          hasMore={hasNextPage}
          loader={
            <Row justify="center">
              <Spin />
            </Row>
          }
          useWindow={false}>
          {isLoading ? (
            <Row justify="center">
              <Spin />
            </Row>
          ) : (
            <Table
              columns={columns}
              dataSource={
                influencers?.pages?.flatMap((pageItem) =>
                  pageItem.data?.community?.map((dataItem) => {
                    return {
                      dp: dataItem?.profilePicUrl,
                      username: dataItem?.socialMediaName,
                      followers: dataItem?.followers,
                      joiningDate: dataItem?.createdDateTime,
                      // campaigns: dataItem?.campaigns,
                      influencerId: dataItem?.influencerId,
                      isLocked: dataItem?.isProfileLockedForBrand,
                      platform: dataItem?.platform,
                      name: dataItem?.name || "",
                      brandId: dataItem?.brandId,
                      brandName: dataItem?.brand?.businessDetails?.name,
                      isInviteAccepted: Boolean(
                        Object.keys(dataItem?.socialMediaKit || {}).length > 0
                      ),
                    } as DataType;
                  })
                ) || []
              }
              scroll={{ y: 700 }}
              pagination={false}
            />
          )}
          {/* {InfluencerCampaignsData?.pages?.map((pageItem) =>
                    pageItem.data?.community?.map((dataItem) => (
                      <CreatorCard creatorData={dataItem} platform={platform} />
                    ))
                  )} */}
        </InfiniteScroll>
      </div>
      <BrandKycDrawer
        status="Verified"
        getAllBrandsKey={[""]}
        id={brandDrawerProps?.brandId}
        openDrawer={brandDrawerProps.visible}
        setOpenDrawer={() =>
          setBrandDrawerProps((brandDrawerProps) => ({
            ...brandDrawerProps,
            visible: false,
          }))
        }
      />
      <InfluencerKycDrawer
        id={influencerDrawerProps?.influencerId}
        openDrawer={influencerDrawerProps?.visible}
        setOpenDrawer={() =>
          setInfluencerDrawerProps((influencerDrawerProps) => ({
            ...influencerDrawerProps,
            visible: false,
          }))
        }
      />
    </div>
  );
};

export default Community;
