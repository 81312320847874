import { sendRequest } from "../../helper";

interface removeFromOrganizationProps {
  userId: string;
  orgIds: string[];
}

export const removeFromOrganization = (props: removeFromOrganizationProps) => {
  return sendRequest("put", `/brand/organization/user/remove`, false, {
    ...props,
  }).then((res) => res.data);
};
