import {
  DashOutlined,
  CloseOutlined,
  ClockCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { PaymentStatus } from "../const/PaymentStatus.const";

export const getCampaignPaymentStatus = (status: string) => {
  if (status === PaymentStatus.status.PROCESSING) {
    return {
      status: "Unpaid",
      color: "#999999",
    };
  }
  if (status === PaymentStatus.status.CANCEL) {
    return {
      status: "Cancelled",
      color: "#FF3141",
    };
  }
  if (status === PaymentStatus.status.SUCCESS) {
    return {
      status: "Paid",
      color: "#00B578",
    };
  }
  return {
    status,
    color: "#FF8F1F",
  };
};
