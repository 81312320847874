import { sendRequest } from "../helper";

interface manualPaymentProps {
  offerPayInId?: string;
  otp: number;
}

export const manualPayment = ({ offerPayInId, otp }: manualPaymentProps) => {
  return sendRequest(
    "post",
    `/brand/offer/payment/manual-in?offerPayInId=${offerPayInId}&otp=${otp}`,
    false
  ).then((res) => res.data);
};
