import { sendRequest } from "../../helper";

type sendInfluencerPushNotificationResult = {
	status: string;
	message: string;
	data: any;
};

export const sendInfluencerPushNotification = ({
	searchQuery,
	body,
}: {
	searchQuery: {
		text: string;
		received: string;
		kycStatus: string;
		isPriceRequested: boolean;
		minFollowers?: number;
		maxFollowers?: number;
		isArchived?: boolean;
		isPushEnabled: string;
		isTokenRevoked: string;
	};
	body: {
		title: string;
		body: string;
		influencerIds: string[];
		isSendToAll: boolean;
	};
}) => {
	var tmp = `/influencer/notification/push?received=${
		searchQuery.received
	}&kycStatus=${searchQuery.kycStatus}&text=${
		searchQuery.text
	}&isPriceRequested=${searchQuery.isPriceRequested}${
		searchQuery?.minFollowers ? `&minFollowers=${searchQuery.minFollowers}` : ""
	}${searchQuery?.isArchived ? `&adminStatus=Archive` : ""}
  ${
		searchQuery?.maxFollowers ? `&maxFollowers=${searchQuery.maxFollowers}` : ""
	}${
		searchQuery?.isPushEnabled
			? `&isPushEnabled=${searchQuery.isPushEnabled}`
			: ""
	}${
		searchQuery?.isTokenRevoked
			? `&isTokenRevoked=${searchQuery.isTokenRevoked}`
			: ""
	}`.replace(/\s+/g, "");

	return sendRequest<sendInfluencerPushNotificationResult>(
		"put",
		tmp,
		false,
		body,
	).then((res) => {
		return res.data;
	});
};
