import React, { useState, useEffect } from "react";
import { Button, Form, Input, Typography, Alert } from "antd";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import { useAuth } from "../../context/AuthContext";

import { useNavigate } from "react-router-dom";

import styles from "./LoginAdmin.module.css";

const { Title } = Typography;

const LoginAdmin: React.FC = () => {
  const [form] = Form.useForm();
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { login, loggedin } = useAuth();

  const onFinish = async (values: any) => {
    try {
      setErrorMsg("");
      setLoading(true);
      await login(values.email, values.password);
    } catch (err) {
      if (
        err instanceof Error &&
        err.message === "Firebase: Error (auth/user-not-found)."
      ) {
        setErrorMsg("Admin Not Found!");
      } else if (
        err instanceof Error &&
        err.message === "Firebase: Error (auth/wrong-password)."
      ) {
        setErrorMsg("Invalid Password");
      } else {
        setErrorMsg("Login Failed");
      }
      setLoading(false);
    }

    setLoading(false);
    form.resetFields();
  };

  useEffect(() => {
    if (loggedin) {
      navigate("/creator");
    }
  }, [loggedin]);

  return (
    <>
      <Navbar />
      <div className={styles.container}>
        <Title className={styles.title}>Admin Login</Title>
        <div className={styles.formContainer}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            {errorMsg && (
              <Alert message={errorMsg} type="error" closable showIcon />
            )}
            <Form.Item
              label="Enter Your Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input placeholder="e.g. stefan@cloutflow.com" />
            </Form.Item>

            <Form.Item
              label="Enter Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password placeholder="e.g. ••••••••" />
            </Form.Item>

            <Form.Item>
              <Button
                className={styles.button}
                type="primary"
                htmlType="submit"
                block
                loading={loading}
                // disabled={loading}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
          {/* <div>
            Don't have an account? <Link to="/register">Register</Link>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LoginAdmin;
