import React from "react";
import { Drawer, Row, Skeleton, Space, Empty } from "antd";
import { useQuery } from "react-query";

import InfluencerTile from "./InfluencerTile";

import { getCampaignInfluencersById } from "../../../handler/Report-Only/getCampaignInfluencersById";

import styles from "./styles.module.css";

interface Props {
  visible: boolean;
  handleClose: () => void;
  campaignId: string;
}

const ReportCampaignDrawer: React.FC<Props> = ({
  visible,
  handleClose,
  campaignId,
}) => {
  const [campaignKey, campaignFn] = getCampaignInfluencersById(campaignId);

  const { data, isLoading } = useQuery(campaignKey, campaignFn, {
    enabled: visible,
  });

  console.log("campaignData", data);

  return (
    <Drawer
      width={"75vw"}
      open={visible}
      title="Report Campaign Details"
      onClose={handleClose}>
      {isLoading ? (
        <Row justify={"center"}>
          <Skeleton paragraph={{ rows: 4 }} />
        </Row>
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <p className={styles.campaignName}>
                <span>{data?.data?.campaign?.campaignName}</span>
                <img
                  className={styles.platformImg}
                  src={`/assets/img/${data?.data?.campaign?.platform}.png`}
                  alt=""
                />
              </p>
              <p className={styles.brandName}>
                {data?.data?.campaign?.searchAbleParams
                  .split(data?.data?.campaign?.campaignName)
                  .at(-1)}
              </p>
            </div>

            {/* <p
              onClick={() => setOpenDrawer(true)}
              className={styles.headerRight}>
              Campaign Preview
            </p> */}
          </div>
          <div className={styles.options}>
            <p className={styles.active}>
              Influencers ({data?.data?.aggregates?.influencer})
            </p>
            <p className={styles.option}>
              Reports ({data?.data?.aggregates?.report})
            </p>
            <p className={styles.option}>
              Budget (
              {data?.data?.influencers?.reduce(
                (prevItem, currentItem) => prevItem + currentItem?.brandPrice,
                0
              )}
              )
            </p>
          </div>

          <div className={styles.table}>
            <div className={styles.paymentTableHeader}>
              <p>Profile</p>
              <p>Medias Added</p>
              <p>Price</p>
              <p>Action</p>
            </div>
            <div className={styles.tableBody}>
              {data?.data.aggregates.influencer !== 0 ? (
                <>
                  {data?.data?.influencers.map((influencerItem, idx) => (
                    <InfluencerTile influencerData={influencerItem} key={idx} />
                  ))}
                </>
              ) : (
                <Row
                  style={{ height: "200px" }}
                  align="middle"
                  justify="center">
                  <Space direction="vertical" align="center">
                    <Empty />
                  </Space>
                </Row>
              )}
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default ReportCampaignDrawer;
