import { sendRequest } from "../helper";

interface approveKycProps {
  firstname?: string;
  lastname?: string;
  geoLocationGooglePlaceId?: string;
  geoLocationName?: string;
  companyName?: string;
  address?: {
    line?: string;
    city?: string;
    pincode?: string;
    country?: string;
    state?: string;
  };
  gst?: string;
  pan?: string;
  isGstRegistered?: boolean;
  profilePicUrl?: string;
  niche?: string[];
  userPlatforms?: any[];
  tags?: string[];
  dob?: string;
  gender?: string;
  influencerId: string;
}

export const updateInfluencerV2 = (props: approveKycProps) => {
  return sendRequest(
    "put",
    `/influencer/me`,
    false,
    { ...props },
    null,
    true
  ).then((res) => res.data);
};
