import { Influencer } from "../../typings";
import { sendRequest } from "../helper";

type getInfluencerResult = {
  status: string;
  message: string;
  data: Influencer;
};

export const getInfluencer = (id?: string) => {
  const queryKey = ["influencer", id];
  const queryFunction = () =>
    sendRequest<getInfluencerResult>(
      "get",
      `/influencer/me?influencerId=${id}`,
      false,
      {},
      undefined,
      true
    ).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
