import { sendRequest } from "../helper";

interface releasePayoutTnxProps {
	finalizedOfferId: string;
	brandId: string;
}

export const releasePayoutTnx = ({
	finalizedOfferId,
	brandId,
}: releasePayoutTnxProps) => {
	return sendRequest(
		"put",
		`/brand/tnx/payouts-release?finalizedOfferId=${finalizedOfferId}&brandId=${brandId}`,
		false,
	).then((res) => res.data);
};
