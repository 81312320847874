import { sendRequest } from "../helper";

type getNotesResult = {
  status: string;
  message: string;
  data: {
    note: string;
    noteCreatedDateTime: string;
  }[];
};

export const getNotes = (influencerId: string) => {
  const queryKey = ["influencer-notes", influencerId];
  const queryFunction = () =>
    sendRequest<getNotesResult>(
      "get",
      `/influencer/relation/note?influencerId=${influencerId}`,
      false
    ).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
