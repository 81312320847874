import { Drawer, Empty, Pagination, Row, Skeleton, Space } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  getInfluencerAllPayments,
  getTotalInfluencerPaymentCount,
} from "../../../handler/Influencer";
import styles from "./InfluencerPaymentDrawer.module.css";
import InfluencerPaymentTile from "./InfluencerPaymentTile/InfluencerPaymentTile";

interface InfluencerPaymentDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  influencerId?: string;
}

const InfluencerPaymentDrawer: React.FC<InfluencerPaymentDrawerProps> = ({
  openDrawer,
  setOpenDrawer,
  influencerId,
}) => {
  // Use States
  const [page, setPage] = useState(1);

  // Get all Influencer Payments and count
  const [getInfluencerAllPaymentsKey, getInfluencerAllPaymentsFn] =
    getInfluencerAllPayments({ influencerId, page });
  const { data: influencerPayments, isLoading } = useQuery(
    getInfluencerAllPaymentsKey,
    getInfluencerAllPaymentsFn,
    { enabled: openDrawer }
  );
  const [getTotalInfluencerPaymentCountKey, getTotalInfluencerPaymentCountFn] =
    getTotalInfluencerPaymentCount(influencerId);
  const { data: totalCount } = useQuery(
    getTotalInfluencerPaymentCountKey,
    getTotalInfluencerPaymentCountFn,
    { enabled: openDrawer }
  );

  return (
    <>
      <Drawer
        width={"75vw"}
        visible={openDrawer}
        onClose={() => setOpenDrawer(false)}>
        <div className={styles.container}>
          <div className={styles.header}>
            <img
              src={influencerPayments?.data.influencer.profilePicUrl}
              alt=""
            />
            <p>
              {influencerPayments?.data.influencer.name}{" "}
              {influencerPayments?.data.influencer.lastname}
            </p>
          </div>
          <p className={styles.title}>Campaigns and Payments</p>
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <p>Timestamp</p>
              <p>
                <p>Campaign name</p> <p>Brand name</p>
              </p>
              <p>Profile</p>
              <p>Influencer Price</p>
              <p>Brand Price</p>
              <p>Payment Status</p>
              <p>Action</p>
            </div>
            <div className={styles.tableBody}>
              {isLoading && <Skeleton paragraph={{ rows: 4 }} />}
              {totalCount?.data !== 0 ? (
                <>
                  {influencerPayments?.data.offers.map(
                    (influencerPayment, idx) => (
                      <InfluencerPaymentTile
                        key={idx}
                        influencerPayment={influencerPayment}
                        getInfluencerAllPaymentsKey={
                          getInfluencerAllPaymentsKey
                        }
                      />
                    )
                  )}
                </>
              ) : (
                <Row
                  style={{ height: "200px" }}
                  align="middle"
                  justify="center">
                  <Space direction="vertical" align="center">
                    <Empty />
                  </Space>
                </Row>
              )}
            </div>
          </div>
          {totalCount?.data !== 0 && (
            <Row justify="center">
              <Pagination
                total={totalCount?.data}
                style={{ margin: "10px auto" }}
                onChange={(page, _) => setPage(page)}
                pageSize={4}
                showSizeChanger={false}
                current={page}
              />
            </Row>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default InfluencerPaymentDrawer;
