import { Button, List, Modal, Tag } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { getIgTokenDebug } from "../../../../../handler/Logs/getDebugIgToken";
import ReactJson from "react-json-view";

const SocialMediaTokenIgDebugModal = ({
	isModalOpen,
	setIsModalOpen,
	logInfo,
}: {
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isModalOpen: boolean;
	logInfo: any;
}) => {
	const [listData, setListData] = useState<Array<any>>([]);

	const [hasToken, setHasToken] = useState<{
		hasSlToken: boolean;
		hasLlToken: boolean;
		analysedSlToken: boolean;
		analysedLlToken: boolean;

		slToken?: string;
		llToken?: string;
	}>({
		hasSlToken: false,
		hasLlToken: false,
		analysedSlToken: false,
		analysedLlToken: false,
	});

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		var _analysisList: Array<any> = [];

		if (logInfo?.hasSlToken) {
			_analysisList.push("Has Shorlived Access Token");
			setHasToken((prev) => {
				return {
					...prev,
					hasSlToken: true,
					slToken: logInfo?.slToken,
					analysedSlToken: false,
				};
			});
		} else {
			_analysisList.push("No Shorlived Access Token Found");
		}

		if (logInfo?.hasLlToken) {
			_analysisList.push("Has Longlive Access Token");

			setHasToken((prev) => {
				return {
					...prev,
					hasLlToken: true,
					slToken: logInfo?.llToken,
					analysedLlToken: false,
				};
			});
		} else {
			_analysisList.push("No Long live Access Token Found");
		}

		setListData((prev) => {
			return [
				..._analysisList,
				<>
					<Tag>Server Error</Tag>
					<ReactJson src={logInfo?.error} collapsed={true}></ReactJson>
				</>,
			];
		});
	}, []);

	const [getSlTokenDebugsKey, getSlTokenDebugsFn] = getIgTokenDebug({
		token: hasToken.slToken || "",
	});

	useQuery(getSlTokenDebugsKey, getSlTokenDebugsFn, {
		enabled: hasToken?.slToken && !hasToken.analysedSlToken ? true : false,
		onSuccess: (args) => {
			if (!hasToken.analysedLlToken) {
				setListData((prev) => {
					return [
						...prev,

						<>
							<Tag>Short Lived Token</Tag>
							<ReactJson src={args?.data} collapsed={false}></ReactJson>
						</>,
					];
				});
				setHasToken((prev) => {
					return { ...prev, analysedLlToken: true };
				});
			}
		},
	});

	const [getLltokenKey, getllTokenDebugsFn] = getIgTokenDebug({
		token: hasToken.llToken || "",
	});

	useQuery(getLltokenKey, getllTokenDebugsFn, {
		enabled: hasToken?.llToken && !hasToken.analysedLlToken ? true : false,
		onSuccess: (args) => {
			if (!hasToken.analysedLlToken) {
				setListData((prev) => {
					return [
						...prev,

						<>
							<Tag>Long Live Token</Tag>
							<ReactJson src={args?.data} collapsed={false}></ReactJson>
						</>,
					];
				});
				setHasToken((prev) => {
					return { ...prev, analysedLlToken: true };
				});
			}
		},
	});

	return (
		<Modal
			title="Ig Connect Debugger"
			open={isModalOpen}
			onOk={handleOk}
			onCancel={handleCancel}
			width={"50vw"}
		>
			<List
				size="large"
				header={<div>Report</div>}
				bordered
				dataSource={listData}
				renderItem={(item) => <List.Item>{item}</List.Item>}
			/>
		</Modal>
	);
};

export default SocialMediaTokenIgDebugModal;
