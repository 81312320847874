import React from "react";
import { Modal, Button } from "antd";

interface DeleteUserModalProps {
  visible: boolean;
  userId: string;
  handleClose: () => void;
  handleSubmit: (userId: string) => void;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  visible,
  userId,
  handleClose,
  handleSubmit,
}) => {
  return (
    <Modal
      visible={visible}
      title="Delete User"
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          danger
          onClick={() => handleSubmit(userId)}
        >
          Delete
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete this user?</p>
    </Modal>
  );
};

export default DeleteUserModal;
