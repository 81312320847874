import { sendRequest } from "../helper";
import { tierDetail, tierUsageDetail } from "../../typings";

interface tierUsage extends tierUsageDetail {
  createdOnDateTime: string;
  lastUpdatedDateTime: string;
}

type Response = {
  status: string;
  message: string;
  data: {
    tier: tierDetail;
    tierPlanUsed: tierUsage;
    currencySymbol: string;
    isIndia: boolean;
    subscriptionStatus: string;
    subscriptionPlan: {
      _id: string;
      brandId: string;
      tierPlanId: string;
      subscriptionStatus: string;
      cycle: string;
      start: string;
      end: string;
      isSubscriptionManagedByStripe: boolean;
      renewalAnchorDate: string;
      nextRenewalOn: string;
      createdOnDateTime: string;
      lastUpdatedDateTime: string;
      __v: number;
      stripeCustomerId: string;
      stripePriceId: string;
      stripeSubscriptionId: string;
      stripeSubscriptionStatus: string;
    };
    subscriptionRequest: {
      canRequest: boolean;
      reason: string;
    };
    cancelRequest: {
      canCancelSubscription: boolean;
      reason: string;
    };
  };
};

export const getSubstate = ({ brandId }: { brandId: string }) => {
  const queryKey = ["subState", brandId];
  const queryFunction = () =>
    sendRequest<Response>(
      "get",
      `/brand/saas-subscription/state?brandId=${brandId}`,
      false
    ).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
