import { Button, Input, InputNumber, message, Modal, Space } from "antd";
import { format } from "date-fns";
import { getCampaignPaymentStatus } from "../../../../Utils/getCampaignPaymentStatus";
import styles from "./CampaignPaymentTile.module.css";
import nf from "@tuplo/numberfmt";
import { OfferPayments } from "../../../../typings";
import { useState } from "react";
import { PaymentStatus } from "../../../../const/PaymentStatus.const";
import { useMutation, useQueryClient } from "react-query";
import { manualPayment, manualPaymentOtp } from "../../../../handler/Campaign";
import { AxiosError } from "axios";

interface CampaignPaymentTileProps {
  campaignPayment: OfferPayments;
  getCampaignAllPaymentsKey: (string | number | undefined)[];
}

const CampaignPaymentTile: React.FC<CampaignPaymentTileProps> = ({
  campaignPayment,
  getCampaignAllPaymentsKey,
}) => {
  const queryClient = useQueryClient();
  // Use States
  const [manualPaymentModal, setManualPaymentModal] = useState<boolean>(false);
  const [otp, setOtp] = useState<number>();
  const [manualPaymentSteps, setManualPaymentSteps] = useState<number>(0);
  const [utr, setUtr] = useState<string>("");

  // Manual Payment OTP
  const manualPaymentOtpMutation = useMutation(manualPaymentOtp, {
    onSuccess: () => {
      message.success("OTP Sent");
      setManualPaymentSteps(1);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });
  // Manual Payment
  const manualPaymentMutation = useMutation(manualPayment, {
    onSuccess: () => {
      message.success("Payment Successfull");
      queryClient.invalidateQueries(getCampaignAllPaymentsKey);
      setManualPaymentModal(false);
    },
    onError: (err: AxiosError) => {
      console.log(err.code, err.message, "error-message", err);
      message.error("Something went wrong");
    },
  });

  return (
    <>
      <div className={styles.payment}>
        <p className={styles.timeStamp}>
          {format(
            new Date(campaignPayment.createDateTime),
            "hh:mm a, do LLL yy"
          )}
        </p>
        <p>₹ {nf(campaignPayment.paymentAmount, "0,0.00")}</p>
        <p
          style={{
            color: getCampaignPaymentStatus(campaignPayment.status)?.color,
          }}>
          {getCampaignPaymentStatus(campaignPayment.status)?.status}
        </p>
        <p
          style={{
            color: getCampaignPaymentStatus(campaignPayment.paymentStatus)
              ?.color,
          }}>
          {getCampaignPaymentStatus(campaignPayment.paymentStatus)?.status}
        </p>
        <p>
          {!campaignPayment.paymentUtr || campaignPayment.paymentUtr === ""
            ? "-"
            : "Manual"}
        </p>
        <p>{campaignPayment._id}</p>
        {campaignPayment.status === PaymentStatus.status.PROCESSING ? (
          <Button onClick={() => setManualPaymentModal(true)}>
            Pay Manually
          </Button>
        ) : (
          "--"
        )}
      </div>
      <Modal
        width={350}
        visible={manualPaymentModal}
        okText="Done"
        onOk={() => {
          if (manualPaymentSteps === 0) {
            manualPaymentOtpMutation.mutate({
              offerPayInId: campaignPayment._id,
              utr,
            });
          } else {
            if (otp)
              manualPaymentMutation.mutate({
                offerPayInId: campaignPayment._id,
                otp,
              });
          }
        }}
        okButtonProps={{
          loading:
            manualPaymentMutation.isLoading ||
            manualPaymentOtpMutation.isLoading,
          disabled:
            (manualPaymentSteps === 0 && utr === "") ||
            (manualPaymentSteps === 1 && !otp),
        }}
        title="Pay Manually"
        onCancel={() => setManualPaymentModal(false)}>
        <Space style={{ width: "100%" }} direction="vertical">
          <p className={styles.manualPaymentText}>
            {manualPaymentSteps === 0 ? "Enter UTR no." : "Enter OTP to verify"}
          </p>
          {manualPaymentSteps === 0 ? (
            <Input
              style={{ width: "100%" }}
              value={utr}
              onChange={(e) => setUtr(e.target.value)}
            />
          ) : (
            <InputNumber
              style={{ width: "100%" }}
              value={otp}
              onChange={(e) => setOtp(e as number)}
            />
          )}
        </Space>
      </Modal>
    </>
  );
};

export default CampaignPaymentTile;
