import { Route, Routes, Navigate } from "react-router-dom";
import LoginAdmin from "./components/LoginAdmin";
import { useAuth } from "./context/AuthContext";
import MainLayout from "./layout/mainLayout";
import { useQuery } from "react-query";
import { getUserRole } from "./handler/User/getUserRole";
import { Button } from "antd";

function App() {
  const { currentUser, logout } = useAuth();
  const [queryKey, queryFunction] = getUserRole();
  const { data, isLoading, error } = useQuery(queryKey, queryFunction);

  if (!currentUser)
    return (
      <Routes>
        <Route path="/login" element={<LoginAdmin />} />
        <Route path="*" element={<Navigate to="login" />} />
      </Routes>
    );

  if (isLoading) return <h1>Loading</h1>;

  if (data?.admin?.role == "admin") return <MainLayout />;

  return (
    <>
      <h1>
        You do not have an admin role, Please contact Shivam to give you a role
        on admin
      </h1>
      <Button onClick={logout}>Logout</Button>
    </>
  );
}

export default App;
