import { sendRequest } from "../helper";

interface createZohoProps {
  influencerId: string;
}

export const createInfluencerZoho = ({ influencerId }: createZohoProps) => {
  return sendRequest(
    "post",
    `/influencer/zoho/create-contact?influencerId=${influencerId}`,
    false
  ).then((res) => res.data);
};
