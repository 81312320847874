import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import {
  Select,
  Row,
  Col,
  Skeleton,
  Spin,
  Pagination,
  Button,
  message,
} from "antd";
import CSVReader from "react-csv-reader";
import { uploadNumberCSV } from "../../../handler/uploadNumberCSV";

import { LoadingOutlined } from "@ant-design/icons";

import { getInfluencers, getInfluencersCount } from "../../../handler/Onboard";
import { getAllBrands } from "../../../handler/Brand";

import InfluencerTile from "./InfluencerTile";

import styles from "./styles.module.css";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: string) => header.replace(/\W/g, "_"),
};

const OnboardCreators = () => {
  const [brandSearchQuery, setBrandSearchQuery] = useState<{
    text: string;
    received: string;
    kycStatus: string;
  }>({
    text: "",
    received: "",
    kycStatus: "Verified",
  });
  const [selectedBrand, setSelectedBrand] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const [getAllBrandsKey, getAllBrandsFn] = getAllBrands({
    page: 1,
    searchQuery: brandSearchQuery,
  });
  const [pageNo, setPageNo] = useState<number>(1);
  const [getInfluencersKey, getInfluencersFn] = getInfluencers({
    pageNumber: pageNo,
    brandId: selectedBrand?.value,
  });
  const [getInfluencersCountKey, getInfluencersCountFn] = getInfluencersCount({
    brandId: selectedBrand?.value,
  });

  const { data: influencersData, isLoading: isInfluencerDataLoading } =
    useQuery(getInfluencersKey, getInfluencersFn);
  const {
    data: influencersCountData,
    isLoading: isInfluencerCountDataLoading,
  } = useQuery(getInfluencersCountKey, getInfluencersCountFn);
  const { data: brands } = useQuery(getAllBrandsKey, getAllBrandsFn);

  const handleForce = (data: any, _: any) => {
    setCSVData(data);
  };

  const uploadNumberCSVMutation = useMutation(uploadNumberCSV, {
    onSuccess: () => {
      message.success("CSV Uploaded Successfully");
    },
  });

  const [csvData, setCSVData] = useState<
    {
      name: string;
      phoneNumber: string;
      emailId: string;
    }[]
  >([]);

  console.log(influencersData);

  return (
    <div>
      <Row style={{ paddingTop: "14px" }}>
        <Col span={12}>
          <Select
            showSearch
            value={selectedBrand}
            style={{ width: 200 }}
            placeholder="Filter by brand name"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            labelInValue
            onSearch={(val) => {
              setBrandSearchQuery({ ...brandSearchQuery, text: val });
            }}
            allowClear
            onChange={(value) => {
              if (!value) {
                return setSelectedBrand(null);
              }
              setSelectedBrand({ label: value.label, value: value.value });
            }}
            notFoundContent={<p>No result Found</p>}
            options={(brands?.data || []).map((brand) => ({
              value: brand._id,
              label: brand.businessDetails?.name,
            }))}
          />
        </Col>
        <Col
          span={10}
          style={{
            textAlign: "center",
            border: "1px solid #ccc",
            padding: "10px 0",
          }}>
          <h3>Upload CSV in format name ,phoneNumber & email</h3>
          <CSVReader
            cssClass={styles.csvInput}
            onFileLoaded={handleForce}
            parserOptions={papaparseOptions}
          />
          <Button
            type="primary"
            disabled={csvData.length <= 0}
            onClick={() => uploadNumberCSVMutation.mutate({ csvData })}
            loading={uploadNumberCSVMutation.isLoading}>
            Upload
          </Button>
        </Col>
        <Col span={24} offset={0}>
          {(isInfluencerDataLoading || isInfluencerCountDataLoading) && (
            <div style={{ marginTop: "30px" }}>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </div>
          )}
          {influencersData && (
            <>
              {influencersData.data.length <= 0 ? (
                <>
                  <Row
                    style={{
                      paddingTop: "58px",
                      // marginTop: "25px",
                      borderTop: "2px solid rgba(0, 0, 0, 0.06)",
                    }}
                    justify="center">
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "24px",
                        color: "rgba(0, 0, 0, 0.85)",
                        textAlign: "center",
                      }}>
                      No one to invite
                    </p>
                  </Row>
                </>
              ) : (
                <>
                  <div
                    className="campaign  list"
                    style={{
                      marginTop: "20px",
                      overflowY: "scroll",
                      height: "70%",
                    }}>
                    <Row className={styles.listHeader}>
                      <Col className={styles.headerItem} span={3}>
                        Creator
                      </Col>
                      <Col className={styles.headerItem} span={6}>
                        Details
                      </Col>
                      <Col
                        className={styles.headerItem}
                        style={{ textAlign: "center" }}
                        span={3}>
                        socialMedia
                      </Col>
                      <Col
                        className={styles.headerItem}
                        style={{ textAlign: "center" }}
                        span={3}>
                        Request Sent on
                      </Col>
                      <Col
                        className={styles.headerItem}
                        style={{ textAlign: "center" }}
                        span={3}>
                        Request Sent by
                      </Col>
                      <Col
                        className={styles.headerItem}
                        style={{ textAlign: "center" }}
                        span={3}>
                        Invite Status
                      </Col>
                      <Col
                        className={styles.headerItem}
                        style={{ textAlign: "center" }}
                        span={3}>
                        Status
                      </Col>
                    </Row>
                    <div
                      style={{
                        minHeight: "300px",
                      }}>
                      {!isInfluencerDataLoading && influencersData && (
                        <div style={{ display: "grid", gap: "4px" }}>
                          {influencersData?.data.map((influencerItem) => {
                            return (
                              <InfluencerTile
                                influencer={influencerItem}
                                key={influencerItem._id}
                              />
                            );
                          })}
                        </div>
                      )}
                      {isInfluencerDataLoading && (
                        <Row
                          justify="center"
                          align="middle"
                          style={{ paddingTop: "100px" }}>
                          <Spin
                            size="large"
                            indicator={
                              <LoadingOutlined style={{ fontSize: 50 }} spin />
                            }
                          />
                        </Row>
                      )}
                    </div>
                  </div>
                  <Row justify="center">
                    <Pagination
                      defaultCurrent={pageNo}
                      total={influencersCountData?.data?.count}
                      style={{ margin: "46px auto" }}
                      onChange={(page, _) => setPageNo(page)}
                      pageSize={5}
                      showSizeChanger={false}
                    />
                  </Row>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OnboardCreators;
