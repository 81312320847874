import { sendRequest } from "../helper";

interface cancelDealProps {
  finalizedOfferId?: string;
  remarks?: string;
}

export const cancelDeal = ({ finalizedOfferId, remarks }: cancelDealProps) => {
  return sendRequest(
    "put",
    `/brand/offer/cancel?finalizedOfferId=${finalizedOfferId}&remarks=${remarks}`,
    false
  ).then((res) => res.data);
};
