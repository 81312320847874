import { Campaign } from "../../typings";
import { sendRequest } from "../helper";

type getCampaignResult = {
  status: string;
  message: string;
  data: Campaign;
};

export const getCampaign = (id?: string) => {
  const queryKey = ["campaign", id];
  const queryFunction = () =>
    sendRequest<getCampaignResult>(
      "get",
      `/brand/campaign/by-id?campaignId=${id}`,
      false
    ).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
