import { Skeleton, Table } from "antd";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { getFrontEndLogCount } from "../../../../handler/Logs/frontEndErrorLogs";
import { getFrontEndErrorLogs } from "../../../../handler/Logs/getfrontEndErrorLogs";
import ReactJson from "react-json-view";

import { format } from "date-fns";

const FrontEndLogs = () => {
  const page = useRef(1);
  const [pageParams, setPageParams] = useState({
    page: page.current,
    limit: 10,
  });

  const [getTotalCountKey, getTotalCountFn] = getFrontEndLogCount();
  const { data: totalCount, isLoading: isCountLoading } = useQuery(
    getTotalCountKey,
    getTotalCountFn
  );

  const [getFrontEndErrorLogsKey, getFrontEndErrorLogsFn] =
    getFrontEndErrorLogs(pageParams);

  const { data, isLoading } = useQuery(
    getFrontEndErrorLogsKey,
    getFrontEndErrorLogsFn
  );

  const onPageChange = (pageNo: any) => {
    page.current = pageNo;
    setPageParams((prev) => {
      return { ...prev, page: pageNo };
    });
  };

  const columns = [
    {
      title: "createdDateTime",
      dataIndex: "createdDateTime",
      key: "createdDateTime",
      render: (_: any, { createdDateTime }: { createdDateTime: any }) => (
        <p>{format(new Date(createdDateTime), "hh:mm a, do LLL yy")}</p>
      ),
    },
    {
      title: "Front End Payload",
      dataIndex: "frontEndPayload",
      key: "frontEndPayload",
    },
    {
      title: "ipAddress",
      dataIndex: "ipAddress",
      key: "ipAddress",
    },
  ];

  return (
    <>
      {isCountLoading || isLoading ? (
        <Skeleton paragraph={{ rows: 10 }} />
      ) : (
        <Table
          size={"middle"}
          scroll={{ y: "70vh" }}
          dataSource={
            data?.data?.map((ele: any) => {
              return {
                key: ele._id,
                ...ele,
                frontEndPayload: (
                  <ReactJson
                    src={ele?.frontEndPayload}
                    collapsed={true}
                    collapseStringsAfterLength={1}></ReactJson>
                ),
              };
            }) || []
          }
          columns={columns}
          pagination={{
            pageSize: 10,
            total: totalCount?.data.count,
            pageSizeOptions: [10],
            onChange: onPageChange,
            current: page.current,
          }}
        />
      )}
    </>
  );
};

export default FrontEndLogs;
