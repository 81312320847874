import { sendRequest } from "../helper";

type getFrontEndLogsResponse = {
	status: string;
	message: string;
	data: any;
};

export const getSocialMediaConnectErrorLog = ({
	page,
	limit,
	phoneNumber,
	influencerId,
	call,
	status,
	socialMediaPlatformType,
}: {
	page: number;
	limit: number;
	phoneNumber: string;
	influencerId: string;
	call: Array<string>;
	status: Array<string>;
	socialMediaPlatformType: Array<string>;
}) => {
	const queryKey = [
		"social-media-connect-log",
		page,
		phoneNumber,
		influencerId,
		call.toString(),
		status.toString(),
		socialMediaPlatformType.toString(),
	];

	console.log(queryKey);
	const queryFunction = () =>
		sendRequest<getFrontEndLogsResponse>(
			"get",
			`/log?logsType=Social-Media-Connect-Error&page=${page}&limit=${limit}&phoneNumber=${encodeURIComponent(
				phoneNumber,
			)}&influencerId=${influencerId}&call=${call.toString()}&status=${status.toString()}&socialMediaPlatformType=${socialMediaPlatformType.toString()}`,
			false,
		).then((res) => {
			return res.data;
		});
	return [queryKey, queryFunction] as const;
};
