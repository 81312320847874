import { Button, List, Modal, Tag } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";

import ReactJson from "react-json-view";
import { getYtTokenDebug } from "../../../../../handler/Logs/getDebugYtToken";

const SocialMediaTokenYtDebugModal = ({
	isModalOpen,
	setIsModalOpen,
	logInfo,
}: {
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isModalOpen: boolean;
	logInfo: any;
}) => {
	const [listData, setListData] = useState<Array<any>>([]);

	const [hasToken, setHasToken] = useState<{
		hasRefreshToken: boolean;
		refreshToken?: string;
		analysedRefreshToken: boolean;
	}>({
		analysedRefreshToken: false,
		hasRefreshToken: false,
	});

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		var _analysisList: Array<any> = [];

		if (logInfo?.hasRefreshToken) {
			_analysisList.push("Has Refresh Token");
			setHasToken((prev) => {
				return {
					...prev,
					hasRefreshToken: true,
					refreshToken: logInfo?.refreshToken,
					analysedRefreshToken: false,
				};
			});
		} else {
			_analysisList.push("No Refresh Token");
		}

		setListData((prev) => {
			return [
				..._analysisList,
				<>
					<Tag>Server Error</Tag>
					<ReactJson src={logInfo?.error} collapsed={true}></ReactJson>
				</>,
			];
		});
	}, []);

	const [getDebugRefreshTokenKey, getRefreshTokenDebugsFn] = getYtTokenDebug({
		token: hasToken.refreshToken || "",
	});

	useQuery(getDebugRefreshTokenKey, getRefreshTokenDebugsFn, {
		enabled:
			hasToken?.refreshToken && !hasToken.analysedRefreshToken ? true : false,
		onSuccess: (args) => {
			if (!hasToken.analysedRefreshToken) {
				setListData((prev) => {
					return [
						...prev,

						<>
							<Tag>Short Lived Token</Tag>
							<ReactJson src={args?.data} collapsed={false}></ReactJson>
						</>,
					];
				});
				setHasToken((prev) => {
					return { ...prev, analysedRefreshToken: true };
				});
			}
		},
	});

	return (
		<Modal
			title="Yt Connect Debugger"
			open={isModalOpen}
			onOk={handleOk}
			onCancel={handleCancel}
			width={"50vw"}
		>
			<List
				size="large"
				header={<div>Report</div>}
				bordered
				dataSource={listData}
				renderItem={(item) => <List.Item>{item}</List.Item>}
			/>
		</Modal>
	);
};

export default SocialMediaTokenYtDebugModal;
