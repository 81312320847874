import { sendRequest } from "../helper";

type getTotalCountResult = {
  status: string;
  message: string;
  data: { count: number };
};

export const getTotalBrandCount = (searchQuery: {
  text: string;
  received: string;
  kycStatus: string;
}) => {
  const queryKey = ["brands", "count", searchQuery];
  const queryFunction = () =>
    sendRequest<getTotalCountResult>(
      "get",
      `/brand/count?&received=${searchQuery.received}&kycStatus=${searchQuery.kycStatus}&text=${searchQuery.text}`,
      false
    ).then((res) => {
      return res.data;
    });
  return [queryKey, queryFunction] as const;
};
